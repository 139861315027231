@import "~@telia-front/sass-tokens/index.scss";
@import "~@core-front/feature-list/styles.scss";

.feature-list__inner {
    border-radius: 8px;
    background-color: $color-raw-purple-fare;
    padding: 16px 32px;
    font-size: 18px;
    align-items: flex-start;

    @media (max-width: ($break-md - 1)) {
        padding: 24px 16px;
        font-size: 16px;
    }
}

.feature-list__item {
    padding: 16px;

    @media (max-width: ($break-md - 1)) {
        padding: 8px 16px;
    }

    @media (min-width: $break-md) {
        width: 100%;
    }
}

.feature-list__item-icon {
    font-size: 40px;

    @media (min-width: $break-md) {
        font-size: 48px;
    }
}

.feature-list__item-image {
    width: 40px;

    @media (min-width: $break-md) {
        width: 48px;
    }
}

.feature-list__item-icon-col {
    height: 48px;
}
