@use "sass:math";

@import "~@core-front/sass-tokens/index.scss";

$radio-indicator-size-medium: 20px !default;
$radio-indicator-size-small: 16px !default;
$radio-indicator-position-top: 1px !default;

.radio {
    position: relative;
}

.radio--vertical {
    display: flex;
}

.radio__label {
    @include visually-hidden();
}

.radio__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.radio__inner {
    position: relative;
    display: inline-block;
    padding-left: ($radio-indicator-size + math.div($radio-indicator-size, 2));
    min-height: $radio-indicator-size + $radio-indicator-position-top;
    cursor: pointer;

    html[data-whatintent="keyboard"] .radio__input:focus ~ & {
        @include keyboard-focus();
    }

    .radio--small & {
        padding-left: ($radio-indicator-size-small + math.div($radio-indicator-size-small, 2));
        min-height: $radio-indicator-size-small;
    }

    .radio--medium & {
        min-height: $radio-indicator-size-medium;
    }

    .radio--employee-background &::before {
        content: "";
        display: block;
        background: $radio-employee-background;
        position: absolute;
        top: -5px;
        left: -5px;
        right: -10px;
        bottom: -5px;
        border-radius: 3px;
    }

    .radio__input:disabled ~ & {
        cursor: default;
    }

    .radio--vertical & {
        display: flex;
        flex-direction: column-reverse;
        text-align: center;
        justify-content: space-between;
        padding: 0;
    }

    .skeleton__content > & {
        display: block;
        cursor: default;
    }
}

.radio__content {
    display: block;
    font-weight: 400;
    position: relative;

    .radio--large & {
        font-size: 16px;
    }

    .radio--medium & {
        font-size: 14px;
    }

    .radio--small & {
        font-size: $font-size-small;
    }

    .radio__input:disabled ~ .radio__inner & {
        color: $radio-disabled-color;
    }

    .radio--vertical & {
        margin-bottom: 15px;
    }

    .radio--hide-label & {
        display: none;
    }
}

.radio__indicator {
    display: block;
    border: 1px solid $radio-border-color;
    box-shadow: none;
    width: $radio-indicator-size;
    height: $radio-indicator-size;
    border-radius: 50%;
    background: $radio-indicator-bg;
    position: absolute;
    left: 0;

    .radio__input:checked ~ .radio__inner & {
        background: $radio-indicator-checked-bg;
        border-color: $radio-indicator-checked-border;
        color: $radio-indicator-checked-color;

        .radio--employee & {
            background: $radio-indicator-employee-bg;
            border-color: $radio-indicator-employee-border;
            color: $radio-indicator-employee-color;
        }
    }

    .radio--large & {
        top: $radio-indicator-position-top;
    }

    .radio--medium & {
        width: $radio-indicator-size-medium;
        height: $radio-indicator-size-medium;
    }

    .radio--small & {
        width: $radio-indicator-size-small;
        height: $radio-indicator-size-small;
    }

    .radio__input:disabled ~ .radio__inner & {
        background: $radio-indicator-disabled-bg;
        border-color: $radio-indicator-disabled-border;
        opacity: $radio-indicator-disabled-opacity;
    }

    .radio__input:disabled:checked ~ .radio__inner & {
        background: $radio-indicator-disabled-checked-bg;
        border-color: $radio-indicator-disabled-checked-border;
        color: $radio-indicator-disabled-color;
    }

    .radio--indicator-middle & {
        top: 50%;
        transform: translateY(-50%);
    }

    .radio--vertical & {
        top: 0;
        position: relative;
        margin: 0 auto;
    }

    &::before {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        background: currentcolor;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;

        .radio--small & {
            width: 6px;
            height: 6px;
        }

        .radio__input:checked ~ .radio__inner & {
            opacity: 1;
        }
    }

    &.skeleton__item {
        border: none;
    }
}

.radio__extra {
    padding-top: 10px;
    padding-left: ($radio-indicator-size + math.div($radio-indicator-size, 2));

    .radio--small & {
        padding-left: ($radio-indicator-size-small + math.div($radio-indicator-size-small, 2));
    }
}
