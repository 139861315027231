@import "~@telia-front/sass-tokens/index.scss";

.progress-bar {
    font-weight: 700;
}

.progress-bar__title {
    margin-bottom: 10px;
}

.progress-bar__inner {
    position: relative;
    height: 40px;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
}

.progress-bar__bar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 0 20px;
    line-height: 40px;
    white-space: nowrap;
    overflow: hidden;
    user-select: none;
}

.progress-bar__bar--content {
    right: 0;
    background: $grey;
    color: $green;
    transition: color $transition-default;

    .progress-bar--fill-medium & {
        color: $blue;
    }

    .progress-bar--fill-high & {
        color: $red;
    }

    .progress-bar--type-order & {
        color: $green;
    }
}

.progress-bar__bar--overlay {
    background: $green;
    color: $white;
    transition: background-color $transition-default;

    .progress-bar--fill-medium & {
        background: $blue;
    }

    .progress-bar--fill-high & {
        background: $red;
    }

    .progress-bar--type-order & {
        background: $green;
    }
}

.progress-bar__button {
    position: absolute;
    top: 50%;
    right: 7px;
    transform: translateY(-50%);
}
