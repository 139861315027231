@import "~@telia-front/sass-tokens/index.scss";

.tab-cordion.tab-cordion--background-light {
    &.tab-cordion--full-width {
        .card__content > & {
            margin-left: -$base-horizontal-spacing-xs;
            margin-right: -$base-horizontal-spacing-xs;

            @media (min-width: $break-sm) {
                margin-left: -$base-horizontal-spacing;
                margin-right: -$base-horizontal-spacing;
            }
        }

        .card__content--padding-none > & {
            margin-left: 0;
            margin-right: 0;
        }

        .card__content--padding-medium > &,
        .card__content--padding-cta > & {
            margin-left: -$base-horizontal-spacing-xs;
            margin-right: -$base-horizontal-spacing-xs;

            @media (min-width: $break-sm) {
                margin-left: -40px;
                margin-right: -40px;
            }
        }

        .card__content--padding-image > & {
            margin-left: -$base-horizontal-spacing-xs;
            margin-right: -$base-horizontal-spacing-xs;

            @media (min-width: $break-sm) {
                margin-left: -30px;
                margin-right: -30px;
            }
        }
    }
}

.tab-cordion__item-tag {
    .accordion__item:not(.accordion__item--employee).is-open & {
        background-color: $tabs-tag-selected-background-color;
        color: $tabs-tag-selected-text-color;
    }

    .accordion__item--employee:not(.is-open) & {
        background-color: $tabs-employee-tag-background-color;
        color: $tabs-employee-tag-text-color;
    }
}

