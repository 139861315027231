@import "~@core-front/sass-tokens/index.scss";

@mixin textfield-hide-label() {
    // important is necessary to override all the possible states
    color: transparent !important;
    padding-top: 10px !important;
    animation: none !important;

    &::before {
        opacity: 1 !important;
        top: -10px !important;
        animation: none !important;
    }

    &::after {
        top: 0;
    }
}

.filefield {
    position: relative;
    font-size: 16px;
    width: 100%;
    max-width: 100%;

    &.is-disabled {
        color: $grey-d;
    }

    &.is-focused {
        z-index: 2;
    }
}

.filefield__inner {
    position: relative;
    padding: $filefield-label-visible-height 0 0;

    .filefield--no-label-no-break & {
        padding-top: $filefield-no-label-padding-top;

        &::after {
            // important is necessary to override all the possible states
            opacity: 1 !important;
            top: 0;
        }
    }

    .filefield--no-label-sm & {
        @media (min-width: $break-sm) {
            padding-top: $filefield-no-label-padding-top;

            &::after {
                // important is necessary to override all the possible states
                opacity: 1 !important;
                top: 0;
            }
        }
    }

    .filefield--no-label-md & {
        @media (min-width: $break-md) {
            padding-top: $filefield-no-label-padding-top;

            &::after {
                // important is necessary to override all the possible states
                opacity: 1 !important;
                top: 0;
            }
        }
    }

    &::after {
        @include transition(
            ('animation': short, 'properties': border-color),
            ('animation': short, 'properties': opacity, 'delay': short),
        );

        content: "";
        display: block;
        position: absolute;
        top: $filefield-border-top-position;
        left: 10px;
        right: 10px;
        border-top: $filefield-border;
        opacity: 0;

        .filefield--floating-label & {
            opacity: 1;
        }

        .filefield.is-focused & {
            border-color: $black;
            opacity: 0;
        }

        .filefield.is-dirty & {
            opacity: 0;
        }

        .filefield.is-invalid & {
            border-color: $filefield-invalid-color;
        }

        .filefield.is-disabled & {
            border-color: rgba($filefield-border-color, .5);
        }
    }
}

.filefield__input {
    @include transition(('animation': short, 'properties': border-color));

    position: relative;
    border: $filefield-border;
    border-top: 0;
    border-radius: $filefield-border-radius;
    box-shadow: $filefield-box-shadow;
    display: block;
    font-size: 16px;
    font-weight: $filefield-weight;
    margin: 0;
    padding: $filefield-padding;
    width: 100%;
    background: $filefield-background;
    text-align: left;
    color: $filefield-input-color;

    .filefield.is-focused & {
        outline: none;
        border-color: $black;
    }

    .filefield.is-invalid & {
        border-color: $filefield-invalid-color;
    }

    .filefield.is-disabled & {
        background-color: $filefield-disabled-background;
        color: $filefield-disabled-color;
        outline: none;
        opacity: .5;
    }
}

.filefield__input-field {
    position: absolute;
    top: $filefield-input-top;
    left: 0;
    bottom: $filefield-input-bottom;
    width: 100%;
    opacity: 0;
    cursor: pointer;

    .filefield.is-disabled & {
        cursor: default;
    }
}

.filefield__label {
    @include transition(('animation': short, 'properties': color));

    color: $black;
    font-size: $filefield-label-size;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    padding: $filefield-label-padding;

    .filefield--floating-label & {
        @include animation(
            (
                'animation': short,
                'name': hide-small-label,
            ),
            (
                'animation': short,
                'name': show-big-label,
                'delay': short,
            )
        );
            
        padding-top: $filefield-label-start-position;
        padding-left: 18px;
        font-size: $filefield-floating-label-size;
    }

    .filefield--floating-label.is-focused &,
    .filefield--floating-label.is-dirty & {
        @include animation(
            (
                'animation': short,
                'name': hide-big-label,
            ),
            (
                'animation': short,
                'name': show-small-label,
                'delay': short,
            )
        );
       
        font-size: $filefield-label-size;
        padding-top: 0;

        &::before {
            @include animation(
                (
                    'animation': short,
                    'name': float-line-with-big-label-to-top,
                ),
                (
                    'animation': short,
                    'name': float-line-with-small-label-to-top,
                    'delay': short,
                )
            );

            top: $filefield-border-top-position;
        }
    }

    .filefield.is-dirty & {
        color: $filefield-dirty-color;

        &::before,
        &::after {
            border-color: $filefield-dirty-border;
        }
    }

    .filefield.is-focused & {
        color: $black;

        &::before,
        &::after {
            border-color: $black;
        }
    }

    .filefield.is-invalid & {
        &::before,
        &::after {
            border-color: $filefield-invalid-color;
        }
    }

    .filefield.is-disabled & {
        &::before,
        &::after {
            border-color: rgba($filefield-border-color, .5);
        }
    }

    .filefield--no-label-no-break & {
        @include textfield-hide-label();
    }

    .filefield--no-label-sm & {
        @media (min-width: $break-sm) {
            @include textfield-hide-label();
        }
    }

    .filefield--no-label-md & {
        @media (min-width: $break-md) {
            @include textfield-hide-label();
        }
    }

    &::after {
        @include transition(('animation': short, 'properties': border-color));

        content: "";
        display: block;
        position: absolute;
        top: $filefield-border-top-position;
        left: 0;
        width: 10px;
        height: $filefield-label-height;
        border-radius: 3px 0 0;
        border: $filefield-border;
        border-bottom: 0;
        border-right: 0;
    }

    &::before {
        @include transition(('animation': short, 'properties': border-color));

        content: "";
        display: block;
        position: relative;
        top: 7px;
        left: 0;
        right: 0;
        margin-left: 10px;
        height: $filefield-label-height;
        border-radius: 0 3px 0 0;
        border: $filefield-border;
        border-bottom: 0;
        border-left: 0;
        flex: 1 0 auto;

        .filefield--floating-label & {
            @include animation(
                (
                    'animation': short,
                    'name': float-line-with-small-label-to-bottom,
                ),
                (
                    'animation': short,
                    'name': float-line-with-big-label-to-bottom,
                    'delay': short,
                )
            );

            top: $filefield-floating-label-top;
        }
    }
}

.filefield__input-content {
    position: relative;
    display: flex;
    margin: -4px -12px -4px -4px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.filefield__files {
    pointer-events: none;
}

.filefield__file {
    margin-right: 10px;
    pointer-events: auto;
}

.filefield__add-btn {
    display: block;
    margin: 3px;
    padding: $filefield-add-btn-padding;

    .filefield.is-disabled & {
        cursor: default;
        pointer-events: none;
    }
}

@keyframes hide-big-label {
    0% {
        padding-top: $filefield-label-start-position;
        font-size: $filefield-floating-label-size;
    }

    100% {
        padding-top: $filefield-border-top-position;
        color: transparent;
        font-size: $filefield-floating-label-size;
    }
}

@keyframes show-small-label {
    0% {
        padding-top: $filefield-border-top-position;
        color: transparent;
        font-size: $filefield-label-size;
    }

    100% {
        padding-top: 0;
        font-size: $filefield-label-size;
    }
}

@keyframes show-big-label {
    0% {
        padding-top: $filefield-border-top-position;
        color: transparent;
        font-size: $filefield-floating-label-size;
    }

    100% {
        padding-top: $filefield-label-start-position;
        font-size: $filefield-floating-label-size;
    }
}

@keyframes hide-small-label {
    0% {
        padding-top: 0;
        font-size: $filefield-label-size;
    }

    100% {
        padding-top: $filefield-border-top-position;
        color: transparent;
        font-size: $filefield-label-size;
    }
}

@keyframes float-line-with-big-label-to-top {
    0% {
        top: -10px;
    }

    100% {
        top: 0;
    }
}

@keyframes float-line-with-small-label-to-top {
    0% {
        top: 0;
    }

    100% {
        top: $filefield-border-top-position;
    }
}

@keyframes float-line-with-big-label-to-bottom {
    0% {
        top: 0;
    }

    100% {
        top: -10px;
    }
}

@keyframes float-line-with-small-label-to-bottom {
    0% {
        top: $filefield-border-top-position;
    }

    100% {
        top: 0;
    }
}
