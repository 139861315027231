@use "sass:map";
@use "sass:math";

@import "~@telia-front/sass-tokens/index.scss";

@mixin progress-inline-break($progress-break: $break-sm, $break: "sm") {
    @media only screen and (min-width: $progress-break) {
        .progress__steps {
            .progress.has-break-#{$break} & {
                display: flex;
                align-items: flex-end;
            }
        }

        .progress__item {
            .progress.has-break-#{$break} & {
                flex: 1 1 0;
                display: inline-block;
                margin-top: 0;

                &:first-child {
                    flex-grow: .5;
                }

                &:last-child {
                    flex-grow: .5;
                }
            }
        }

        .progress__header {
            .progress.has-break-#{$break} & {
                padding-left: 0;
                padding-bottom: 48px;
                text-align: center;
                margin-top: 0;

                &::before {
                    width: 100%;
                    height: $progress-indicator-line-width;
                    top: auto !important;
                    bottom: math.div($progress-current-counter-size - $progress-indicator-line-width, 2);
                    right: 50%;
                    left: auto;
                }

                &::after {
                    display: none;
                }
            }

            .progress.has-break-#{$break} .progress__item:last-child > & {
                &::before {
                    top: auto;
                    right: 0;
                    width: 200%;
                }
            }

            .progress.has-break-#{$break} .progress__item.is-current & {
                &::before {
                    background: $progress-indicator-line-current-color;
                }
            }

            .progress.has-break-#{$break} .progress__item.is-done & {
                &::before {
                    background: $progress-indicator-line-done-color;
                }

                &::after {
                    background-color: $progress-indicator-line-done-color;
                }
            }

            .progress.has-break-#{$break} .progress__item.is-current + & {
                &::before {
                    display: block;
                }
            }

            .progress.has-break-#{$break} .progress__item:first-child & {
                text-align: left;
            }

            .progress.has-break-#{$break} .progress__item:last-child & {
                text-align: right;
            }
        }

        .progress__counter {
            .progress.has-break-#{$break} & {
                top: auto;
                bottom: 0;
                left: 50%;
                margin-left: -1 * math.div($progress-current-counter-size, 2);
            }

            .progress.has-break-#{$break} .progress__item:first-child & {
                left: 0;
                margin-left: 0;
            }

            .progress.has-break-#{$break} .progress__item:last-child & {
                left: 100%;
                margin-left: -$progress-current-counter-size;
            }
        }

        .progress__title {
            .progress.has-break-#{$break} & {
                padding-left: 10px;
                padding-right: 10px;
            }

            .progress.has-break-#{$break} .progress__item:first-child & {
                padding-left: 0;
            }

            .progress.has-break-#{$break} .progress__item:last-child & {
                padding-right: 0;
            }
        }

        .progress__error {
            .progress.has-break-#{$break} & {
                text-align: center;
                position: absolute;
                left: 0;
                right: 0;
                top: 100%;
                margin-top: 10px;
            }

            .progress.has-break-#{$break} .progress__item:first-child & {
                text-align: left;
            }

            .progress.has-break-#{$break} .progress__item:last-child & {
                text-align: right;
            }
        }

        .progress__info {
            .progress.has-break-#{$break} & {
                text-align: center;
                position: absolute;
                left: 0;
                right: 0;
                top: 100%;
                margin-top: 10px;
            }

            .progress.has-break-#{$break} .progress__item:first-child & {
                text-align: left;
            }

            .progress.has-break-#{$break} .progress__item:last-child & {
                text-align: right;
            }
        }

        .progress__content {
            .progress.has-break-#{$break} & {
                margin-left: 0;
                border: 0;
                padding: 15px 0;
            }

            .progress.has-break-#{$break} .progress__item & {
                display: none !important;
            }
        }
    }
}

@include progress-inline-break($break-sm, "sm");
@include progress-inline-break($break-md, "md");
@include progress-inline-break($break-lg, "lg");
@include progress-inline-break($break-xl, "xl");

.progress.progress--card {
    position: relative;
    display: flex;
    flex-direction: column;

    @media (min-width: $break-md) {
        align-items: flex-start;
        flex-direction: row;
    }
}

.progress__steps {
    @include list-unstyled();

    position: relative;
    z-index: 1;
    counter-reset: progress-counter;

    .progress--card & {
        width: 280px;
        flex-direction: column;
        padding-left: 0;
        flex: 0 0 auto;
        border-radius: 3px;
        background: $white;

        & > li:first-child {
            border-radius: 8px 8px 0 0;
        }

        & > li:last-child {
            border-radius: 0 0 8px 8px;
        }

        @media only screen and (max-width: ($break-md - 1)) {
            height: 100%;
            width: 100%;
            margin-top: 1rem;
        }

        @media (min-width: $break-xl) {
            width: 380px;
        }
    }
}

.progress__category {
    font-weight: 700;
    padding: 12px;
    color: $grey-t;
    width: 100%;
    border-top: 1px solid $white;
    background: $color-neutral-2;

    &:first-child {
        border-top: 0;
    }
}

.progress__item {
    counter-increment: progress-counter;
    position: relative;
    list-style: none;

    .progress--card & {
        width: 100%;
        border-top: 1px solid $white;
        padding: 12px;
        position: relative;
        background: $grey-m;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;

        &.progress__item--employee {
            background-color: $color-employee-3;
        }

        &.is-disabled {
            pointer-events: none;
            color: $color-neutral-4;
        }

        &.is-current:not(.has-subitems) {
            background: $white;
            border-top: 0;

            + .progress__item {
                border-top: 0;
            }
        }

        &.has-subitems {
            @media (min-width: $break-md) {
                padding: 16px;
            }
        }

        @media (min-width: $break-md) {
            padding: 16px;
            min-height: 74px;
        }
    }
}

.progress__item.progress__item--uncategorized {
    background: $grey-l;
}

.progress__sub-steps {
    @include list-unstyled();

    margin: 12px -12px -12px;
    position: relative;
    z-index: 1;
    counter-reset: progress-counter;
    background: $grey-m;

    .progress__item--employee & {
        background-color: $white;
    }

    @media (min-width: $break-md) {
        margin: 16px -16px -16px;
    }
}

.progress__sub-item {
    position: relative;
    padding-left: 12px;
    display: flex;
    align-items: center;

    &.is-current {
        background: $white;
    }

    &.is-disabled {
        color: $color-neutral-4;
        pointer-events: none;
    }

    &.progress__sub-item--employee {
        background: $color-employee-3;
    }

    &.progress__sub-item--employee.is-current {
        background: $white;
    }

    &::before {
        content: counters(progress-counter, ".");
        counter-increment: progress-counter;
        display: flex;
        justify-content: center;
        align-items: center;
        width: $progress-current-counter-size;
        text-align: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: $h5-size;
        line-height: $h5-line-height;
    }

    &.is-current::before {
        color: $color-selected-1;
    }

    &.progress__sub-item--employee.is-current::before {
        color: $color-employee-1;
    }

    @media (min-width: $break-md) {
        min-height: 60px;
        padding-left: 16px;
    }
}

.progress__header {
    @include reset-button();

    display: block;
    position: relative;
    padding-left: 44px;
    margin-top: 32px;
    min-height: $progress-current-counter-size;

    @media (min-width: $break-sm) {
        padding-left: 52px;
    }

    &::before {
        content: "";
        display: block;
        width: $progress-indicator-line-width;
        position: absolute;
        z-index: -1;
        top: -32px;
        left: $progress-indicator-line-position;
        bottom: 100%;
        background: $color-raw-grey-bright;

        @media (min-width: $break-sm) {
            left: 16px;
        }
    }

    &::after {
        content: "";
        display: block;
        width: $progress-indicator-line-width;
        position: absolute;
        z-index: -1;
        top: $progress-current-counter-mobile-size;
        left: $progress-indicator-line-position;
        bottom: 0;
        background: $color-raw-grey-bright;

        @media (min-width: $break-sm) {
            top: $progress-current-counter-size;
            left: 16px;
        }
    }

    .progress__item:first-child & {
        margin-top: 0;

        &::before {
            display: none;
        }
    }

    .progress__item:last-child & {
        &::after {
            display: none;
        }
    }

    .progress__item.is-current + .progress__item & {
        margin-top: 0;

        &::before {
            top: -15px;
        }
    }

    .progress--bottom .progress__item:last-child & {
        &::after {
            display: block;
        }
    }

    .progress--bottom .progress__item.is-current + .progress__item & {
        margin-top: 32px;

        &::before {
            display: block;
            top: -32px;
        }
    }

    .progress--card & {
        width: 100%;
        display: flex;
        align-items: center;
        min-height: 22px;
        padding-left: 36px;
        margin-top: 0;

        &:first-child {
            text-align: left;
        }

        &::after,
        &::before {
            content: none;
        }

        @media (min-width: $break-md) {
            padding-left: 48px;
        }
    }
}

button.progress__header {
    html[data-whatintent="mouse"] .progress--card & {
        &:hover {
            color: $purple;
        }
    }
}

.progress__header-inner {
    .progress--card & {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 4px;

        @media (min-width: $break-xs) {
            flex-wrap: nowrap;
            flex-direction: row;
            align-items: center;
        }
    }
}

.progress__sub-header-inner {
    .progress--card & {
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        border-top: 1px solid $white;
        padding: 12px 12px 12px 0;

        @media (min-width: $break-md) {
            padding: 16px 16px 16px 0;
        }
    }

    .progress--card .progress__sub-item.is-current + .progress__sub-item & {
        border-top: 0;
    }

    .progress--card .progress__sub-item.is-current & {
        border-top: 0;
    }
}

.progress__header-title-section {
    .progress--card & {
        flex: 1 1 auto;
    }
}

.progress__counter {
    display: flex;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    text-align: center;
    line-height: 24px;
    font-size: $progress-counter-font-size;
    color: $white;
    background-color: $white;
    border-radius: 50%;

    .progress--card & {
        top: 50%;
        transform: translateY(-50%);
        background-color: unset;

        & .progress__badge {
            @media (max-width: ($break-md - 1)) {
                padding: 4px;
                min-width: 24px;
                line-height: 16px;
                font-size: $badge-font-size-large-sm;
                font-weight: $badge-font-weight-large-sm;
            }

            & .badge__icon {
                width: $badge-size-large-icon-width;
                height: $badge-size-large-icon-height;

                @media (max-width: ($break-md - 1)) {
                    width: $badge-font-size-large-sm;
                    height: $badge-font-size-large-sm;
                }
            }
        }
    }
}

.progress__title {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.375;

    @media (min-width: $break-sm) {
        font-size: 22px;
        font-weight: 400;
    }

    .progress__item.is-done.progress__item--employee &,
    .progress__item.is-current.progress__item--employee & {
        color: $color-employee-1;
    }

    .progress--card & {
        font-size: 16px;
        font-weight: 700;
    }

    .progress__item.is-disabled & {
        pointer-events: none;
        color: $color-neutral-4;
    }
}

.progress__sub-title {
    display: flex;
    font-weight: 700;
    align-items: flex-start;

    .progress__sub-item.is-current & {
        color: $color-selected-1;
    }

    .progress__sub-item.is-current.progress__sub-item--employee & {
        color: $color-employee-1;
    }
}

.progress__error {
    display: none;
    color: $progress-invalid-color;
    font-size: 12px;
    font-weight: 700;

    .progress__item.is-invalid & {
        display: block;
    }
}

.progress__info {
    display: block;
    font-size: 14px;

    .progress--card & {
        text-overflow: clip;
        position: relative;
        overflow: hidden;

        @media (min-width: $break-md) {
            font-size: 14px;
        }
    }
}

.progress__icon {
    @include reset-icon();

    width: $progress-icon-size-sm;
    height: $progress-icon-size-sm;

    @media (min-width: $break-md) {
        height: $progress-icon-size;
        width: $progress-icon-size;
    }

    .progress__item.is-disabled.has-icon & {
        color: $color-neutral-4;
    }
}

.progress__content {
    display: block;
    position: relative;
    margin-top: 14px;
    margin-bottom: 24px;
    border: 2px solid $color-raw-grey-bright;
    border-radius: 3px;
    padding: 15px;

    .progress--bottom &,
    .progress__item.is-current & {
        display: block;

        &::after {
            content: "";
            display: block;
            width: $progress-indicator-line-width;
            position: absolute;
            z-index: -1;
            top: -16px;
            left: $progress-indicator-line-position - $progress-indicator-line-width;
            bottom: 100%;
            background: $color-raw-grey-bright;

            @media (min-width: $break-sm) {
                left: 16px - $progress-indicator-line-width;
            }
        }

        &::before {
            content: "";
            display: block;
            width: $progress-indicator-line-width;
            position: absolute;
            z-index: -1;
            top: 100%;
            bottom: -24px;
            left: $progress-indicator-line-position - $progress-indicator-line-width;
            background: $color-raw-grey-bright;

            @media (min-width: $break-sm) {
                left: 16px - $progress-indicator-line-width;
            }
        }
    }

    .progress--bottom & {
        &::before {
            display: none;
        }
    }

    .progress--card & {
        border: 0;
        padding: 0 0 0 20px;
        margin: 0;
        width: 100%;

        @media only screen and (max-width: ($break-md - 1)) {
            padding-left: 0;
            max-width: 100%;
            margin-top: 1rem;
        }

        @media (min-width: $break-lg) {
            min-width: calc(100% - 280px);
            max-width: calc(100% - 280px);
        }

        @media (min-width: $break-xl) {
            min-width: calc(100% - 380px);
            max-width: calc(100% - 380px);
        }
    }
}

.progress__header-meta-section {
    font-size: 16px;
    color: $text-color-base;
    white-space: nowrap;
    display: block;
    text-align: right;
}

.progress__sub-badge {
    margin-left: 5px;
}

.progress__footer {
    border-top: 1px solid $white;
    padding: 12px;
    width: 100%;
    background-color: $grey-m;

    @media (min-width: $break-md) {
        padding: 16px;
    }
}

// progress mobile steps
.progress__indicator-list {
    @include list-unstyled();

    display: flex;
}

.grid-collapse-trigger {
    .progress & {
        @include reset-button();
        @include button-link-variant($btn-primary-link-color, $btn-primary-link-hover-color, $btn-primary-link-pressed-color);
    }
}

.progress__indicator-list-icon {
    transition: transform .3s ease-in-out;

    &.is-open {
        transform: rotate(-180deg);
    }
}

.progress__indicator-list-item {
    display: block;
    position: relative;
    min-height: $progress-current-counter-size;
    flex: 1 1 0;

    &:first-child {
        &.is-current {
            margin-left: calc($progress-current-counter-size / 2 - $progress-counter-size / 2);
        }
    }

    &:last-child {
        flex-grow: 0;
        margin-right: $progress-current-counter-mobile-size;

        &.is-current {
            margin-right: $progress-current-counter-size;

            @media (min-width: $break-sm) {
                margin-right: calc($progress-current-counter-size + $progress-counter-size);
            }
        }

        &::before {
            content: none;
        }
    }

    &::before {
        content: "";
        width: 100%;
        height: $progress-indicator-line-width;
        display: block;
        position: absolute;
        background-color: $progress-step-color;
        top: 50%;
        transform: translateY(-50%);
    }

    &.is-done {
        &::before {
            background: $progress-indicator-line-done-color;
        }
    }
}

.progress__indicator-step-wrapper {
    top: 50%;
    position: absolute;

    .progress__indicator-list-item.is-current & {
        left: calc(($progress-current-counter-size / 2 - $progress-counter-size / 2) * -1);
        transform: translateY(-50%);
    }
}

.progress__dot {
    position: absolute;
    height: $progress-counter-size;
    width: $progress-counter-size;
    border-radius: 100%;
    background-color: $progress-step-color;
}

.progress__dot--done {
    background-color: $color-success-1;
}

.progress__dot--employee.progress__dot--disabled {
    background-color: $color-employee-3;
}

.progress__dot--employee.progress__dot--done {
    background-color: $color-employee-1;
}

.progress__dot-background {
    background-color: $white;
    position: absolute;
    width: $progress-counter-size;
    height: $progress-counter-size;
    border-radius: 100%;
    transform: translateY(-50%);
}
