@use "sass:math";

@import "~@telia-front/sass-tokens/index.scss";

$slider-handle-size: 20px;

.slider {
    padding-top: math.div($slider-handle-size, 2);
    padding-right: math.div($slider-handle-size, 2) + 1px;
    padding-bottom: 27px;
    padding-left: math.div($slider-handle-size, 2) + 1px;
    overflow: hidden;
    position: relative;

    * {
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        touch-action: none;
        user-select: none;
        box-sizing: border-box;
    }
}

.slider__target {
    position: relative;
    height: 2px;
    border-radius: 3px;
    background: $grey;

    &[disabled] {
        color: $grey-d;
    }

    &::before,
    &::after {
        content: "";
        display: block;
        position: absolute;
        height: 2px;
        width: 13px;
        top: 0;
        background: $grey;
    }

    &::before {
        left: -10px;
        border-radius: 3px 0 0 3px;
    }

    &::after {
        right: -10px;
        border-radius: 0 3px 3px 0;
    }
}

.slider__base {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    transform: translate3d(0, 0, 0);

    &::before,
    &::after {
        content: "";
        display: block;
        position: absolute;
        height: 24px;
        top: -9px;
        width: 100%;
    }

    &::before {
        left: -10px;
    }

    &::after {
        right: -10px;
    }
}

.slider__origin {
    position: absolute;
    z-index: 1;
    top: 0;
    left: auto;
    right: 0;
    height: 0;
    width: 100%;
    transform-origin: 0 0;
    will-change: transform;

    .slider__state-tap & {
        transition: transform .3s;
    }
}

.slider__state-drag * {
    cursor: inherit !important;
}

.slider__handle {
    position: absolute;
    width: $slider-handle-size;
    height: $slider-handle-size;
    right: -9px;
    top: -9px;
    border-radius: math.div($slider-handle-size, 2);
    background: $color-selected-1;
    cursor: pointer;
    transition: background-color 200ms;

    &::after {
        content: " ";
        position: absolute;
        height: 8px;
        width: 8px;
        left: 50%;
        top: 50%;
        display: block;
        background-color: $white;
        transform: translate(-50%, -50%);
        border-radius: 4px;
    }

    .slider__inner[disabled] & {
        background: $grey-d;
        cursor: default;
    }

    &.slider__hover,
    &.slider__active {
        background: $color-selected-2;
    }
}

.slider__tooltip {
    display: block;
    position: absolute;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    bottom: -18px;
    text-align: center;
    font-weight: 700;
    font-size: $font-size-small;
    white-space: nowrap;
}

.slider__tooltip--left {
    left: 0;
    right: auto;
    transform: none;
}

.slider__tooltip--right {
    left: auto;
    right: 0;
    transform: none;
}

.slider__tooltip--collapsed {
    bottom: 0;
}

.slider__tooltip--hidden {
    visibility: hidden;
}

.slider__connect {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform-origin: 0 0;
    will-change: transform;
    background-image: linear-gradient(270deg, $color-selected-1 0%, $color-selected-1 100%);

    &::before {
        background-image: linear-gradient(270deg, $color-selected-2 0%, $color-selected-1 100%);
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        transition: opacity 200ms;

        .slider__state-hover &,
        .slider__state-drag & {
            opacity: 1;
        }
    }

    .slider__inner[disabled] & {
        background: $grey-d;
    }

    .slider__state-tap & {
        transition: transform .3s;
    }
}

.slider__connect--reverse {
    &::before {
        background-image: linear-gradient(270deg, $color-selected-1 0%, $color-selected-2 100%);
    }
}

.slider__pips-wrapper {
    position: relative;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
    font-size: 12px;
}

.slider__pip {
    cursor: pointer;
    position: absolute;
    transform: translateX(-50%);
}

.slider__pip--active {
    color: $color-selected-1;
    transition: color .3s;
}

.slider__pip--disabled {
    color: $grey-d;
    cursor: auto;
}

.slider__pip-inner {
    display: flex;
    font-weight: 700;
}

.slider__pip-label {
    max-width: 90px;
}

.slider__pip-icon {
    margin-left: 4px;
    font-size: 8px;
}
