@import "~@telia-front/sass-tokens/index.scss";
@import "~@core-front/scroll-fade/styles.scss";

.scroll-fade {
    &.scroll-fade--has-arrows.scroll-fade--gutter-md {
        &.has-scrolled-to-right {
            --scroll-fade-arrow-left: #{$scroll-fade-transparent} 30px, #{$scroll-fade-color} 30%;
        }

        &.has-scrolled-to-left {
            --scroll-fade-arrow-right: #{$scroll-fade-transparent} 30px, #{$scroll-fade-color} 30%;
        }
    }
}

.scroll-fade__button {
    &.scroll-fade__button-left {
        .scroll-fade--gutter-md & {
            left: 10px;
        }
    }

    &.scroll-fade__button-right {
        .scroll-fade--gutter-md & {
            right: 10px;
        }
    }
}
