@import "~@core-front/sass-tokens/index.scss";

$carousel-paddings: (
    none 0,
    xsmall 8px,
    small 16px,
    medium 24px,
    large 32px,
    xlarge 48px,
    xxlarge 64px,
);

.carousel {
    overflow: hidden;

    &.carousel--without-slick,
    &.carousel--without-slick.carousel--with-arrows,
    &.carousel--without-slick.carousel--with-arrows.carousel--with-center-arrows {
        padding-top: $carousel-horizontal-padding-default;
        padding-bottom: $carousel-horizontal-padding-default;

        @media (max-width: ($break-sm - 1)) {
            padding-top: $carousel-horizontal-padding-default-sm;
            padding-bottom: $carousel-horizontal-padding-default-sm;
        }
    }

    &.carousel--with-arrows {
        padding-bottom: 4px;
        margin-bottom: -4px;

        &.carousel--with-center-arrows {
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }

    &.carousel--has-container {
        display: flex;
        justify-content: center;
    }
}

.carousel--full-width-container {
    margin-left: -$container-padding;
    margin-right: -$container-padding;

    @media (min-width: $break-sm) {
        margin-left: -$container-padding-sm;
        margin-right: -$container-padding-sm;
    }

    @media (min-width: $break-lg) {
        margin-left: -$container-padding-lg;
        margin-right: -$container-padding-lg;
    }
}

@each $size, $padding in $card-paddings {
    .carousel--full-width-left-#{$size} .carousel-container {
        padding-left: $padding;
    }
    
    .carousel--full-width-left-#{$size} {
        margin-left: -$padding;
    }

    .carousel--full-width-right-#{$size} .carousel-container {
        padding-right: $padding;
    }
    
    .carousel--full-width-right-#{$size} {
        margin-right: -$padding;
    }
}

.carousel--full-width {
    .container > & {
        @extend .carousel--full-width-container;
    }

    @each $size, $padding in $card-paddings {
        .card__content--padding-#{$size} > & .carousel-container {
            padding-left: $padding;
            padding-right: $padding;
        }

        .card__content--padding-#{$size} > & {
            margin-left: -$padding;
            margin-right: -$padding;
        }
    }

    @each $size, $padding in $card-paddings {
        .card__content--padding-right-#{$size} > & .carousel-container {
            padding-right: $padding;
        }

        .card__content--padding-left-#{$size} > & .carousel-container {
            padding-left: $padding;
        }

        .card__content--padding-horizontal-#{$size} > & .carousel-container {
            padding-left: $padding;
            padding-right: $padding;
        }

        .card__content--padding-right-#{$size} > & {
            margin-right: -$padding;
        }
        
        .card__content--padding-left-#{$size} > & {
            margin-left: -$padding;
        }

        .card__content--padding-horizontal-#{$size} > & {
            margin-left: -$padding;
            margin-right: -$padding;
        }
    }
}

@each $size, $padding in $carousel-paddings {
    .carousel--full-width-#{$size} .carousel-container {
        padding-left: $padding;
        padding-right: $padding;
    }

    .carousel--full-width-#{$size} {
        margin-left: -$padding;
        margin-right: -$padding;
    }
}

.carousel--fade {
    .carousel__fade--left,
    .carousel__fade--right {
        display: none;
    }

    @media (min-width: $break-xl) {
        .carousel__fade--left,
        .carousel__fade--right {
            display: block;
            flex-grow: 1;
            z-index: 1;
        }

        .carousel__fade--left {
            margin-right: 0;
            background: linear-gradient(to left, transparent, $white 24px) no-repeat;
        }

        .carousel__fade--right {
            margin-left: 0;
            background: linear-gradient(to right, transparent, $white 24px) no-repeat;
        }

        &.carousel--with-arrows {
            .carousel__fade--left,
            .carousel__fade--right {
                margin-bottom: $btn-min-height;
            }
        }

        &.carousel--with-small-arrows {
            .carousel__fade--left,
            .carousel__fade--right {
                margin-bottom: $btn-small-min-height;
            }
        }

        &.carousel--with-link-arrows {
            .carousel__fade--left,
            .carousel__fade--right {
                margin-bottom: 24px;
            }
        }

        &.carousel--with-link-arrows.carousel--with-small-arrows {
            .carousel__fade--left,
            .carousel__fade--right {
                margin-bottom: 16px;
            }
        }

        &.carousel--with-center-arrows,
        &.carousel--with-link-arrows.carousel--with-small-arrows.carousel--with-center-arrows {
            .carousel__fade--left,
            .carousel__fade--right {
                margin-bottom: 0;
            }
        }

        &.carousel--inverse {
            .carousel__fade--left {
                background: linear-gradient(to left, transparent, $grey-l 24px) no-repeat;
            }

            .carousel__fade--right {
                background: linear-gradient(to right, transparent, $grey-l 24px) no-repeat;
            }
        }
    }
}

.carousel__slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    z-index: 0;

    .carousel--with-arrows & {
        padding-bottom: $btn-min-height;
    }

    .carousel--with-small-arrows & {
        padding-bottom: $btn-small-min-height;
    }

    .carousel--with-link-arrows.carousel--with-small-arrows & {
        padding-bottom: 16px;
    }

    .carousel--with-link-arrows & {
        padding-bottom: 24px;
    }

    .slick-list {
        @include transition(('animation': long, 'properties': (margin-left, margin-right)));
    
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        height: 100%;
        overflow: visible;
        margin-right: calc(($container-padding-sm / 2) * -1);
        margin-left: calc(($container-padding-sm / 2) * -1);

        &:focus {
            outline: none;
        }

        @media (max-width: ($break-sm - 1)){
            margin-right: calc($carousel-default-peeking-slide-width - $container-padding / 2);
            margin-left: calc(($container-padding / 2) * -1);

            .carousel--in-end & {
                margin-right: calc(($container-padding / 2) * -1);
                margin-left: calc($carousel-default-peeking-slide-width - $container-padding / 2);
            }

            .carousel--is-center &,
            .carousel--is-full & {
                margin-right: calc(($carousel-horizontal-padding-default-sm / 2) * -1);
                margin-left: calc(($carousel-horizontal-padding-default-sm / 2) * -1);
            }
        }

        @media (min-width: $break-sm) and (max-width: ($break-xl - 1)){
            margin-right: calc($carousel-default-peeking-slide-width - $container-padding / 2);
            margin-left: calc(($container-padding-sm / 2) * -1);

            .carousel--in-end & {
                margin-right: calc(($container-padding-sm / 2) * -1);
                margin-left: calc($carousel-default-peeking-slide-width - $container-padding / 2);
            }

            .carousel--is-center.carousel--has-container & {
                margin-left: 16px;
                margin-right: 30px;
            }

            .carousel--is-center &,
            .carousel--is-full & {
                margin-right: calc(($carousel-horizontal-padding-default / 2) * -1);
                margin-left: calc(($carousel-horizontal-padding-default / 2) * -1);
            }

            .carousel--in-end.carousel--is-left & {
                margin-right: calc(($carousel-horizontal-padding-default / 2) * -1);
                margin-left: calc(($carousel-align-left-peeking-slide-width - $container-padding ) + $carousel-horizontal-padding-default-sm / 2);
            }

            .carousel--is-left & {
                margin-right: calc(($carousel-align-left-peeking-slide-width - $container-padding ) + $carousel-horizontal-padding-default-sm / 2);
                margin-left: calc(($carousel-horizontal-padding-default / 2) * -1);
            }
        }

        @media (min-width: $break-xl){
            .carousel--is-center & {
                margin-right: 0;
                margin-left: 0;
            }

            .carousel--is-center.carousel--has-container & {
                margin-right: 0;
                margin-left: -12px;
            }
        }
    }

    .slick-track {
        position: relative;
        left: 0;
        top: 0;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        height: 100%;
    }

    .slick-loading .slick-track {
        visibility: hidden;
    }

    .slick-slide {
        @include transition(('animation': long, 'properties': opacity));

        display: none;
        min-height: 1px;
        padding: $carousel-horizontal-padding-default calc($carousel-horizontal-padding-default / 2);

        & > div {
            display: flex;
            flex-grow: 1;
        }

        [dir="rtl"] & {
            float: right;
        }

        &.slick-loading img {
            display: none;
        }

        &.dragging img {
            pointer-events: none;
        }

        .carousel--has-after-slides-content & {
            padding-bottom: 16px;
        }

        @media (max-width: ($break-sm - 1)) {
            padding: $carousel-horizontal-padding-default-sm calc($carousel-horizontal-padding-default-sm / 2);

            .carousel--is-full & {
                &[aria-hidden="true"] {
                    opacity: 0;
                }
            }
        }

        @media (min-width: $break-sm){
            .carousel--fade & {
                &.slick-hidden {
                    opacity: 1;
                }
            }
        }

        @media (max-width: ($break-xl - 1)){
            &.slick-hidden {
                opacity: 0;
            }

            .carousel--in-end & {
                opacity: 1;
            }
        }
    }

    &.slick-initialized .slick-slide {
        display: flex;
        flex-direction: column;
    }

    .slick-loading .slick-slide {
        visibility: hidden;
    }

    .slick-arrow.slick-hidden {
        display: none;
    }

    .carousel--has-container & {
        @media (min-width: $break-lg) {
            &::before,
            &::after {
                content: none;
            }
        }
    }
}

.carousel__item {
    display: flex;
    width: 100%;

    &--width-min {
        width: auto;
    }
}

.carousel__show-more {
    padding: 20px 10px;
    margin-bottom: 0;
}

.carousel__dots {
    @include list-unstyled();

    text-align: center;
    font-size: 0;

    .carousel--with-arrows & {
        position: absolute;
        bottom: $carousel-dots-with-arrows-bottom;
        left: auto;
        right: auto;
        width: 100%;
    }

    .carousel--with-small-arrows & {
        bottom: $carousel-dots-with-small-arrows-bottom;
    }

    .carousel--with-center-arrows & {
        position: relative;
        bottom: 0;
    }

    li {
        display: inline-block;
        margin: 0 2px;
    }

    button {
        @include reset-button();

        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $carousel-dots-color;
        color: transparent;
    }

    li.slick-active button {
        background-color: $carousel-dots-active-color;
    }

    &.carousel__dots--left {
        text-align: left;

        li:first-child {
            margin-left: 0;
        }
    }
}

.carousel__nav {
    // important to override a very specific selector in button styles
    // the default there is relative so forcing absolute here does not cause issues
    position: absolute !important;
    // the default there is 1
    z-index: 2 !important;
    bottom: 0;
    right: 0;
}

.carousel__nav--prev {
    right: 56px;
}

.carousel--with-small-arrows {
    .carousel__nav--prev {
        right: 40px;
    }
}

.carousel--with-center-arrows {
    .carousel__slider {
        padding-bottom: 0;
    }

    &.carousel--with-arrows,
    &.carousel--with-link-arrows,
    &.carousel--with-arrows.carousel--with-link-arrows {
        .carousel__slider {
            padding-bottom: 0;
        }
    }

    .carousel__nav {
        top: 50%;
        bottom: unset;
        right: unset;
        transform: translateY(-50%);
    }

    &.carousel--with-dots {
        .carousel__nav {
            top: calc(50% - 8px);

            @media (min-width: $break-sm) {
                top: calc(50% - 16px);
            }
        }
    }

    .carousel__nav--prev {
        left: 0;
        right: unset;

        @media (min-width: $break-sm) {
            left: -21px;

            .carousel--with-small-arrows & {
                left: -13px;
            }
        }
    }

    .carousel__nav--next {
        right: 0;

        @media (min-width: $break-sm) {
            right: -21px;

            .carousel--with-small-arrows & {
                right: -13px;
            }
        }
    }
}

.carousel__after-slides-content {
    margin-bottom: 16px;
}
