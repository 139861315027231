.header__megamenu-grid {
    flex-wrap: wrap;
    margin: 0 -20px;

    @media (min-width: $break-lg) {
        flex-wrap: nowrap;
        display: inline-flex;
    }
}

.header__nav-megamenu-dropdown {
    width: 100%;
    max-width: 100%;

    & .header__dropdown-content-wrapper {
        background-color: $grey-m;
        border-radius: 0;
    }
}

.header__megamenu-column {
    padding: 0 20px 20px;
    width: 240px;

    @media (max-width: ($break-lg - 1)) {
        width: inherit;
    }
}

.header__megamenu-column--bordered {
    @media (min-width: $break-lg) {
        border-left: 1px solid $color-raw-black-fare;
    }
}

.header__megamenu-title {
    margin-bottom: 16px;
    font-size: 22px;

    &.header__megamenu-title--no-margin {
        margin-bottom: 0;
    }
}

.header__megamenu-title-tag {
    margin-bottom: 16px;
}

.header__megamenu-link {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 400;
    color: $black;

    @media (min-width: $break-xl) {
        font-size: 16px;
    }

    @media (max-width: ($break-lg - 1)) {
        font-size: 16px;
    }

    html[data-whatintent="mouse"] &:hover,
    &.is-current {
        color: $purple;
    }
}

.header__megamenu-link--employee {
    color: $pink;

    html[data-whatintent="mouse"] &:hover,
    &.is-current {
        color: $pink-l;
    }
}

.header__megamenu-link-inner {
    margin-right: 6px;
}

.header__megamenu-link-secure-icon,
.header__megamenu-external-icon {
    margin-left: 3px;
    font-size: 16px;
}

.header__megamenu-link-image {
    display: inline-block;
    vertical-align: middle;
    margin-top: -1px;
}

.header__megamenu-section {
    & + & {
        margin-top: 20px;
    }
}

.header__megamenu-tag {
    margin-top: -1px;
}

.header__megamenu-banners {
    @media (min-width: $break-lg) {
        margin-top: 40px;
        margin-bottom: -40px;
    }
}

.header__megamenu-banners-grid {
    @media (max-width: $break-lg) {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
    }

    .header__megamenu-banners-grid-col {
        @media (max-width: $break-lg) {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

.header__megamenu-list--bordered {
    border-top: 1px solid $color-raw-black-fare;
    padding-top: 15px;
    margin-top: -5px;
}

.header__megamenu-list-item {
    & + & {
        @media (max-width: $break-xl) {
            margin-top: 8px;
        }
    }
}
