@import "~@telia-front/sass-tokens/index.scss";

.sms-preview {
    position: relative;
    max-width: 320px;
    height: auto;

    &::before {
        content: "";
        display: block;
        width: 100%;
        padding-top: 199%;
    }
}

.sms-preview__container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url("~@telia-front/static-assets/images/phone-model.svg");
    background-repeat: no-repeat;
    background-size: cover;
}

.sms-preview__container-inner {
    position: relative;
    height: 100%;
    padding: 12% 10%;
}

.sms-preview__text {
    height: inherit;
    overflow: auto;

    .sms-preview--middle & {
        display: flex;
        align-items: center;
    }
}
