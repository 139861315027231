@import "~@telia-front/sass-tokens/index.scss";

$timeline-gutter: 20px;

.timeline {
    position: relative;
}

.timeline__entry {
    position: relative;
    margin-left: $timeline-gutter;
    margin-right: $timeline-gutter;

    @media (min-width: $break-sm) {
        margin-left: 25px;
        margin-right: 25px;
    }

    &::before {
        content: "";
        position: relative;
        display: block;
        width: 3px;
        background: $grey-m;
        top: 0;
        bottom: 0;
        left: 6px;
        height: 20px;

        @media (min-width: $break-sm) {
            position: absolute;
            left: 6px;
            height: auto;
        }
    }

    &:last-child {
        margin-bottom: 0;

        &::before {
            bottom: calc(100% - #{$timeline-gutter});
        }
    }
}

.timeline__icon {
    box-sizing: content-box;
    position: relative;
    display: block;
    top: 0;
    left: -5px;
    color: $grey;
    border: 5px solid $white;
    background-clip: padding-box;
    margin-top: 0;

    @media (min-width: $break-sm) {
        position: absolute;
        top: 16px;
    }

    .timeline__entry.has-warning & {
        color: $red-l;
    }

    .timeline__entry.is-current & {
        color: $purple;
    }

    .timeline__entry.is-done & {
        color: $green;
    }
}

.timeline__content {
    position: relative;
    padding: 20px 0;
    margin-left: 0;

    @media (min-width: $break-sm) {
        padding: 20px 1em 0;
        margin-left: 25px;
    }

    .timeline__entry:last-child & {
        padding-bottom: 0;
    }
}
