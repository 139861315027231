@use "sass:math";

@import "~@telia-front/sass-tokens/index.scss";
@import "~@core-front/card/styles.scss";

.card.bg-brand-secondary-bg-subtlest {
    --card-hover-background: var(--color-brand-secondary-bg-subtlest-hover);
}

.card--with-flip-content {
    transition: height 150ms ease-in-out;

    &.is-animating {
        overflow: hidden;
    }

    &.is-fixed-height {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
    }
}

.card--full-width {
    @media (max-width: ($break-sm - 1)) {
        width: calc(100% + 30px);
        margin-left: -$base-horizontal-spacing-xs;
        margin-right: -$base-horizontal-spacing-xs;
    }
}

.card--type-inactive {
    border: $card-border-width solid $card-type-inactive-border-color;
}

.card--type-attention {
    border: $card-border-width solid $card-type-attention-border-color;
}

.card--type-light {
    border: $card-border-width solid $card-type-light-border-color;
}

.card__close {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2; // should be higher than card__content z-index
}

.card__figure {
    position: relative;
    flex-shrink: 0;
}

.card__image {
    width: 100%;
    height: auto;
    display: block;

    .card--attention & {
        border-radius: 0;
    }

    .card--border-radius-small & {
        border-top-left-radius: $card-border-radius-small;
        border-top-right-radius: $card-border-radius-small;
    }

    .card--border-radius-medium & {
        border-top-left-radius: $card-border-radius-medium;
        border-top-right-radius: $card-border-radius-medium;
    }

    .card--border-radius-large & {
        border-top-left-radius: $card-border-radius-large;
        border-top-right-radius: $card-border-radius-large;
    }
}

.card__content {
    .card--with-flip-content & {
        opacity: 0;
        transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;

        &.is-entered {
            opacity: 1;
        }

        &.is-exited,
        &.is-exiting {
            opacity: 0;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            pointer-events: none;
        }
    }

    .card--with-flip-content &:not(.card__flip-content) {
        transform: translateY(-20px);

        &.is-entered {
            transform: translateY(0);
        }

        &.is-exiting,
        &.is-exited {
            transform: translateY(-20px);
        }
    }

    .card--with-flip-content.is-fixed-height & {
        flex: 0 0 100%;

        &.is-exited,
        &.is-exiting {
            position: static;
        }
    }
}

.card__footer {
    @include card-border-radius();
}

.card.card--left-bordered {
    border-left: $card-left-border-width solid;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    &.card--border-radius-small > :first-child,
    &.card--border-radius-medium > :first-child,
    &.card--border-radius-large > :first-child {
        border-top-left-radius: 0;
    }

    &.card--border-radius-small > :last-child,
    &.card--border-radius-medium > :last-child,
    &.card--border-radius-large > :last-child {
        border-bottom-left-radius: 0;
    }

    &.card--left-bordered-blue {
        border-left-color: var(--color-palette-blue-500);
    }

    &.card--left-bordered-blue-l {
        border-left-color: var(--color-palette-blue-300);
    }

    &.card--left-bordered-green {
        border-left-color: var(--color-palette-green-500);
    }

    &.card--left-bordered-green-l {
        border-left-color: var(--color-palette-green-300);
    }

    &.card--left-bordered-pink {
        border-left-color: var(--color-palette-pink-500);
    }

    &.card--left-bordered-pink-l {
        border-left-color: var(--color-palette-pink-300);
    }

    &.card--left-bordered-orange {
        border-left-color: var(--color-palette-orange-500);
    }

    &.card--link.card--left-bordered::before {
        left: $card-left-border-width * -1;
    }
}

.card__flip-toggle {
    position: absolute;
    z-index: 2;
    top: 16px;
    right: 16px;
}

.card__flip-content {
    transform: translateY(20px);

    &.is-exiting,
    &.is-exited {
        transform: translateY(20px);
    }

    &.is-entered {
        transform: translateY(0);
    }

    .card.is-fixed-height & {
        transform: translate(-100%, 20px);

        &.is-exiting,
        &.is-exited {
            transform: translate(-100%, 20px);
        }

        &.is-entered {
            transform: translate(-100%, 0);
        }
    }
}
