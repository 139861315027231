@import "~@core-front/sass-tokens/index.scss";

.close-button {
    line-height: 0;
    padding: 8px;
    color: $close-button-hover-color;
    border: none;
    background: none;
    border-radius: 50%;
}

.close-button__inner {
    display: block;
    position: relative;
    width: 24px;
    height: 24px;
    background-color: $close-button-background;
}

.close-button__icon {
    @include reset-icon();

    position: absolute;
    top: 50%;
    left: 50%;
    color: $close-button-color;
    font-size: 24px;
    transform: translate(-50%, -50%);

    html[data-whatintent="mouse"] .close-button:hover & {
        color: inherit;
    }
}
