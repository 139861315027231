@use "sass:math";

.header__megamenu-banner {
    position: relative;
    background-color: $grey-m;
    padding: 20px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    font-weight: 400;
    color: $black;

    html[data-whatintent="mouse"] &:hover {
        color: $black;
    }

    @media (min-width: $break-lg) {
        background-color: $white;
    }
}

.header__megamenu-banner-inner {
    display: flex;
    align-items: center;
    width: 100%;
}

.header__megamenu-banner-content {
    width: 100%;

    .header__megamenu-banner--with-background & {
        @media (min-width: $break-lg) {
            width: 60%;
            flex-grow: 1;
        }
    }
}

.header__megamenu-banner-background {
    width: 40%;
    max-width: 400px;
    display: none;
    justify-content: flex-end;

    @media (min-width: $break-lg) {
        display: flex;
        align-self: stretch;
    }
}

.header__megamenu-banner-background-inner {
    margin-top: -20px;
    margin-bottom: -20px;
    margin-right: -20px;
    overflow: hidden;
    width: 100%;
    display: flex;
    border-radius: 8px;
}

.header__megamenu-banner-background-image-wrapper {
    position: relative;
    width: 100%;

    &::before {
        content: "";
        display: block;
        width: 100%;
        padding-top: (math.div(70, 400) * 100%);
    }
}

.header__megamenu-banner-background-image {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
}
