@import "~@core-front/sass-tokens/index.scss";

.icon {
    width: 1em;
    height: 1em;
    fill: currentcolor;
}

.icon--align-middle {
    vertical-align: middle;
    margin-top: -.25em;
}

.icon--vertical-align-middle {
    vertical-align: middle;
}

.icon--xxs {
    font-size: $icon-font-size-xxs;
}

.icon--xs {
    font-size: $icon-font-size-xs;
}

.icon--sm {
    font-size: $icon-font-size-sm;
}

.icon--md {
    font-size: $icon-font-size-md;
}

.icon--lg {
    font-size: $icon-font-size-lg;
}

.icon--xl {
    font-size: $icon-font-size-xl;
}

.icon--xxl {
    font-size: $icon-font-size-xxl;
}

.icon--xxxl {
    font-size: $icon-font-size-xxxl;
}

.icon--background {
    background-color: var(--color-neutral-bg-level-1, #{$white});
    border-radius: 50%;
    padding: .1em;
}
