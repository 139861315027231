.header__languages {
    line-height: 1;

    .header__top & {
        display: none;

        @media (min-width: $break-sm) {
            display: block;
        }

        @media (max-width: $break-lg) {
            padding: 0 10px;
        }
    }

    .header__dropdown & {
        padding: 0 6px;

        @media (min-width: $break-sm) {
            display: none;
        }
    }
}

.header__languages-list {
    font-size: 12px;
    line-height: 16px;
    display: flex;

    @media (min-width: $break-lg) {
        padding: 0 24px;
    }

    .header__dropdown & {
        font-size: 14px;
        line-height: 20px;
        justify-content: flex-end;
    }

    .header__top & {
        font-size: 12px;
    }
}

.header__languages-item {
    position: relative;

    &.is-current {
        .header__languages-link::after {
            display: block;
            content: "";
            border-bottom: 2px solid $purple;
            padding-top: 13px;
        }
    }
}

.header__languages-link {
    opacity: .9;
    font-weight: 400;
    outline-offset: -3px;
    font-size: 12px;
    display: block;
    padding: 16px 8px 0;

    .header__dropdown & {
        font-size: 14px;
    }
}

.header__languages-item.is-current .header__languages-link {
    color: $purple;
}
