@use "sass:map";

.header {
    position: fixed;
    pointer-events: none;
    z-index: map.get($zindex, header);
    top: 0;
    left: 0;
    right: 0;
    line-height: $base-line-height;

    .header__bottom-search-wrapper {
        @media (max-width: ($break-xl - 1)) {
            visibility: visible;
            opacity: 1;
            transition: opacity .15s ease-in;
        }

        @media (min-width: $break-xl) {
            transition: width .15s ease-in-out;
        }

        &:not(.is-open) {
            @media (max-width: ($break-xl - 1)) {
                visibility: hidden;
                opacity: 0;
                transition: opacity .15s ease-out, visibility .15s ease-out, width .15s ease-out;
                width: 320px;
            }

            @media (min-width: $break-xl) {
                padding-left: 20px;
                margin-right: 10px;
                width: 180px;
            }

            @media (max-width: ($break-lg - 1)) {
                position: relative;
                width: 100%;
            }
        }

        &.is-open {
            @media (min-width: $break-lg) {
                width: 320px;

                &::before {
                    content: "";
                    position: absolute;
                    height: 45px;
                    width: 60px;
                    background: linear-gradient(to left, $white 0, $white 30px, rgba($white, 0) 100%);
                    left: -45px;
                }
            }
        }
    }

    &.header--minimized-search {
        .header__bottom-search-wrapper:not(.is-open) {
            visibility: hidden;
            opacity: 0;
        }

        .header__actions-item--search {
            display: flex !important;
        }
    }
}

.header__main {
    position: static;
    z-index: 4;

    @media (min-width: $break-lg) {
        position: relative;
    }

    .header--animating-nav.header--has-overlay &,
    .header--open-nav.header--has-overlay & {
        @media (min-width: $break-lg) {
            position: relative;
        }
    }

    .header--open-sso & {
        @media (min-width: $break-lg) {
            position: static;
        }
    }

    .header--open-nav & {
        @media (max-width: $break-lg) {
            position: relative;
        }
    }
}

.header__bottom-main {
    pointer-events: auto;
    position: relative;
    z-index: -1;
    transition: transform 500ms cubic-bezier(.86, 0, .07, 1);

    .header.has-scrolled & {
        pointer-events: none;
        transform: translateY(-100%);
    }

    &[data-state="exited"] {
        z-index: auto;
    }
}

.header__top {
    min-height: 48px;
    background-color: $grey-l;
    color: $black;
    white-space: nowrap;

    @media (min-width: $break-sm) {
        overflow: auto;
    }
}

.header__top-inner {
    display: flex;
    justify-content: space-between;
    height: 48px;
}

.header__top-left {
    display: flex;
    flex-shrink: 0;
}

.header__top-right {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    overflow: hidden;

    @media (min-width: $break-sm) {
        flex-shrink: 0;
        overflow: visible;
    }

    .header__employee & {
        flex-shrink: 1;
        overflow: visible;
    }

    &.logged-in {
        flex-shrink: 1;
    }
}

.header__top-container {
    padding-left: 0;
    padding-right: 5px;

    @media (min-width: $break-sm) {
        padding-left: 0;
        padding-right: 15px;
    }

    @media (min-width: $break-lg) {
        padding-left: $container-padding-lg;
        padding-right: $container-padding-lg;
    }
}

.header__employee {
    .header__top {
        min-height: 40px;
    }

    .header__top-inner {
        height: 40px;
    }
}

.header__bottom {
    position: relative;
    background-color: $white;
    padding: 0;
    z-index: 1;
    min-height: 64px;

    @media (min-width: $break-lg) {
        min-height: 72px;
    }
}

.header__bottom-shadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(100% + 2px);
    overflow: hidden;
    pointer-events: none;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 2px;
        box-shadow: 0 1px 2px rgba($black, .1);
    }
}

.header__bottom-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 64px;

    @media (min-width: $break-lg) {
        height: 72px;
    }
}

.header__bottom-left {
    display: flex;
    align-items: center;

    @media (min-width: $break-lg) {
        flex-grow: 0;
    }

    .header--open-search & {
        display: none;

        @media (min-width: $break-sm) {
            display: flex;
        }
    }
}

.header__bottom-right-search {
    position: relative;
    width: 100%;
    height: 48px;

    @media (min-width: $break-xl) {
        max-width: 320px;
    }

    @media (max-width: ($break-lg - 1)) {
        position: absolute;
        width: calc(100% - $header-search-offset-left-md);
        right: $header-search-offset-right;
    }

    @media (max-width: ($break-sm - 1)) {
        width: calc(100% - $header-search-offset-left-xs);
    }
}

.header__bottom-right {
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;

    @media (max-width: ($break-lg - 1)) {
        position: absolute;
        right: $container-padding-sm;
        width: 100%;
    }

    @media (max-width: ($break-sm - 1)) {
        right: $container-padding;
    }
}

.header__bottom-search-wrapper {
    position: absolute;
    right: 0;
    width: 100%;
    height: 45px;
    z-index: 1;

    @media (max-width: ($break-xl - 1)) {
        visibility: visible;
        opacity: 1;
        transition: opacity .15s ease-in;
    }

    @media (min-width: $break-xl) {
        transition: width .15s ease-in-out;
    }

    &:not(.is-open) {
        @media (max-width: ($break-xl - 1)) {
            visibility: hidden;
            opacity: 0;
        }

        @media (min-width: $break-xl) {
            padding-left: 20px;
            margin-right: 10px;
            width: 180px;
        }
    }

    &.is-open {
        @media (min-width: $break-lg) {
            width: 320px;

            &::before {
                content: "";
                position: absolute;
                height: 45px;
                width: 60px;
                background: linear-gradient(to left, $white 0, $white 30px, rgba($white, 0) 100%);
                left: -45px;
            }
        }
    }
}

.header__logo {
    display: block;
    position: relative;
    z-index: 1;
    margin-right: 48px;
    flex-shrink: 0;
    width: 75px;

    @media (min-width: $break-lg) {
        padding: 14px 0;
    }
}

.header__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba($grey-l, .7);
    z-index: 3;
    pointer-events: auto;
    will-change: opacity;
    opacity: 0;
    transition: opacity 100ms ease-out;

    &.is-entered {
        opacity: 1;
    }
}

.header__scroll-fade-wrapper {
    flex-shrink: 1;
    flex-grow: 1;
    min-width: 0;

    @media (min-width: $break-sm) {
        flex-shrink: 0;
    }
}

.header__accessibility {
    padding: 8px 20px;

    .header__top & {
        font-size: 12px;
        padding: 9px 10px;
        display: none;

        @media (min-width: $break-lg) {
            display: block;
        }
    }

    .header__dropdown & {
        @media (min-width: $break-lg) {
            display: none;
        }
    }

    @media (max-width: ($break-lg - 1)) {
        padding-left: 16px;
        display: block;
    }
}

.list__content {
    .header__actions-item--cart > .header__dropdown .header__dropdown-content > .text > .list > .list__inner > .list__item > .list__item-inner > & {
        padding: 6px 8px;
    }
}
