@import "~@telia-front/sass-tokens/index.scss";

.logo--small {
    width: 76px;

    [data-brand="diil"] & {
        width: 60px;
    }

    [data-brand="super"] & {
        width: 40px;
    }

    [data-brand="simpel"] & {
        width: 80px;
    }

    [data-brand="greenit"] & {
        width: 102px;
    }

    &.digital-hub-logo {
        width: 94px;
    }
}

.logo__img {
    display: block;
    width: 100%;
    height: auto;
}

.logo__path {
    .logo--color-neutral & {
        fill: var(--color-neutral-graphic);
    }

    [data-brand="telia"][data-theme="dark"] &,
    [data-brand="telia"] [data-theme="dark"] &,
    [data-brand="greenit"][data-theme="dark"] &,
    [data-brand="greenit"] [data-theme="dark"] &,
    [data-theme="dark"] .digital-hub-logo &,
    .digital-hub-logo[data-theme="dark"]  & {
        fill: #ffffff;
    }
}

// Diil logo only, needs an "inverted" text color.
.logo__path-foreground {
    .logo--color-neutral & {
        fill: var(--color-neutral-bg-level-1);
    }
}
