@import "~@core-front/sass-tokens/index.scss";

.form-helper {
    display: flex;
    font-size: $form-helper-size;
    text-align: left;
}

.form-helper--valid {
    color: $form-helper-valid-color;
}

.form-helper--error {
    color: $form-helper-error-color;
    font-weight: $form-helper-error-font-weight;
}

.form-helper__icon {
    font-size: 16px;
    line-height: 1;
    margin-right: 5px;
    flex: 0 0 auto;

    .form-helper--help & {
        color: $form-helper-help-color;
    }

    .form-helper--error & {
        color: $form-helper-error-icon-color;
    }

    .form-helper--valid & {
        color: $form-helper-valid-icon-color;
    }
}

.form-helper__spinner {
    display: inline-block;
    width: 1em;
    height: 1em;

    .spinner__circle {
        height: 14px;
        width: 14px;
    }
}

.form-helper__check-circle {
    stroke: $form-helper-valid-icon-color;
    opacity: 0;
    animation: fade-in .3s linear .9s both;
}

.form-helper__check-check {
    @include animation((
        'animation': long,
        'name': draw-check,
        'iteration-count': 1,
        'direction': both,
    ));

    stroke-dasharray: $form-helper-check-length $form-helper-check-length;
    stroke: $form-helper-valid-icon-color;
}

@keyframes draw-check {
    0% {
        stroke-dashoffset: $form-helper-check-length;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
