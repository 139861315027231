@import "~@core-front/sass-tokens/index.scss";

.spinner {
    display: block;
    text-align: center;
    width: 100%;
}

.spinner__circle {
    animation: rotate 2s linear infinite;
    height: 42px;
    transform-origin: center center;
    width: 42px;
}

.spinner__path {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
    stroke-width: 4px;
    stroke-miterlimit: 10;
    stroke: $spinner-color;
}

.spinner__text {
    display: block;
    margin-top: 20px;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -136;
    }
}
