@use "sass:map";

@import "~@telia-front/sass-tokens/index.scss";
@import "~@core-front/tag/styles.scss";

$tag-colors: (
    primary: (
        default: var(--color-brand-primary-bg-strong, $color-background-7),
        text: var(--color-brand-primary-text-onbg-strong, $color-background-6)
    ),
    recommended: (
        default: var(--color-brand-expressive-bg-subtle, $color-recommended-2),
        text: var(--color-brand-expressive-text, $color-recommended-1),
    ),
    attention: (
        default: var(--color-attention-bg-subtle, $color-attention-2),
        text: var(--color-attention-text, $color-attention-3),
    ),
    employee: (
        default: var(--color-employee-bg-strong, $color-employee-1),
        text: var(--color-employee-text-onbg-strong, $color-background-6),
    ),
    success: (
        default: var(--color-success-bg-subtle, $color-success-2),
        text: var(--color-success-text, $color-success-3),
    ),
    information: (
        default: var(--color-info-bg-subtle, $color-information-2),
        text: var(--color-info-text, $color-information-3),
    ),
    service: (
        default: var(--color-neutral-bg-level-1, $white),
    ),
    error: (
        default: var(--color-error-bg-strong, $color-error-1),
        text: var(--color-error-text-onbg-strong, $white),
    ),
    campaign-1: (
        default: var(--color-campaign-1-bg, $color-raw-purple-fare),
        text: var(--color-campaign-1-text, $color-raw-purple-dim),
    ),
    campaign-2: (
        default: var(--color-campaign-2-bg, $color-campaign-light-2),
        text: var(--color-campaign-2-text, $color-raw-red-light),
    ),
    campaign-3: (
        default: var(--color-campaign-3-bg, $color-campaign-2),
        text: var(--color-campaign-3-text, $white),
    ),
    campaign-4: (
        default: var(--color-campaign-4-bg, $color-campaign-4),
        text: var(--color-campaign-4-text, $white),
    ),
    text: (
        default: var(--color-neutral-bg-subtle, $color-raw-grey-bright),
        text: var(--color-neutral-text, $color-raw-black-strong),
    ),
);

/* Modifier: Purposes */
@each $name, $colors in $tag-colors {
    .telia-tag--#{$name} {
        background: map.get($colors, "default");
        color: map.get($colors, "text");
    }
}

.telia-tag__icon {
    @include reset-icon();

    flex-basis: $tag-svg-size;
    width: $tag-svg-size;
    height: $tag-svg-size;

    .telia-tag--animating:not(.telia-tag--large) &,
    .telia-tag--icon:not(.telia-tag--large) & {
        flex-basis: $tag-svg-size-icon;
        width: $tag-svg-size-icon;
        height: $tag-svg-size-icon;
    }

    .telia-tag--animating.telia-tag--small &,
    .telia-tag--icon.telia-tag--small & {
        flex-basis: $tag-svg-size-icon-small;
        width: $tag-svg-size-icon-small;
        height: $tag-svg-size-icon-small;
    }
}
