@use "sass:math";
@import "~@telia-front/sass-tokens/index.scss";

.magazine {
    display: flex;
    justify-content: center;

    &.is-disabled {
        opacity: .6;
    }
}

.magazine__inner {
    display: flex;
    position: relative;
    max-width: 100%;
}

.magazine__header {
    display: block;
    color: $grey-d;
    text-align: center;
    margin-bottom: 10px;
    margin-left: -15px;
    margin-right: -15px;

    .magazine__input:checked ~ .magazine__label & {
        color: $black;
    }
}

.magazine__title {
    display: block;
    font-weight: 700;
}

.magazine__info {
    display: block;
    font-size: $font-size-small;
}

.magazine__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
}

.magazine__label {
    z-index: 1;
    width: 100%;
    flex: 1 0 auto;
    cursor: pointer;

    .magazine__input:disabled ~ & {
        cursor: default;
    }

    &::after {
        content: "";
        display: block;
        width: 130px;
        max-width: 100%;
        padding-top: math.div(350, 260) * 100%;
    }
}

.magazine__image {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    html[data-whatintent="keyboard"] .magazine__input:focus ~ .magazine__label & {
        @include keyboard-focus();
    }

    &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;

        .magazine__label:hover & {
            box-shadow: inset 0 0 0 3px $color-selected-1;
        }

        .magazine__input:checked ~ .magazine__label & {
            box-shadow: inset 0 0 0 3px $color-selected-1;
        }

        .magazine__input:disabled ~ .magazine__label:hover & {
            box-shadow: none;
        }
    }
}

.magazine__img {
    box-shadow: 15px 0 30px -10px rgba($black, .2);
}

.magazine__checked-badge {
    display: none;
    position: absolute;
    bottom: 1px;
    z-index: 2;
    left: 50%;
    transform: translate(-50%, 50%);

    .magazine__input:checked ~ .magazine__label & {
        display: inline-block;
    }
}
