@use "sass:map";

@import "~@core-front/sass-tokens/index.scss";

$tooltip-arrow-width: 8px;
$tooltip-distance-from-reference: 10px;
$tooltip-arrow-size: 20px;
$tooltip-border-width: 1px;
$tooltip-arrow-distance-from-content: calc(($tooltip-arrow-size / 2 - $tooltip-border-width) * -1);

.tooltip {
    position: absolute;
    z-index: map.get($zindex, tooltip);
    display: flex;
    visibility: hidden;
    will-change: visibility, transform, opacity;
    opacity: 0;
    transition: opacity 100ms ease-out;
    max-width: calc(100% - #{$base-horizontal-spacing-xs * 2});

    &.is-ready {
        visibility: visible;
    }

    &.is-ready.is-shown.is-entered {
        opacity: 1;
    }
}

body.is-scroll-disabled .tooltip,
.tooltip--on-header {
    z-index: map_get($zindex, tooltip-on-header);
}

.tooltip[data-popper-placement="top"],
.tooltip[data-popper-placement="bottom"] {
    flex-direction: column;
}

.tooltip__arrow {
    position: relative;
    width: $tooltip-arrow-size;
    height: $tooltip-arrow-size;

    .tooltip[data-popper-placement="top"] & {
        clip-path: inset(50% 0 0 0);
        bottom: $tooltip-arrow-distance-from-content;
    }

    .tooltip[data-popper-placement="right"] & {
        clip-path: inset(0 50% 0 0);
        left: $tooltip-arrow-distance-from-content;
    }

    .tooltip[data-popper-placement="bottom"] & {
        clip-path: inset(0 0 50% 0);
        top: $tooltip-arrow-distance-from-content;
    }

    .tooltip[data-popper-placement="left"] & {
        clip-path: inset(0 0 0 50%);
        right: $tooltip-arrow-distance-from-content;
    }
}

.tooltip__arrow::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    border: $tooltip-border-color solid 1px;
    background-color: $tooltip-color;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}

.tooltip__inner {
    transition: transform 100ms ease-out;

    .tooltip[data-popper-placement="top"] & {
        transform: translateY($tooltip-arrow-width * -1);
    }

    .tooltip[data-popper-placement="right"] & {
        transform: translateX($tooltip-arrow-width);
    }

    .tooltip[data-popper-placement="bottom"] & {
        transform: translateY($tooltip-arrow-width);
    }

    .tooltip[data-popper-placement="left"] & {
        transform: translateX($tooltip-arrow-width * -1);
    }

    .tooltip[data-popper-placement="top"].is-ready.is-shown.is-entered & {
        transform: translateY(($tooltip-arrow-width + $tooltip-distance-from-reference) * -1);
    }

    .tooltip[data-popper-placement="right"].is-ready.is-shown.is-entered & {
        transform: translateX($tooltip-arrow-width + $tooltip-distance-from-reference);
    }

    .tooltip[data-popper-placement="bottom"].is-ready.is-shown.is-entered & {
        transform: translateY($tooltip-arrow-width + $tooltip-distance-from-reference);
    }

    .tooltip[data-popper-placement="left"].is-ready.is-shown.is-entered & {
        transform: translateX(($tooltip-arrow-width + $tooltip-distance-from-reference) * -1);
    }
}

.tooltip__content {
    font-size: 14px;
    background: $tooltip-color;
    padding: 16px;
    box-shadow: $elevation-5;
    box-sizing: border-box;
    overflow: hidden;
    max-width: 100%;
    border-radius: 8px;
    border: 1px solid $tooltip-border-color;

    .tooltip--fixed-width & {
        width: 224px;
    }

    .tooltip--large & {
        width: 400px;
    }

    .tooltip--no-padding & {
        padding: 0;
    }
    
    .tooltip--has-close-button & {
        padding-right: 32px;
    }
}

.tooltip__close {
    position: absolute;
    right: 0;
    top: 0;
}
