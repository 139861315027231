@import "~@telia-front/sass-tokens/index.scss";

$countdown-gutter: 8px;
$countdown-gutter-lg: 16px;
$countdown-time-transform: translate(-50%, -50%);

.countdown {
    display: flex;
    flex-wrap: wrap;
    margin-right: $countdown-gutter * .5 * -1;
    margin-left: $countdown-gutter * .5 * -1;
    margin-bottom: $countdown-gutter * -1;

    @media (min-width: $break-xl) {
        margin-right: $countdown-gutter-lg * .5 * -1;
        margin-left: $countdown-gutter-lg * .5 * -1;
        margin-bottom: $countdown-gutter-lg * -1;
    }
}

.countdown-item {
    display: inline-block;
    text-align: center;
    padding-right: $countdown-gutter * .5;
    padding-left: $countdown-gutter * .5;
    padding-bottom: $countdown-gutter;

    @media (min-width: $break-xl) {
        padding-right: $countdown-gutter-lg * .5;
        padding-left: $countdown-gutter-lg * .5;
        padding-bottom: $countdown-gutter-lg;
    }
}

.countdown-item__inner {
    position: relative;
    border-radius: 8px;
    width: 56px;
    height: 52px;

    @media (min-width: $break-sm) {
        width: 64px;
        height: 60px;
    }

    &:not(.countdown-item__inner--custom-color) {
        color: $color-raw-purple-fare;
    }

    &:not(.countdown-item__inner--custom-background) {
        background-color: $color-raw-purple-dim;
    }

    .countdown-item--dark > & {
        &:not(.countdown-item__inner--custom-color) {
            color: $color-raw-purple-dim;
        }

        &:not(.countdown-item__inner--custom-background) {
            background-color: $color-raw-purple-fare;
        }
    }
}

.countdown-item__unit {
    margin-top: 5px;
    color: $black;

    .countdown-item--dark > & {
        color: $color-raw-white-strong;
    }
}

.countdown-item__time {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: $countdown-time-transform;
}

.countdown-item__time--animate {
    &-enter {
        opacity: 0;
        transform: $countdown-time-transform translateY(-10px);
    }

    &-enter-active {
        opacity: 1;
        transform: $countdown-time-transform translateY(0);
        transition: opacity 100ms ease-in, transform 100ms ease-in;
    }

    &-exit-active {
        opacity: 0;
        transform: $countdown-time-transform translateY(10px);
        transition: opacity 100ms ease-out, transform 100ms ease-out;
    }
}
