@import "~@core-front/sass-tokens/index.scss";

.signature__canvas {
    position: relative;
    display: flex;
    height: 230px;
    margin-top: 10px;
    background: $signature-background;
    border: 1px solid $color-inactive-1;
    border-radius: 3px;
    overflow: auto;

    .signature--light & {
        background: $white;
    }

    .signature--employee & {
        border-color: $color-employee-1;
    }

    .signature--disabled & {
        opacity: .5;
    }

    .signature--dark.signature--disabled & {
        background: $signature-background-disabled;
    }

    .signature--invalid & {
        border-color: $color-error-1;
    }

    .signature:not(.signature--disabled) &:focus {
        border-color: $black;
    }

    .signature__canvas-inner {
        position: absolute;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
    }
}

.signature__label {
    font-size: 16px;
}

.signature__form-helper {
    margin-top: 5px;
}
