@import "~@core-front/sass-tokens/index.scss";

.mini-form {
    position: relative;
    z-index: 0;
}

.mini-form__input {
    padding-right: $mini-form-input-padding-right;
}

.mini-form__button {
    position: absolute;
    z-index: 2;
    top: $mini-form-button-top;
    right: 8px;

    .mini-form--no-label-no-break & {
        top: $mini-form-hide-label-button-top;
    }

    .mini-form--no-label-sm & {
        @media (min-width: $break-sm) {
            top: $mini-form-hide-label-button-top;
        }
    }

    .mini-form--no-label-md & {
        @media (min-width: $break-md) {
            top: $mini-form-hide-label-button-top;
        }
    }
}
