.header__nav {
    .header__bottom & {
        @media (max-width: ($break-lg - 1)) {
            display: none;
        }
    }

    @media (min-width: $break-lg) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        flex-grow: 1;
    }

    .header__dropdown & {
        display: block;
    }
}

.header__nav-list {
    @include list-unstyled();

    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @media (min-width: $break-lg) {
        flex-direction: row;
    }

    &::before {
        content: "";
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        height: 1px;
        top: 0;
        background: $grey;

        @media (min-width: $break-lg) {
            display: none;
        }
    }
}

.header__nav-item {
    position: relative;
    list-style: none;
    flex-grow: 1;
    display: inline-block;
    box-sizing: initial;

    &::after {
        content: "";
        display: block;
        height: 1px;
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        background: $grey;

        @media (min-width: $break-lg) {
            display: none;
        }
    }

    &:last-child {
        &::after {
            display: none;
        }
    }
}

.header__nav-link {
    display: flex;
    align-items: center;
    color: $black;
    font-weight: 400;
    padding: 16px;
    transition: color $transition-default;
    font-size: 14px;
    justify-content: flex-start;

    .header__dropdown & {
        font-size: 20px;
    }

    @media (min-width: $break-lg) {
        width: 100%;
        text-align: center;
        line-height: 1;
        padding: 15px 10px;
    }

    @media (min-width: $break-xl) {
        font-size: 16px;
    }

    html[data-whatintent="mouse"] &:hover,
    .header__nav-item.is-open > &,
    .header__nav-item.is-current > & {
        color: $purple;
    }

    .header__nav-label-inner {
        .header__nav-item.is-current > &::after {
            @media (min-width: $break-lg) {
                display: block;
                content: "";
                border-bottom: 2px solid $purple;
                padding-top: 70px;
                position: absolute;
                width: 100%;
                z-index: 2;
            }
        }

        .header__nav-item.is-current.has-submenu > &::after {
            @media (min-width: $break-lg) {
                width: calc(100% + 15px);
            }
        }
    }

    .header__nav-item:first-child & {
        @media (min-width: $break-lg) {
            padding-left: 0;
        }
    }

    .header__nav-item:nth-last-child(2) & {
        @media (min-width: $break-lg) {
            padding-left: 15px;
        }
    }
}

.header__nav-link--employee {
    color: $pink;

    html[data-whatintent="mouse"] &:hover,
    .header__nav-item.is-open > &,
    .header__nav-item.is-current > & {
        color: $pink-l;
    }
}

.header__nav-icon {
    @include reset-icon();

    display: block;
    font-size: 24px;
    margin-right: 10px;

    @media (min-width: $break-lg) {
        display: none;
    }
}

.header__nav-item-icon {
    font-size: 24px;
    margin-right: 10px;
}

.header__nav-label {
    flex: 1 1 auto;

    @media (min-width: $break-lg) {
        display: flex;
        max-width: 100%;
        flex: initial;
    }
}

.header__nav-label-inner {
    position: relative;
    padding-right: 2px;
    display: flex;
    align-items: center;

    @media (min-width: $break-lg) {
        min-height: 2em;
    }
}

.header__nav-arrow {
    @include reset-icon();

    flex-shrink: 0;
    font-size: 24px;
    display: initial;

    @media (min-width: $break-lg) {
        font-size: 16px;
    }

    .header__nav-item.is-open > .header__nav-link > & {
        @media (max-width: ($break-lg - 1)) {
            transform: rotate(180deg);
        }
    }
}

.header__nav-secondary-icon {
    font-size: 16px;
    margin-left: 3px;
}

.header__nav-megamenu {
    display: none;

    .header__nav-megamenu-dropdown & {
        display: block;
    }

    .header__nav-item.is-open > & {
        display: block;

        @media (min-width: $break-lg) {
            display: none;
        }
    }

    .header__nav-item > & {
        padding: 16px;
    }
}

.header__nav-megamenu-dropdown {
    .header__dropdown-content {
        padding: 32px 20px 40px;
    }
}

.header__nav-item-button-container {
    padding: 32px;

    @media (min-width: $break-lg) {
        display: none;
    }

    .header__nav-item-button {
        box-sizing: border-box;
    }
}
