@use "sass:math";

@import "~@telia-front/sass-tokens/index.scss";
@import "~@core-front/card-old/styles.scss";

.card-old {
    box-shadow: 0 1px 1px rgba($black, .15);
    border-radius: $card-old-border-radius;
}

.card-old--with-flip-content {
    transition: height 150ms ease-in-out;

    &.is-animating {
        overflow: hidden;
    }

    &.is-fixed-height {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
    }
}

.card-old--shadow-large {
    box-shadow: 0 50px 20px -40px rgba($black, 10%);
    // shadow does not show up on smaller card-olds
    min-height: 100px;
}

.card-old--full-width {
    @media (max-width: ($break-sm - 1)) {
        width: calc(100% + 30px);
        margin-left: -$base-horizontal-spacing-xs;
        margin-right: -$base-horizontal-spacing-xs;
    }
}

.card-old--left-bordered {
    border-color: transparent; // Fixes Safari with border-left
    border-left: 6px solid;
    border-radius: $card-old-border-radius;
}

.card-old--left-bordered-blue {
    border-left-color: $blue;
}

.card-old--left-bordered-blue-l {
    border-left-color: $blue-l;
}

.card-old--left-bordered-green {
    border-left-color: $green;
}

.card-old--left-bordered-green-l {
    border-left-color: $green-l;
}

.card-old--left-bordered-pink {
    border-left-color: $pink;
}

.card-old--left-bordered-pink-l {
    border-left-color: $pink-l;
}

.card-old--left-bordered-orange {
    border-left-color: $orange;
}

.card-old--border {
    border: $card-old-border-width solid $grey-l;

    /**
     * Ideally, card-old--grey class should give grey-l border, so that the background and border color logic would be the same.
     * However, due to bad decisions made in the past, card-old--grey class is used to get grey-d border in lots of places.
     * Migration path:
     *  1. Use inactive type on card-old in all places where grey-d border is coming via grey color bordered card-old currently;
     *  2. Change logic here so that grey and grey-light would give same border color
     *  3. Use grey color everywhere to get grey-l border
     *  4. Remove grey-light option.
     */

    &.card-old--grey {
        border-color: $grey-d;
    }

    &.card-old--grey-light {
        border-color: $grey-l;
    }
}

.card-old--invisible {
    border-color: transparent;
}

.card-old--employee {
    border: $card-old-border-width solid $color-employee-1;
}

.card-old--selected {
    border: $card-old-border-width solid $color-selected-1;
}

.card-old--inactive {
    border: $card-old-border-width solid $card-old-inactive-color;
}

.card-old--attention {
    border-top: $card-old-border-width solid $color-attention-1;
    border-color: $color-attention-1;
}

.card-old--recommended {
    border-top: $card-old-border-width solid $color-recommended-1;
    border-color: $color-recommended-1;
}

.card-old--error {
    border-top: $card-old-border-width solid $color-error-1;
    border-color: $color-error-1;
}

.card-old--border,
.card-old--inverse,
.card-old--invisible,
.card-old--employee,
.card-old--selected {
    box-shadow: none;
}

.card-old--badge-top {
    margin-top: math.div(30px, 2);
}

.card-old--badge-bottom {
    margin-bottom: math.div(30px, 2);
}

.card-old__badge-wrapper {
    position: absolute;
    left: 10px;
    right: 10px;
    pointer-events: none;
    display: flex;
    justify-content: center;
}

.card-old__badge-wrapper--top {
    top: 0;
}

.card-old__badge-wrapper--bottom {
    bottom: 0;
}

.card-old__badge-wrapper-inner {
    border-radius: 100rem;
    background: $body-bg;
    pointer-events: auto;
    position: relative;
    z-index: 1;

    .card-old__badge-wrapper--top & {
        transform: translate(0, -50%);
        top: -1px;
    }

    .card-old__badge-wrapper--bottom & {
        transform: translate(0, 50%);
        bottom: -1px;
    }
}

.card-old__close {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.card-old__tag {
    position: absolute;
    top: -15px;
    right: -15px;
    z-index: 1;
}

.card-old__figure {
    position: relative;
    flex-shrink: 0;
}

.card-old__image {
    width: 100%;
    height: auto;
    display: block;

    .card-old--border &,
    .card-old--selected &,
    .card-old--attention & {
        border-radius: 0;
    }

    .card-old--shape-option & {
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
    }

    .card-old--shape-option.card-old--border &,
    .card-old--shape-option.card-old--selected &,
    .card-old--shape-option.card-old--attention & {
        border-top-right-radius: 12px - $card-old-border-width;
        border-top-left-radius: 12px - $card-old-border-width;
    }
}

.card-old__content {
    @media (min-width: $break-sm) {
        padding: $base-horizontal-spacing;
    }

    .card-old--with-flip-content & {
        opacity: 0;
        transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;

        &.is-entered {
            opacity: 1;
        }

        &.is-exited,
        &.is-exiting {
            opacity: 0;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            pointer-events: none;
        }
    }

    .card-old--with-flip-content &:not(.card-old__flip-content) {
        transform: translateY(-20px);

        &.is-entered {
            transform: translateY(0);
        }

        &.is-exiting,
        &.is-exited {
            transform: translateY(-20px);
        }
    }

    .card-old--with-flip-content.is-fixed-height & {
        flex: 0 0 100%;

        &.is-exited,
        &.is-exiting {
            position: static;
        }
    }
}

.card-old__content--padding-none {
    padding: 0;
}

.card-old__content--padding-default {
    padding: $base-horizontal-spacing-xs;

    @media (min-width: $break-sm) {
        padding: $base-horizontal-spacing;
    }
}

.card-old__content--padding-medium {
    padding: 20px $base-horizontal-spacing-xs;

    @media (min-width: $break-sm) {
        padding: 40px;
    }
}

.card-old__content--padding-cta {
    padding: 40px $base-horizontal-spacing-xs;

    @media (min-width: $break-sm) {
        padding: 40px;
    }

    @media (min-width: $break-md) {
        padding: 60px 40px;
    }

    @media (min-width: $break-lg) {
        padding: 80px 40px;
    }
}

.card-old__content--padding-image {
    padding: 40px $base-horizontal-spacing-xs;

    @media (min-width: $break-sm) {
        padding: 40px 30px;
    }

    .grid__col--xs-6 & {
        padding: 10px 5px;

        @media (min-width: $break-sm) {
            padding: 40px 30px;
        }
    }
}

.card-old__footer {
    display: block;
    position: relative;
    border-top: 0;
    padding: $base-horizontal-spacing-xs;

    @media (min-width: $break-sm) {
        padding: $base-horizontal-spacing;
    }

    @include card-old-border-radius();
}

.card-old__footer--padding-none {
    padding-left: 0;
    padding-right: 0;
}

.card-old__footer--padding-default {
    padding: $base-horizontal-spacing-xs;

    @media (min-width: $break-sm) {
        padding: $base-horizontal-spacing;
    }
}

.card-old__footer--padding-medium,
.card-old__footer--padding-cta {
    padding-left: $base-horizontal-spacing-xs;
    padding-right: $base-horizontal-spacing-xs;

    @media (min-width: $break-sm) {
        padding-left: 40px;
        padding-right: 40px;
    }
}

.card-old__footer--padding-image {
    padding-left: $base-horizontal-spacing-xs;
    padding-right: $base-horizontal-spacing-xs;

    @media (min-width: $break-sm) {
        padding-left: 30px;
        padding-right: 30px;
    }

    .grid__col--xs-6 & {
        padding-left: 5px;
        padding-right: 5px;

        @media (min-width: $break-sm) {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}

.card-old__header--padding-none {
    padding-left: 0;
    padding-right: 0;
}

.card-old__header--padding-default {
    padding: $base-horizontal-spacing-xs;

    @media (min-width: $break-sm) {
        padding: $base-horizontal-spacing;
    }
}

.card-old__header--padding-medium,
.card-old__header--padding-cta {
    padding-left: $base-horizontal-spacing-xs;
    padding-right: $base-horizontal-spacing-xs;

    @media (min-width: $break-sm) {
        padding-left: 40px;
        padding-right: 40px;
    }
}

.card-old__header--padding-image {
    padding-left: $base-horizontal-spacing-xs;
    padding-right: $base-horizontal-spacing-xs;

    @media (min-width: $break-sm) {
        padding-left: 30px;
        padding-right: 30px;
    }

    .grid__col--xs-6 & {
        padding-left: 5px;
        padding-right: 5px;

        @media (min-width: $break-sm) {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}

.card-old__badge.badge--inactive,
.card-old__badge.badge--selected,
.card-old__badge.badge--attention {
    &::before,
    &::after {
        content: "";
        position: absolute;
        top: 50%;
        width: 12px;
        height: 10px;
        margin-top: -5px;
    }

    &::before {
        left: -11px;
    }

    &::after {
        right: -11px;
    }
}

.card-old__flip-toggle {
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
}

.card-old__flip-content {
    transform: translateY(20px);

    &.is-exiting,
    &.is-exited {
        transform: translateY(20px);
    }

    &.is-entered {
        transform: translateY(0);
    }

    .card-old.is-fixed-height & {
        transform: translate(-100%, 20px);

        &.is-exiting,
        &.is-exited {
            transform: translate(-100%, 20px);
        }

        &.is-entered {
            transform: translate(-100%, 0);
        }
    }
}
