@import "~@telia-front/sass-tokens/index.scss";

$pagination-link-size-mobile: 42px;
$pagination-link-size-desktop: 26px;
$pagination-font-size-mobile: 14px;
$pagination-font-size-desktop: 12px;
$pagination-spacing-mobile: 10px;
$pagination-spacing-desktop: 4px;
$pagination-border-width: 1px;

.pagination-number {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: $pagination-font-size-mobile;
    font-weight: 400;
    white-space: nowrap;

    @media (min-width: $break-sm) {
        font-size: $pagination-font-size-desktop;
    }
}

.pagination-number--left {
    justify-content: flex-start;
}

.pagination-number--right {
    justify-content: flex-end;
}

.pagination-number__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
    margin: 0;
    padding: 0;
    list-style: none;
}

.pagination-number__text,
.pagination-number__item {
    margin: $pagination-spacing-mobile;

    @media (min-width: $break-sm) {
        margin: $pagination-spacing-desktop;
    }
}

.pagination-number__link {
    position: relative;
    display: block;
    width: $pagination-link-size-mobile;
    height: $pagination-link-size-mobile;
    border: $pagination-border-width solid transparent;
    border-radius: 50%;
    background: $white;
    color: $black;
    font-weight: 400;
    line-height: $pagination-link-size-mobile;
    text-align: center;
    transition: all 150ms ease;

    @media (min-width: $break-sm) {
        width: $pagination-link-size-desktop;
        height: $pagination-link-size-desktop;
        line-height: $pagination-link-size-desktop;
    }

    .pagination-number--light & {
        background: $grey-m;
    }

    html[data-whatintent="mouse"] &:hover {
        border-color: $purple;
        color: $purple;
    }

    .pagination-number__item.is-active & {
        background: $purple;
        color: $white;
        font-weight: 700;
    }

    html[data-whatintent="mouse"] .pagination-number__item.is-active & {
        &:hover {
            background: $purple;
            color: $white;
        }
    }
}
