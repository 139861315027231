.header__segment-list {
    display: flex;
    font-size: 12px;

    @media (min-width: $break-sm) {
        font-size: 14px;
    }

    & > .list__inner {
        flex-wrap: nowrap;
    }
}

.header__segment-link {
    display: block;
    color: $text-color-base;
    padding: 16px 8px 0;
    border-top: 2px solid transparent;
    font-weight: 700;
    transition: $transition-default;
    transition-property: color, background-color;
    background: rgba($white, 0);
    outline-offset: -5px;

    @media (min-width: $break-sm) {
        padding: 14px 12px 0;
    }

    html[data-whatintent="mouse"] &:hover {
        color: $purple;
    }

    .header__segment-item.is-current &,
    .header__segment-item-with-icon.is-current & {
        color: $purple;
        background-color: $white;
        border-top-color: $purple;
        font-weight: 700;

        .header__segment--employee & {
            border-top-color: $pink;
            color: $pink;
        }

        .header__segment--employee-test & {
            border-top-color: $blue;
            color: $blue;
        }

        .header__segment--employee-dev & {
            border-top-color: $orange;
            color: $orange;
        }

        &:hover {
            color: $purple;

            .header__segment--employee & {
                color: $pink;
            }

            .header__segment--employee-test & {
                color: $blue;
            }

            .header__segment--employee-dev & {
                color: $orange;
            }
        }
    }

    .header__segment-item.is-current.is-employee & {
        color: $pink;
        border-top-color: $pink;

        &:hover {
            color: $pink;
        }
    }

    .header__segment--employee & {
        border-top-color: $pink;
        color: $white;

        html[data-whatintent="mouse"] &:hover {
            color: $pink;
            background-color: $white;
        }
    }

    .header__segment--employee-test & {
        border-top-color: $blue;
        color: $white;

        html[data-whatintent="mouse"] &:hover {
            color: $blue;
            background-color: $white;
        }
    }

    .header__segment--employee-dev & {
        border-top-color: $orange;
        color: $white;

        html[data-whatintent="mouse"] &:hover {
            color: $orange;
            background-color: $white;
        }
    }
}

.header__segment-item {
    &.is-current {
        .header__segment-link::after {
            display: block;
            content: "";
            border-bottom: 2px solid $purple;
            padding-top: 13px;
        }
    }

    &:not(.is-current):last-child .header__segment-link {
        padding-right: 5px;
    }

    &:first-child .header__segment-link {
        padding-left: 16px;

        @media (min-width: $break-sm) {
            padding-left: 24px;
        }

        @media (min-width: $break-lg) {
            padding-left: 0;
        }
    }

    & + .header__segment-item {
        margin-left: unset;
    }

    &.is-employee {
        .header__segment-link {
            color: $pink;
        }

        &:not(.is-current) .header__segment-link:hover {
            color: $pink-l;
        }

        &.is-current {
            .header__segment-link::after {
                border-bottom-color: $pink;
            }
        }
    }
}

.header__segment:not(.header__segment--employee, .header__segment--employee-dev, .header__segment--employee-test) {
    .header__segment-link {
        border-top: unset;
    }

    .header__segment-item.is-current,
    .header__segment-item-with-icon.is-current {
        .header__segment-link {
            background-color: unset;
        }
    }
}

.header__segment-item-with-icon {
    .header__segment-link {
        padding-left: 3px;
    }
}

.header__segment-icon {
    margin-right: 3px;
    padding-left: 15px;

    @media (min-width: $break-sm) {
        padding-left: 25px;
    }

    @media (min-width: $break-lg) {
        padding-left: 0;
    }
}

.header__employee {
    .header__segment-link,
    .header__segment-item.is-current .header__segment-link,
    .header__segment-item:not(.is-current) + .header__segment-item:not(.is-current) .header__segment-link,
    .header__segment-item.is-current + .header__segment-item:not(.is-current) .header__segment-link {
        padding: 10px;
        color: $white;

        @media (min-width: $break-sm) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .header__segment {
        width: unset;
    }

    .header__segment-list {
        font-size: 12px;
    }

    .header__segment-icon {
        padding-left: 0;
    }
}

.header__segment--employee,
.header__segment--employee-dev,
.header__segment--employee-test {
    .header__segment-icon {
        margin-right: 10px;
    }
}
