@import "~@core-front/sass-tokens/index.scss";

.tag-group {
    display: flex;
    flex-direction: column;

    &.tag-group--align-horizontal {
        flex-direction: row;
    }
}

.tag-group__tag {
    .tag-group--align-vertical & + & {
        margin-top: -15px;
    }

    .tag-group--align-vertical & + &.telia-tag--medium {
        margin-top: -12px;
    }

    .tag-group--align-vertical & + &.telia-tag--small {
        margin-top: -9px;
    }

    .tag-group--align-horizontal & + & {
        margin-left: -15px;
    }

    .tag-group--align-horizontal & + &.telia-tag--medium {
        margin-left: -12px;
    }

    .tag-group--align-horizontal & + &.telia-tag--small {
        margin-left: -9px;
    }
}
