@import "~@core-front/sass-tokens/index.scss";

.search {
    position: relative;
}

.search__input {
    padding-left: 43px;

    .search--small & {
        padding-left: 29px;
    }

    .textfield--small.textfield--with-icon & {
        padding-right: 0;
    }
}

.search__textfield .textfield__label {
    color: $search-textfield-label-color;
}

.textfield__label {
    &::before {
        .search__textfield & {
            margin-right: 33px;
        }

        .search__textfield.textfield--small & {
            margin-right: 26px;
        }

        .search__textfield.is-focused &,
        .search__textfield.is-dirty & {
            margin-right: 10px;
        }

        .search__textfield.textfield--small.is-focused &,
        .search__textfield.textfield--small.is-dirty & {
            margin-right: 6px;
        }
    }

    .textfield--with-icon.search__textfield & {
        padding-right: 0;
    }

    .textfield__label-text {
        .textfield--with-icon.search__textfield & {
            padding-right: 0;
        }
    }
}

.search__action {
    position: absolute;
    left: 0;
    top: 7px;
    height: 42px;
    padding: 9px 5px 9px 16px;
    border: none;
    outline: none;
    background-color: transparent;
    z-index: 2;

    html[data-whatintent="mouse"] &:hover {
        color: $search-action-hover-color;
    }

    &:disabled {
        color: $search-action-disabled-color;
    }

    .search--small & {
        height: 26px;
        padding: 5px 6px 5px 10px;
    }

    .search--no-label-no-break & {
        top: 0;
    }

    .search--no-label-sm & {
        @media (min-width: $break-sm) {
            top: 0;
        }
    }

    .search--no-label-md & {
        @media (min-width: $break-md) {
            top: 0;
        }
    }
}

.search__action-icon {
    @include reset-icon();

    display: block;
    font-size: 24px;

    .search--small & {
        font-size: 16px;
    }
}
