@use "sass:map";

@import "~@core-front/sass-tokens/index.scss";

.dropdown {
    position: absolute;
    max-width: calc(100vw - 20px);
    z-index: map.get($zindex, suggestion);
}

.dropdown--width-default {
    width: 320px;
}

.dropdown__suggestion {
    @include transition(('animation': short, 'properties': (transform, opacity)));

    max-height: none;
    transform: translateY(-10px);
    opacity: 0;

    .dropdown.is-entered & {
        transform: translateY(0);
        opacity: 1;
    }
}

.dropdown.is-hidden {
    @include visually-hidden();
}
