@import "~@telia-front/sass-tokens/index.scss";

.footer {
    color:  var(--color-neutral-text);
    background: var(--color-brand-primary-bg-subtle);

    &.footer--neutral {
        background: var(--color-neutral-bg-level-2);
    }

    @media print {
        display: none;
    }
}

.footer__link {
    display: inline-flex;
    font-weight: 400;
    padding: 3px 0;
    color:  var(--color-neutral-text);

    @media (min-width: $break-md) {
        padding: 0;
    }
}

.footer__link--social {
    color: var(--color-neutral-graphic);
    display: initial;
    padding: 0;
}

.footer__content {
    display: none;
    line-height: 20px;
    padding-bottom: 21px;

    @media (min-width: $break-md) {
        display: block;
        margin-left: 0;
        margin-right: 0;
        background-color: transparent;
    }
}

.footer__title {
    font-weight: 700;

    @media (min-width: $break-md) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 18px; 
    }
}

.footer__footer {
    padding-bottom: 24px;

    .footer--align-center & * {
        text-align: center;
    }

    &:only-child {
        padding: 32px 0;
    }
}

.footer__footer-item {
    color: var(--color-neutral-text-subtle);

    .list--direction-inline.list--bordered > .list__inner > &:not(:first-child) {
        @media (min-width: $break-md) {
            border-left-color: var(--color-neutral-border-subtle);
        }
    }

    &:first-child {
        font-weight: 700;
        color:  var(--color-neutral-text);
    }

    .footer--align-center & {
        max-width: none;
    }
}

.footer__footer-link {
    font-weight: 700;
    color:  var(--color-neutral-text);
}

.footer__list--two-column > .list__inner {
    columns: 2;
}

.footer__top {
    padding-top: 24px;
}
