@import "~@telia-front/sass-tokens/index.scss";

.truncate {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.truncate--dark,
.truncate--light {
    position: relative;
    text-overflow: clip;

    &.truncate--shown::before {
        content: "";
        width: 40px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.truncate--dark::before {
    background: linear-gradient(to left, $grey-m 0, rgba($grey-m, 0));
}

.truncate--light::before {
    background: linear-gradient(to left, $white 0, rgba($white, 0));
}
