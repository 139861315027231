@import "~@telia-front/sass-tokens/index.scss";

.chart-bar-horizontal__title {
    margin-bottom: 10px;
}

.chart-bar-horizontal__bar {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 0 3px 3px 0;
    transition: left 800ms cubic-bezier(.23, 1, .32, 1);
}

.chart-bar-horizontal__bar-wrapper {
    @include reset-button();

    display: block;
    position: relative;
    background: $graph-unused-color;
    border-radius: 3px;
    width: 100%;
    height: 6px;
}

.chart-bar-horizontal__value-bar {
    @include reset-button();

    position: absolute;
    top: 0;
    bottom: 0;

    &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    &:nth-last-child(2) {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }
}
