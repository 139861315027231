@use "sass:math";

@import "~@telia-front/sass-tokens/index.scss";

@import "~@core-front/modal/styles.scss";

.modal__header {
    z-index: 1;
    background-color: var(--color-neutral-bg-level-2);
    border-radius: $modal-border-radius $modal-border-radius 0 0;

    .modal--full-screen & {
        border-radius: 0;
    }

    .modal--with-sticky-header & {
        position: sticky;
        top: 0;
    }
}

.modal__header--dark {
    background-color: var(--color-brand-primary-bg-subtle);
    color: var(--color-neutral-text);
}

.modal__header,
.modal__footer {
    min-height: 80px;
    padding: 16px $modal-horizontal-spacing-xs;
    display: flex;
    align-items: center;

    @media (min-width: $break-sm) {
        padding-left: 40px;
        padding-right: 40px;
    }

    &.text-center {
        justify-content: center;
    }

    &.text-left {
        justify-content: start;
    }

    &.text-right {
        justify-content: end;
    }

    .modal--with-sidebar-right &,
    .modal--with-sidebar-left & {
        border-radius: 0;
    }
}

.modal__close {
    .modal--with-sticky-header & {
        position: sticky;
        top: 10px;
    }
}

.modal__close-wrapper {
    .modal--with-sticky-header & {
        height: 100%;
    }
}

.modal__body {
    @media screen and (max-width: ($break-sm - 1)) {
        padding: 32px $modal-horizontal-spacing-xs;
    }
}
