.header__employee {
    pointer-events: auto;
    min-height: 35px;
    background: $pink;
    color: $white;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.header__employee--test {
    background-color: $blue;
}

.header__employee--dev {
    background-color: $orange;
}

.header__employee-actions {
    display: flex;
    align-items: center;

    @include list-unstyled();
}

.header__employee-item {
    position: relative;
    font-size: 12px;
    height: 100%;
}

.header__employee-item-inner {
    display: flex;
    align-items: center;
    padding: 9px 10px;
}

.header__employee-button {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    color: $white;
    margin-left: 3px;
    padding: 6px 10px 8px;
    background: transparent;
    border: none;
    border-top: 2px solid $pink;
    height: inherit;
    outline-offset: -3px;

    &.is-current {
        position: relative;
        z-index: 4;
    }

    html[data-whatintent="mouse"] &:hover,
    &.is-current {
        color: $pink;
        background-color: $white;
    }

    .header__employee--test & {
        border-top-color: $blue;
        color: $white;

        html[data-whatintent="mouse"] &:hover,
        &.is-current {
            color: $blue;
            background-color: $white;
        }
    }

    .header__employee--dev & {
        border-top-color: $orange;
        color: $white;

        html[data-whatintent="mouse"] &:hover,
        &.is-current {
            color: $orange;
            background-color: $white;
        }
    }
}

.header__employee-item-label {
    .header__employee-button--hide-label & {
        @include visually-hidden();
    }
}

.header__employee-icon,
.header__employee-arrow-icon {
    @include reset-icon();
}

.header__employee-icon {
    margin-right: 6px;
    font-size: 24px;

    .header__employee-button--hide-label & {
        margin-right: 0;
    }
}

.header__employee-arrow-icon {
    transition: transform .3s ease-in-out;
    margin-left: 2px;
    font-size: 16px;

    .header__employee-button.is-current & {
        transform: rotate(180deg);
    }
}
