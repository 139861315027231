@use "sass:list";

@import "~@telia-front/sass-tokens/index.scss";

$colors: (
    primary-1: $color-primary-1,
    primary-2: $color-primary-2,
    primary-3: $color-primary-3,
    primary-4: $color-primary-4,
    primary-5: $color-primary-5,
    primary-6: $color-primary-6,
    neutral-1: $color-neutral-1,
    neutral-2: $color-neutral-2,
    neutral-3: $color-neutral-3,
    neutral-4: $color-neutral-4,
    neutral-5: $color-neutral-5,
    neutral-6: $color-neutral-6,
    background-1: $color-background-1,
    background-2: $color-background-2,
    background-3: $color-background-3,
    background-4: $color-background-4,
    background-5: $color-background-5,
    background-6: $color-background-6,
    background-7: $color-background-7,
    background-8: $color-background-8,
    background-9: $color-background-6,
    success-1: $color-success-1,
    success-2: $color-success-2,
    success-3: $color-success-3,
    selected-1: $color-selected-1,
    selected-2: $color-selected-2,
    selected-3: $color-selected-3,
    campaign-1: $color-campaign-1,
    campaign-2: $color-campaign-2,
    campaign-3: $color-campaign-3,
    campaign-4: $color-campaign-4,
    campaign-light-1: $color-campaign-light-1,
    campaign-light-2: $color-campaign-light-2,
    campaign-light-3: $color-campaign-light-3,
    campaign-extra-light-2: $color-campaign-extra-light-2,
    information-1: $color-information-1,
    information-2: $color-information-2,
    information-3: $color-information-3,
    focus-1: $color-focus-1,
    attention-1: $color-attention-1,
    attention-2: $color-attention-2,
    attention-3: $color-attention-3,
    recommend-1: $color-recommended-1,
    recommend-2: $color-recommended-2,
    inactive-1: $color-inactive-1,
    inactive-2: $color-inactive-2,
    error-1: $color-error-1,
    error-2: $color-error-2,
    error-3: $color-error-3,
    error-4: $color-error-4,
    employee-1: $color-employee-1,
    employee-2: $color-employee-2,
    employee-3: $color-employee-3
);

$text-colors: (
    attention-text: var(--color-attention-text),
    attention-text-onbg-strong: var(--color-attention-text-onbg-strong),
    disabled-text: var(--color-disabled-text),
    employee-text: var(--color-employee-text),
    employee-text-onbg-strong: var(--color-employee-text-onbg-strong),
    error-text: var(--color-error-text),
    error-text-onbg-strong: var(--color-error-text-onbg-strong),
    info-text: var(--color-info-text),
    info-text-onbg-strong: var(--color-info-text-onbg-strong),
    neutral-text: var(--color-neutral-text),
    neutral-text-subtle: var(--color-neutral-text-subtle),
    neutral-text-onbg-strong: var(--color-neutral-text-onbg-strong),
    success-text: var(--color-success-text),
    success-text-onbg-strong: var(--color-success-text-onbg-strong),
    brand-primary-text: var(--color-brand-primary-text),
    brand-primary-text-subtle: var(--color-brand-primary-text-subtle),
    brand-primary-text-onbg-strong: var(--color-brand-primary-text-onbg-strong),
    brand-expressive-text: var(--color-brand-expressive-text),
    brand-expressive-text-onbg-strong: var(--color-brand-expressive-text-onbg-strong),
    brand-secondary-text: var(--color-brand-secondary-text),
    campaign-1-text: var(--color-campaign-1-text),
    campaign-2-text: var(--color-campaign-2-text),
    campaign-3-text: var(--color-campaign-3-text),
    campaign-4-text: var(--color-campaign-4-text)
);

$bg-colors: (
    attention-bg-subtle: var(--color-attention-bg-subtle),
    attention-bg-strong: var(--color-attention-bg-strong),
    disabled-bg: var(--color-disabled-bg),
    employee-bg-subtlest: var(--color-employee-bg-subtlest),
    employee-bg-subtle: var(--color-employee-bg-subtle),
    employee-bg-strong: var(--color-employee-bg-strong),
    error-bg-strong: var(--color-error-bg-strong),
    info-bg-subtle: var(--color-info-bg-subtle),
    info-bg-strong: var(--color-info-bg-strong),
    neutral-bg-subtlest: var(--color-neutral-bg-subtlest),
    neutral-bg-subtle: var(--color-neutral-bg-subtle),
    neutral-bg-strong: var(--color-neutral-bg-strong),
    neutral-bg-level-1: var(--color-neutral-bg-level-1),
    neutral-bg-level-2: var(--color-neutral-bg-level-2),
    neutral-bg-level-3: var(--color-neutral-bg-level-3),
    neutral-bg-level-4: var(--color-neutral-bg-level-4),
    success-bg-subtle: var(--color-success-bg-subtle),
    success-bg-strong: var(--color-success-bg-strong),
    brand-primary-bg-subtle: var(--color-brand-primary-bg-subtle),
    brand-primary-bg-strong: var(--color-brand-primary-bg-strong),
    brand-expressive-bg-subtle: var(--color-brand-expressive-bg-subtle),
    brand-expressive-bg-strong: var(--color-brand-expressive-bg-strong),
    brand-secondary-bg-subtlest: var(--color-brand-secondary-bg-subtlest),
    brand-secondary-bg-subtle: var(--color-brand-secondary-bg-subtle),
    campaign-1-bg: var(--color-campaign-1-bg),
    campaign-2-bg: var(--color-campaign-2-bg),
    campaign-3-bg: var(--color-campaign-3-bg),
    campaign-4-bg: var(--color-campaign-4-bg)
);

@each $name, $color in $colors {
    .bg-#{$name} {
        background-color: $color;
    }

    .c-#{$name} {
        color: $color;
    }
}

@each $name, $color in $text-colors {
    .c-#{$name} {
        color: $color;
    }
}

@each $name, $color in $bg-colors {
    .bg-#{$name} {
        background-color: $color;
    }
}
