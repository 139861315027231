@import "~@core-front/sass-tokens/index.scss";

.selector {
    width: 100%;
}

.selector__list {
    font-size: $selector-font-size;
    line-height: $selector-line-height;
    font-weight: 700;
}

.selector__label {
    width: 100%;
    margin-bottom: 10px;

    .selector--hide-label & {
        @include visually-hidden();
    }

    .selector--center & {
        text-align: center;
    }

    .selector--right & {
        text-align: right;
    }

    .selector--small & {
        margin-bottom: 5px;
        font-size: 12px;
    }
}

.selector__item {
    position: relative;
    flex: 0 1 auto;

    &.selector__item--custom-color {
        border-radius: 5px;
    }
}

.selector__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
}

.selector__indicator {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $selector-padding-vertical $selector-padding-horizontal;
    cursor: pointer;
    border-radius: $selector-border-radius;
    background: $selector-background;
    border: $selector-border-width solid $selector-border-color;
    text-align: center; // for multiline labels
    transition: 100ms ease-out;
    transition-property: box-shadow, background-color, border-color, color;
    width: 100%;
    min-height: $selector-min-height;

    html[data-whatintent="mouse"] .selector__input:hover ~ & {
        border-color: $selector-border-hover-color;
        box-shadow: inset 0 0 0 1px $selector-border-hover-color;
    }

    html[data-whatintent="keyboard"] .selector__input:focus ~ & {
        @include keyboard-focus();
    }

    html[data-whatintent="mouse"] .selector__item--employee .selector__input:hover ~ &  {
        border-color: $selector-border-hover-color-employee;
        box-shadow: inset 0 0 0 1px $selector-border-hover-color-employee;
    }

    .selector__item--inactive & .selector__item-label-strikethrough,
    .selector__item--inactive & .selector__input:disabled .selector__item-label-strikethrough {
        color: $selector-inactive-text-color;
    }

    .selector__input:checked ~ & {
        color: $selector-selected-color;
        background-color: $selector-selected-background-color;
        border-color: $selector-selected-border-color;

        .selector__item--inactive & .selector__item-label-strikethrough {
            color: $selector-selected-color;
        }

        .selector__item--inactive.selector__item--employee & .selector__item-label-strikethrough {
            color: $selector-selected-color-employee;
        }

        .selector__item--employee & {
            background-color: $selector-selected-background-color-employee;
            border-color: $selector-selected-border-color-employee;
            color: $selector-selected-color-employee;
        }
    }

    html[data-whatintent="mouse"] .selector__input:checked:hover ~ & {
        color: $selector-selected-color;
        background-color: $selector-selected-background-hover-color;
        border-color: $selector-selected-border-hover-color;
        box-shadow: none;
    }

    .selector__input:disabled ~ & {
        color: $selector-disabled-color;
        background-color: $selector-disabled-background;
        cursor: default;
        border-color: $selector-disabled-border-color;
    }

    .selector__input:disabled:checked ~ & {
        background-color: $selector-disabled-selected-background;
        border-color: $selector-disabled-selected-background;
        color: $selector-disabled-selected-color;
    }

    html[data-whatintent="mouse"] .selector__input:disabled:hover ~ & {
        border-color: $selector-disabled-border-color;
        box-shadow: none;
    }

    html[data-whatintent="mouse"] .selector__input:disabled:checked:hover ~ & {
        color: $selector-disabled-color;
        background-color: $selector-disabled-selected-background;
        border-color: $selector-disabled-selected-background;
    }

    html[data-whatintent="mouse"] .selector__item--employee .selector__input:checked:enabled:hover ~ & {
        color: $selector-selected-hover-color-employee;
        background-color: $selector-selected-background-hover-color-employee;
        border-color: $selector-selected-border-hover-color-employee;
    }

    .selector--small & {
        font-size: $selector-small-font-size;
        line-height: $selector-small-line-height;
        padding: $selector-small-padding-vertical $selector-small-padding-horizontal;
        min-height: $selector-small-min-height;
    }

    .selector--large & {
        font-size: $selector-large-font-size;
        line-height: $selector-large-line-height;
        padding: $selector-large-padding-vertical $selector-large-padding-horizontal;
        min-height: $selector-large-min-height;
    }

    .selector--xlarge & {
        font-size: $selector-xlarge-font-size;
        line-height: $selector-xlarge-line-height;
        min-width: $selector-xlarge-size;
        min-height: $selector-xlarge-min-height;
        padding: $selector-xlarge-padding-vertical $selector-xlarge-padding-horizontal;
    }

    .selector__item--employee &,
    .selector__item--employee.selector__item--inactive &,
    .selector__item--employee.selector__item--inactive & .selector__item-label-strikethrough {
        color: $selector-color-employee;
    }

    .selector__item--custom-color & {
        border-radius: 50%;
        height: 36px;
        width: 36px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        min-height: auto;

        .selector--small & {
            height: 24px;
            width: 24px;
            padding: 3px;
        }
    }

    .selector__item--inactive.selector__item--custom-color &::after {
        display: block;
        content: "";
        background-color: $selector-inactive-color;
        width: 35px;
        height: 1px;
        position: absolute;
        transform: rotateY(0deg) rotate(-45deg);
        transition: 100ms ease-in-out;
        transition-property: background-color;

        .selector--small & {
            width: 23px;
        }
    }

    .selector__item--inactive.selector__item--custom-color .selector__input:disabled ~ &::after {
        background-color: $selector-disabled-border-color;
    }

    html[data-whatintent="mouse"] .selector__input:hover ~ &::after,
    .selector__item--custom-color .selector__input:checked ~ &::after,
    html[data-whatintent="mouse"] .selector__item--custom-color .selector__input:checked:hover ~ &::after {
        background-color: $selector-selected-color-border-color;
    }

    html[data-whatintent="mouse"] .selector__item--custom-color .selector__input:enabled:hover ~ &,
    html[data-whatintent="mouse"] .selector--small .selector__item--custom-color .selector__input:enabled:hover ~ & {
        border-color: $selector-selected-color-border-color;
        box-shadow: inset 0 0 0 1px $selector-selected-color-border-color;
    }

    .selector__item--custom-color .selector__input:checked ~ &,
    html[data-whatintent="mouse"] .selector__item--custom-color .selector__input:checked:hover ~ &,
    .selector__item--custom-color .selector--small .selector__input:checked ~ &,
    html[data-whatintent="mouse"] .selector--small .selector__item--custom-color .selector__input:checked:hover ~ & {
        background-color: transparent;
        border-color: $selector-selected-color-border-color;
        box-shadow: inset 0 0 0 1px $selector-selected-color-border-color;
    }

    html[data-whatintent="mouse"] & .selector__item--custom-color .selector__input:disabled:hover ~ & {
        box-shadow: none;
        border-color: $selector-border-hover-color-custom;
        background-color: transparent;
    }

    .selector__item--custom-color .selector__input:disabled ~ & {
        background-color: transparent;
    }

    .selector__item--custom-color .selector__input:disabled:checked ~ &,
    html[data-whatintent="mouse"] .selector__item--custom-color .selector__input:disabled:checked:hover ~ & {
        border: 1px solid $selector-border-hover-color-custom;
        box-shadow: inset 0 0 0 1px $selector-border-hover-color-custom;
        background-color: transparent;
    }
}

.selector__indicator-label--has-children {
    @include visually-hidden();
}

.selector__indicator-inner {
    .selector__item--custom-color & {
        display: block;
        height: 24px;
        width: 24px;
        border-radius: 50%;

        .selector--small & {
            height: 16px;
            width: 16px;
        }
    }

    .selector__item--inactive.selector__item--custom-color .selector__indicator & {
        clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 86%, 86% 0, 100% 0, 100% 15%, 15% 100%, 100% 100%, 100% 0);
    }

    .selector__item--custom-color .selector__input:checked ~ .selector__indicator & {
        box-shadow: none;
    }

    .selector__item--custom-color .selector__input:disabled ~ .selector__indicator & {
        opacity: .4;
    }
}

.selector__item-label {
    .selector__item--custom-color & {
        @include visually-hidden();
    }
}

.selector__form-helper {
    margin-top: $selector-form-helper-margin-top;

    &.form-helper--help {
        color: $selector-form-helper-help;
    }
}
