@import "~@core-front/sass-tokens/index.scss";

.chip {
    display: inline-flex;
    padding: 4px 8px;
    border-radius: 50rem;
    border: 1px solid $chip-border-color;
    font-size: 12px;
    line-height: 16px;
    white-space: nowrap;

    &.is-disabled {
        opacity: .4;
        pointer-events: none;
    }
}

.chip__remove {
    @include reset-button();
    @include transition(('animation': short, 'properties': color));

    margin-left: 5px;
    margin-right: -4px;
    color: $text-color-base;

    html[data-whatintent="mouse"] &:hover {
        color: $color-primary-1;
    }
}

.chip__remove-icon {
    vertical-align: bottom;
}
