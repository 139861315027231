@import "~@telia-front/sass-tokens/index.scss";
@import "~@core-front/option-card-old/styles.scss";

.option-card-old__content {
    .option-card-old--small & {
        padding: $option-card-old-small-vertical-padding $option-card-old-small-horizontal-padding ($option-card-old-small-vertical-padding * .4);

        &:last-child {
            padding-bottom: $option-card-old-small-vertical-padding;
        }
    }

    .option-card-old--small .option-card-old__indicator + &:nth-child(2) {
        padding-top: $option-card-old-small-vertical-padding + 20px + 20px;
    }

    .option-card-old--medium & {
        padding: $option-card-old-medium-vertical-padding $option-card-old-medium-horizontal-padding ($option-card-old-medium-vertical-padding * .4);

        &:last-child {
            padding-bottom: $option-card-old-medium-vertical-padding;
        }
    }
}

.option-card-old__footer {
    .option-card-old--small & {
        padding: ($option-card-old-small-vertical-padding * .4) $option-card-old-small-horizontal-padding $option-card-old-small-vertical-padding;
    }

    .option-card-old--medium & {
        padding: ($option-card-old-medium-vertical-padding * .4) $option-card-old-medium-horizontal-padding $option-card-old-medium-vertical-padding;
    }
}

.option-card-old__indicator {
    .option-card-old--small & {
        top: $option-card-old-small-vertical-padding;
    }

    .option-card-old--medium & {
        top: $option-card-old-medium-vertical-padding;
    }
}

.option-card-old__content--grey {
    background-color: $grey;
    padding-bottom: 36px;
}

.option-card-old__checked-badge-inner {
    &::before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPScxLjEnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZycgeG1sbnM6eGxpbms9J2h0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsnIHg9JzBweCcgeT0nMHB4JyB2aWV3Qm94PScwIDAgMTMgMTAnIHN0eWxlPSdlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEyIDEwOycgeG1sOnNwYWNlPSdwcmVzZXJ2ZSc+PGcgdHJhbnNmb3JtPSd0cmFuc2xhdGUoLTEwMiAtOSknPjxwYXRoIGZpbGw9JyM1ZTAwOTInIGQ9J00xMTUsMTljLTIuMy0zLjktNS4xLTQtMTAuMy00aC0wLjNjLTIuOSwwLTMuMi0yLTAuMy0yYzAuMiwwLDAuNCwwLDAuNiwwIGM1LjQsMCw4LjEtMC4zLDEwLjQtNFYxOXonLz48L2c+PC9zdmc+");
    }

    &::after {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPScxLjEnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZycgeG1sbnM6eGxpbms9J2h0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsnIHg9JzBweCcgeT0nMHB4JyB2aWV3Qm94PScwIDAgMTMgMTAnIHN0eWxlPSdlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDEyIDEwOycgeG1sOnNwYWNlPSdwcmVzZXJ2ZSc+PGcgdHJhbnNmb3JtPSd0cmFuc2xhdGUoLTEwMiAtOSknPjxwYXRoIGZpbGw9JyM1ZTAwOTInIGQ9J00xMDIsOWMyLjMsMy45LDUuNCw0LDEwLjcsNGMyLjksMCwyLjksMiwwLDJoLTAuM2MtNS4yLDAtOCwwLjEtMTAuMyw0Vjl6Jy8+PC9nPjwvc3ZnPg==");
    }
}

.option-card-old__tag--top {
    position: absolute;
    top: 10px;
    right: -10px;
}
