@import "~@telia-front/sass-tokens/index.scss";

.channel-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.channel-list--center {
    justify-content: center;
}

.channel-list--right {
    justify-content: flex-end;
}

.channel-list__item {
    margin: 5px;

    .channel-list--large & {
        @media only screen and (min-width: $break-sm) {
            margin: 10px;
        }
    }
}
