@use "sass:math";

@import "~@telia-front/sass-tokens/index.scss";

.banner {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: $banner-border-radius-small;
    width: 100%;
    color: inherit;

    html[data-whatintent="mouse"] &:hover {
        color: inherit;
    }

    @media (min-width: $break-lg) {
        &.banner--large {
            border-radius: $banner-border-radius;
        }
    }
}

.banner--dark {
    color: $white;

    html[data-whatintent="mouse"] &:hover {
        color: $white;
    }
}

.banner--anchor {
    font-weight: inherit;
}

.banner--full-size-image {
    .banner-item {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        flex: 1;
    }
}

.banner-item__image-column {
    overflow: hidden;
    position: relative;
    width: 100%;
    border-radius: $banner-border-radius-small $banner-border-radius-small 0 0;

    @media (min-width: $break-lg) {
        position: absolute;
        height: 100%;
        border-radius: $banner-border-radius;

        .banner--small & {
            border-radius: $banner-border-radius-small;
        }

        .banner--align-vertical & {
            overflow: hidden;
            position: relative;
            width: 100%;
            height: auto;
            border-radius: $banner-border-radius-small $banner-border-radius-small 0 0;
        }
    }

    .banner--xsmall & {
        position: absolute;
        height: 100%;
        border-radius: $banner-border-radius-xsmall;
    }

    .banner--full-size-image & {
        position: absolute;
        height: 100%;
        border-radius: $banner-border-radius-small;
    }

    &::before {
        content: "";
        display: block;
        width: 100%;
        padding-top: 50%;

        .banner--xsmall & {
            padding-top: 0;
        }

        .banner--large & {
            padding-top: 100%;
        }
    }
}

.banner-item__content-column {
    position: relative;
    display: flex;
    flex: 1 0 auto;

    &::before {
        @media (min-width: $break-lg) {
            content: "";
            display: block;
            width: 100%;
            padding-top: math.div(390, 1180) * 100%;

            .banner--xsmall & {
                padding-top: 0;
            }

            .banner--full-size-image & {
                padding-top: math.div(390, 1180) * 100%;
            }
        }
    }
}

.banner-item__image-picture {
    display: block;
    width: 100%;
    height: 100%;
}

.banner-item__image {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;

    @media (min-width: $break-lg) {
        height: 100%;
    }
}

.banner--no-radius {
    border-radius: 0;

    .banner-item__image-column {
        border-radius: 0;
    }

    @media (min-width: $break-lg) {
        &.banner--large {
            border-radius: 0;
        }
    }
}

.banner-item__inner {
    // important rule since element is inside flex parent
    max-width: 100%;
    padding: 16px;
    width: 100%;
    align-self: auto;
    display: flex;
    flex-direction: column;

    @media (min-width: $break-sm) {
        padding: $banner-padding-medium;
    }

    @media (min-width: $break-lg) {
        margin-left: -100%;
        width: 50%;
        padding: $banner-padding-medium 0 $banner-padding-medium $banner-padding-medium;
    }

    @media (max-width: $break-lg - 1) {
        .carousel-hero & {
            padding-top: 35px;
        }
    }

    .banner--large & {
        @media (min-width: $break-lg) {
            padding: $banner-padding-xlarge 0 $banner-padding-xlarge $banner-padding-xlarge;
        }
    }

    .banner--xsmall & {
        width: 66%;
        padding: $banner-padding-small 0 $banner-padding-small $banner-padding-small;

        @media (min-width: $break-lg) {
            padding: $banner-padding-large 0 $banner-padding-large $banner-padding-large;
        }
    }

    .banner--align-vertical & {
        max-width: 100%;
        padding: $banner-padding-small;
        width: 100%;
        align-self: auto;
        display: flex;
        flex-direction: column;
    }

    .banner--full-size-image & {
        max-width: 100%;
        padding: $banner-padding-small;
        width: 100%;
        align-self: auto;
        display: flex;
        flex-direction: column;
        margin-top: auto;

        @media (min-width: $break-sm) {
            padding: $banner-padding-medium;
        }

        @media (min-width: $break-lg) {
            padding: $banner-padding-large;
        }
    }
}

.banner__heading {
    margin-bottom: 8px;
    flex: 1 0 auto;

    @media (min-width: $break-sm) {
        margin-bottom: 24px;

        .banner--xsmall & {
            margin-bottom: 8px;
        }
    }

    .banner--xsmall & {
        margin-bottom: 8px;
    }
}

.banner-item__tag {
    position: absolute;
    top: $banner-padding-small;

    @media (min-width: $break-sm) {
        top: $banner-padding-medium;
    }

    @media (min-width: $break-lg) {
        top: $banner-padding-xlarge;
    }

    .banner-item__tag-left & {
        left: $banner-padding-small;

        @media (min-width: $break-sm) {
            left: $banner-padding-medium;
        }

        @media (min-width: $break-lg) {
            left: calc(50% + $banner-padding-xlarge);
        }
    }

    .banner-item__tag-right & {
        right: $banner-padding-small;

        @media (min-width: $break-sm) {
            right: $banner-padding-medium;
        }

        @media (min-width: $break-lg) {
            right: $banner-padding-xlarge;
        }
    }

    .banner--align-vertical .banner-item__tag-left & {
        top: $banner-padding-small;
        left: $banner-padding-small;

        @media (min-width: $break-sm) {
            left: $banner-padding-small;
        }

        @media (min-width: $break-lg) {
            left: $banner-padding-small;
        }
    }

    .banner--align-vertical .banner-item__tag-right & {
        top: $banner-padding-small;
        right: $banner-padding-small;

        @media (min-width: $break-sm) {
            right: $banner-padding-small;
        }

        @media (min-width: $break-lg) {
            right: $banner-padding-small;
        }
    }

    .banner--full-size-image .banner-item__tag-left & {
        top: $banner-padding-small;
        left: $banner-padding-small;

        @media (min-width: $break-sm) {
            top: $banner-padding-medium;
            left: $banner-padding-medium;
        }

        @media (min-width: $break-lg) {
            top: $banner-padding-large;
            left: $banner-padding-large;
        }
    }

    .banner--full-size-image .banner-item__tag-right & {
        top: $banner-padding-small;
        right: $banner-padding-small;

        @media (min-width: $break-sm) {
            top: $banner-padding-medium;
            right: $banner-padding-medium;
        }

        @media (min-width: $break-lg) {
            top: $banner-padding-large;
            right: $banner-padding-large;
        }
    }
}

.carousel-hero__container {
    &.carousel-hero__banner-container {
        margin-top: 0;

        .carousel-hero__pause-button-inner::before {
            @media (max-width: ($break-lg - 1)) {
                margin-top: calc(100% - 40px);
            }
        }
    }
}

.carousel-hero {
    &.banner {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;

        &.banner--no-radius {
            border-radius: 0 !important;
        }

        &::before {
            padding-top: 0 !important;
        }

        @media (min-width: $break-lg) {
            &.banner--large {
                border-radius: $banner-border-radius;
            }
        }
    }
}

.carousel-hero__background-image {
    @media (min-width: $break-lg) {
        .banner & {
            top: 0;
        }
    }
}

.carousel-hero__background {
    .banner & {
        display: none;
    }
}

.carousel-hero__content {
    .banner & {
        bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
        text-align: left;
        justify-content: unset;

        h1 {
            font-size: 32px;

            @media (min-width: $break-sm) {
                font-size: 44px;
            }

            @media (min-width: $break-xl) {
                font-size: 56px;
            }
        }
    }
}

.carousel-hero__dots {
    .banner & {
        &::before {
            @media (max-width: $break-lg - 1) {
                padding-top: 100%;
            }
        }

        .carousel-hero__indicator {
            &::after {
                @media (max-width: $break-lg - 1) {
                    top: 16px;
                    bottom: 18px;
                }
            }

            @media (max-width: $break-lg - 1) {
                padding-top: 17.5px;
            }
        }

        @media (min-width: $break-lg) {
            bottom: 4px !important;
        }
    }
}

@each $size, $padding in $banner-paddings {
    .banner-item--padding-#{$size} {
        .banner-item__inner {
            padding: $padding;

            .banner--large &,
            .banner--xsmall & {
                @media (min-width: $break-lg) {
                    padding: $padding;
                }
            }
        }

        &.banner-item__tag-left {
            .banner-item__tag {
                top: $padding;
                left: $padding;

                @media (min-width: $break-lg) {
                    left: 50%;
                }
            }
        }

        &.banner-item__tag-right {
            .banner-item__tag {
                top: $padding;
                right: $padding;
            }
        }
    }

    .banner--align-vertical,
    .banner--full-size-image {
        .banner-item--padding-#{$size} {
            &.banner-item__tag-left {
                .banner-item__tag {
                    @media (min-width: $break-lg) {
                        left: $padding;
                    }
                }
            }

        }
    }
}

@each $size, $padding in $banner-paddings {
    .banner-item--padding-top-#{$size} {
        .banner-item__inner {
            padding-top: $padding;

            .banner--large &,
            .banner--xsmall & {
                padding-top: $padding;
            }
        }

        &.banner-item__tag-left {
            .banner-item__tag {
                top: $padding;
            }

        }

        &.banner-item__tag-right {
            .banner-item__tag {
                top: $padding;
            }
        }
    }

    .banner-item--padding-right-#{$size} {
        .banner-item__inner {
            padding-right: $padding;

            .banner--large &,
            .banner--xsmall & {
                padding-right: $padding;
            }
        }

        &.banner-item__tag-right {
            .banner-item__tag {
                right: $padding;
            }
        }
    }

    .banner-item--padding-bottom-#{$size} {
        .banner-item__inner {
            padding-bottom: $padding;

            .banner--large &,
            .banner--xsmall & {
                padding-bottom: $padding;
            }
        }
    }

    .banner-item--padding-left-#{$size} {
        .banner-item__inner {
            padding-left: $padding;

            .banner--large &,
            .banner--xsmall & {
                padding-left: $padding;
            }
        }

        &.banner-item__tag-left {
            .banner-item__tag {
                left: $padding;

                @media (min-width: $break-lg) {
                    left: 50%;
                }
            }

        }
    }

    .banner--align-vertical,
    .banner--full-size-image {
        .banner-item--padding-left-#{$size} {
            &.banner-item__tag-left {
                .banner-item__tag {
                    @media (min-width: $break-lg) {
                        left: $padding;
                    }
                }
            }

        }
    }
}

@each $size, $margin in $banner-margins {
    .banner--margin-#{$size},
    .carousel-hero__banner-container.banner--margin-#{$size} {
        margin: $margin;
        width: auto;
    }
}

@each $size, $margin in $banner-margins {
    .banner--margin-top-#{$size},
    .carousel-hero__banner-container.banner--margin-top-#{$size} {
        margin-top: $margin;
    }

    .banner--margin-right-#{$size},
    .carousel-hero__banner-container.banner--margin-right-#{$size} {
        margin-right: $margin;
        width: auto;
    }

    .banner--margin-bottom-#{$size},
    .carousel-hero__banner-container.banner--margin-bottom-#{$size} {
        margin-bottom: $margin;
    }

    .banner--margin-left-#{$size},
    .carousel-hero__banner-container.banner--margin-left-#{$size} {
        margin-left: $margin;
        width: auto;
    }
}
