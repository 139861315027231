@use "sass:map";
@use "sass:math";

@import "~@telia-front/sass-tokens/index.scss";

.carousel-hero__container {
    position: relative;
    margin-top: -30px;
    overflow: hidden;
}

.carousel-hero {
    position: relative;
    overflow: hidden;
    z-index: map.get($zindex, carousel-hero);
    margin-left: auto;
    margin-right: auto;
    touch-action: pan-y;
    max-width: 2560px;

    .slick-track {
        display: flex;
        height: 100%;
    }

    .slick-slide {
        display: flex;
        position: relative;
        float: left;
        height: auto;
        opacity: 1 !important;

        &:not(.slick-current) {
            pointer-events: none;

            .carousel-hero__inner {
                display: none;
            }
        }

        > div {
            width: 100%;
        }
    }

    &::before {
        @media (min-width: $break-lg) {
            content: "";
            display: block;
            width: 100%;
            padding-top: (math.div(853, 2560) * 100%);

            @media (min-aspect-ratio: 2 / 1) {
                padding-top: (math.div(853, 2560) * 80%);
            }
        }
    }

    .slick-list {
        @media (min-width: $break-lg) {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}

.carousel-hero__item {
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 1px;
    display: none;
    color: $text-color-base;
    transition: background-color 300ms cubic-bezier(.25, .1, .25, 1);

    @media (min-width: $break-lg) {
        position: absolute;
        top: 0;
        left: 0;
    }

    .carousel-hero & {
        height: 100%;
    }

    &:first-child {
        display: block;
    }

    &.is-current {
        z-index: 1;
    }

    &.is-prev-current {
        z-index: 2;
    }

    &:not(.is-current) {
        // important to override inline style
        background-color: transparent !important;
    }

    &::before {
        @media (min-width: $break-lg) {
            content: "";
            display: block;
            width: 100%;
            padding-top: (math.div(853, 2560) * 100%);

            .carousel-hero:not(.carousel-hero--min-height) & {
                @media (min-aspect-ratio: 2 / 1) {
                    padding-top: (math.div(853, 2560) * 80%);
                }
            }
        }
    }
}

.carousel-hero__item--theme-dark {
    color: $white;
}

.carousel-hero__background {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    opacity: 1;
    transition: 300ms cubic-bezier(.25, .1, .25, 1);
    transition-property: opacity, transform;

    @media (min-width: $break-lg) {
        position: absolute;
        top: 0;
        left: 0;
    }

    .carousel-hero__item.is-current & {
        transform: translateX(0);
    }

    .carousel-hero__item.is-prev & {
        transform: translateX(-20px);
    }

    .carousel-hero__item.is-next & {
        transform: translateX(20px);
    }

    .carousel-hero__item.is-prev-current & {
        opacity: 0;
    }

    &::before {
        content: "";
        display: block;
        width: 100%;
        padding-top: 50%;

        @media (min-width: $break-lg) {
            padding-top: (math.div(853, 2560) * 100%);

            .carousel-hero:not(.carousel-hero--min-height) & {
                @media (min-aspect-ratio: 2 / 1) {
                    padding-top: (math.div(853, 2560) * 80%);
                }
            }
        }
    }
}

.carousel-hero__background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .carousel-hero:not(.carousel-hero--min-height) & {
        @media (min-width: $break-lg) {
            @media (min-aspect-ratio: 2 / 1) {
                top: -10%;
            }
        }
    }
}

.carousel-hero__video-wrapper {
    display: none;

    @media (min-width: $break-lg) {
        display: block;
    }
}

.carousel-hero__background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: $body-bg;
    border-radius: 0;

    & .video__iframe {
        border-radius: 0;
    }
}

.video__image {
    .carousel-hero__background-video & {
        height: 100%;
        border-radius: 0;
    }
}

.carousel-hero__content {
    padding-top: 30px;
    padding-bottom: 70px;
    opacity: 0;
    transition: 300ms cubic-bezier(.25, .1, .25, 1) 100ms;
    transition-property: opacity, transform;

    @media (max-width: ($break-lg - 1)) {
        text-align: center;
    }

    @media (min-width: $break-lg) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        bottom: calc((100vw - 180px) / 4 * .142);
        padding-bottom: 30px;
    }

    @media (min-width: 2560px) {
        bottom: calc((2560px - 180px) / 4 * .142);
    }

    .carousel-hero__item.is-current & {
        transform: translateX(0);
        opacity: 1;
    }

    .carousel-hero__item.is-prev & {
        transform: translateX(-20px);
    }

    .carousel-hero__item.is-next & {
        transform: translateX(20px);
    }

    // special fluid h1 style inside carousel-hero

    h1,
    .h1 {
        font-size: $h2-size;

        @media (min-width: $break-sm) {
            font-size: $h1-size-sm;
        }

        @media (min-width: $break-lg) {
            @include fluid-type($break-lg, $break-xl, 44px /* $h1-size-xl */, 56px /* $h1-size-xl */);
        }

        @media (min-width: $break-xl) {
            // TODO: handle other brand font-sizes dynamically
            @include fluid-type($break-xl, 2560px, 56px /* $h1-size-xl */, 74px);
        }

        @media (min-width: 2560px) {
            font-size: 74px;
        }

        .carousel-hero__item--theme-dark & {
            color: $white;
        }
    }
}

.carousel-hero__dots {
    position: absolute;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;

    @media (min-width: $break-lg) {
        top: auto;
        bottom: calc((100vw - 180px) / 4 * .142);
    }

    @media (min-width: $break-xl) {
        bottom: calc((#{$page-max-width} - 180px) / 4 * .142);
    }

    &::before {
        @media (max-width: ($break-lg - 1)) {
            content: "";
            display: block;
            width: 100%;
            padding-top: 50%;
        }
    }

    // these elements don't have a specific class and can't be assigned one :(

    li {
        position: relative;
        display: inline-block;
        margin: 0;
        padding: 0;
        cursor: pointer;
        vertical-align: top;
    }
}

.carousel-hero__indicator {
    display: block;
    position: relative;
    background: transparent;
    border: none;
    border-radius: 3px;
    z-index: 1;
    pointer-events: auto;
    padding: 10px 3px 20px;
    margin: 0 1.5px;
    height: 33px;
    width: 50px;

    @media (min-width: $break-lg) {
        padding: 20px 3px;
        height: 43px;
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 10px;
        left: 0;
        right: 0;
        bottom: 20px;
        background-color: rgba($grey-d, .2);

        @media (min-width: $break-lg) {
            top: 20px;
        }

        &:focus {
            @include keyboard-focus();
        }
    }
}

.carousel-hero__progress {
    opacity: 0;
    position: absolute;
    z-index: 1001;
    left: 0;
    top: auto;
    margin-top: -1.5px;
    width: 50px;
    height: 3px;
    transform-origin: left;
    background: $carousel-progress-color;
    transition: opacity 500ms ease;

    @media (min-width: $break-lg) {
        top: 50%;
    }

    .carousel-hero__dots li.slick-active & {
        opacity: 1;
        animation-name: scale-from-left;
    }
}

@keyframes scale-from-left {
    0% {
        transform: scaleX(0);
    }

    100% {
        transform: scaleX(1);
    }
}

.carousel-hero__pause-button-container {
    position: absolute;
    width: 100%;
    height: 100%;

    @media (max-width: $break-lg - 1) {
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    .carousel-hero__pause-button-inner {
        position: relative;
        height: 100%;
        width: 100%;
        max-width: 2560px;
        margin: 0 auto;

        &::before {
            @media (max-width: ($break-lg - 1)) {
                content: "";
                display: block;
                width: 100%;
                margin-top: calc(50% - 40px);
            }
        }

        .btn.carousel-hero__pause-button {
            position: absolute;
            right: 24px;
            bottom: 24px;
            z-index: 1;

            @media (max-width: ($break-lg - 1)) {
                right: 8px;
                bottom: unset;
            }
        }
    }
}
