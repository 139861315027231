@import "~@core-front/sass-tokens/index.scss";

.tooltip-toggle {
    background: transparent;
    padding: 0;
    border: 0;
    margin: 0;
    text-align: inherit;
    user-select: text;

    html[data-whatintent="mouse"] &:hover {
        color: $tooltip-toggle-color-hover;
    }

    html[data-whatintent="mouse"] &.tooltip-toggle--employee:hover {
        color: $tooltip-toggle-employee-color-hover;
    }
}

.tooltip-toggle--icon {
    position: relative;
    color: $tooltip-toggle-icon-color;
    vertical-align: middle;
    width: 24px;
    height: 1em;

    &.tooltip-toggle--employee {
        color: $tooltip-toggle-employee-icon-color;
    }

    html[data-whatintent="mouse"] &:hover {
        color: $tooltip-toggle-icon-color-hover;
    }

    html[data-whatintent="mouse"] &.tooltip-toggle--employee:hover {
        color: $tooltip-toggle-employee-icon-color-hover;
    }

    &::after {
        content: "";
        position: absolute;
        top: -5px;
        bottom: -5px;
        left: -5px;
        right: -5px;
    }
}

.tooltip-toggle__inner {
    position: relative;
    background-image: linear-gradient(to right, $tooltip-toggle-border-color 40%, transparent 20%);
    background-position: bottom;
    background-size: 3px 1px;
    background-repeat: repeat-x;
    padding-bottom: 2px;

    html[data-whatintent="mouse"] .tooltip-toggle:not(.tooltip-toggle--icon):hover & {
        background-image: linear-gradient(to right, $tooltip-toggle-border-color-hover 40%, transparent 20%);
    }

    .tooltip-toggle--employee & {
        background-image: linear-gradient(to right, $tooltip-toggle-employee-border-color 40%, transparent 20%);
    }

    html[data-whatintent="mouse"] .tooltip-toggle--employee:not(.tooltip-toggle--icon):hover & {
        background-image: linear-gradient(to right, $tooltip-toggle-employee-border-color-hover 40%, transparent 20%);
    }

    .tooltip-toggle--icon & {
        background: none;
        padding-bottom: 0;
        position: absolute;
        height: 24px;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }
}

.tooltip-toggle__text {
    .tooltip-toggle--icon & {
        @include visually-hidden();
    }
}
