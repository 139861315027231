@use "sass:map";

@import "~@telia-front/sass-tokens/index.scss";

.product-comparison-bar {
    left: 0;
    right: 0;
    z-index: map.get($zindex, product-comparison-bar);
    transition: top 500ms cubic-bezier(.86, 0, .07, 1);

    &.has-scrolled {
        position: fixed;
        top: 0;
    }
}

.product-comparison-bar__card {
    border-radius: 0;
}

.product-comparison-bar__grid {
    max-width: 1224px;
}

.product-comparison-bar__slot {
    &:not(.product-comparison-bar__buttons) {
        @media (max-width: $break-lg - 1) {
            max-width: 25%;
        }
    }

    @media (min-width: $break-lg) {
        max-width: 20%;
    }
}

.product-comparison-bar__buttons {
    align-items: center;
}

.product-comparison-bar__product-name {
    @media (max-width: $break-lg - 1) {
        display: none;
    }
}

.product-comparison-bar__close-button {
    @media (max-width: $break-lg - 1) {
        display: none;
    }
}

.product-comparison-bar__product-image-container {
    @media (max-width: $break-lg - 1) {
        display: inline-flex;
        position: relative;
        margin-top: 10px;
    }
}

.product-comparison-bar__remove-product-btn {
    position: absolute;
    top: -20px;
    right: -20px;

    @media (min-width: $break-lg) {
        display: none;
    }
}

.product-comparison-bar__background {
    display: none;

    .product-comparison-bar.has-scrolled ~ & {
        display: block;
    }
}
