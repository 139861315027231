@use "sass:map";

@import "~@telia-front/sass-tokens/index.scss";

.sidemenu {
    position: relative;
    background: $body-bg;
    z-index: map.get($zindex, sidemenu);

    @media (min-width: $break-sm) {
        width: 100%;
        background: transparent;
    }
}

.sidemenu__main {
    position: relative;
    overflow: hidden;
    height: 50px;

    @media (min-width: $break-sm) {
        height: auto;
        width: 100%;
        overflow: visible;
    }

    &::before,
    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        height: 2px;
        background: $separator-color-default;
        z-index: 4;

        @media (min-width: $break-sm) {
            display: none;
        }
    }

    &::before {
        top: 0;
    }

    &::after {
        bottom: 0;
    }
}

.sidemenu__view {
    width: 100%;
    white-space: nowrap;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media (min-width: $break-sm) {
        white-space: normal;
        overflow: visible;
    }
}

.sidemenu__nav {
    padding: 0 0 20px;

    @media (min-width: $break-sm) {
        @include list-unstyled();

        padding-bottom: 0;
    }
}

.sidemenu__nav--level-0 {
    &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        background: linear-gradient(to right, transparent 0%, $body-bg 100%);
        width: 40px;
        z-index: 3;

        @media (min-width: $break-sm) {
            display: none;
        }
    }
}

.sidemenu--inverse {
    background: var(--color-neutral-bg-level-2);

    .sidemenu__nav--level-0 {
        &::after {
            background: linear-gradient(to right, transparent 0%, var(--color-neutral-bg-level-2) 100%);
        }
    }

    @media (min-width: $break-sm) {
        background: transparent;
    }
}

.sidemenu__nav--level-1 {
    display: none;

    @media (min-width: $break-sm) {
        margin-top: 13px;
        padding-left: 20px;
    }

    .sidemenu__item.is-current > & {
        @media (min-width: $break-sm) {
            display: block;
        }
    }

    .sidemenu__sub &.is-open {
        display: block;
    }
}

.sidemenu__item {
    position: relative;
    display: inline-block;

    @media (min-width: $break-sm) {
        display: block;
        margin-bottom: 13px;
    }

    &:last-child {
        @media (min-width: $break-sm) {
            margin-bottom: 0;
        }
    }

    .sidemenu__nav--level-1 > & {
        display: block;
        border-bottom: 1px solid $separator-color-default;

        @media (min-width: $break-sm) {
            border-bottom: 0;
        }
    }
}

.sidemenu__link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $text-color-base;
    font-weight: 700;
    padding: 14px 8px;

    @media (max-width: $break-sm - 1) {
        outline-offset: -4px;
    }

    @media (min-width: $break-sm) {
        padding: 0;
    }

    .sidemenu__nav--level-1 > .sidemenu__item > & {
        padding: 19px 20px;

        @media (min-width: $break-sm) {
            padding: 0;
        }
    }

    .sidemenu__item.is-current > & {
        color: $tabs-selected-text-color;

        @media (min-width: $break-sm) {
            color: $anchor-hover-color;
        }
    }

    .sidemenu__item:last-child & {
        @media (min-width: $break-sm) {
            padding-bottom: 0;
        }
    }

    .sidemenu__nav--level-0 > .sidemenu__item.is-current > &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background: $tabs-selected-border-color;
        z-index: 5;

        @media (min-width: $break-sm) {
            display: none;
        }
    }

    html[data-whatintent="mouse"] .sidemenu--employee & {
        &:hover {
            color: var(--color-employee-text-hover);
        }
    }

    .sidemenu--employee .sidemenu__item.is-current > & {
        color: var(--color-employee-text);

        &::after {
            background-color: var(--color-employee-text);
        }
    }

    .sidemenu__item--employee & {
        color: var(--color-employee-text);
    }

    html[data-whatintent="mouse"] .sidemenu__item--employee & {
        &:hover {
            color: var(--color-employee-text-hover);
        }
    }

    .sidemenu__item--employee.is-current > & {
        color: var(--color-employee-text-hover);

        &::after {
            background-color: var(--color-employee-text-hover);
        }
    }
}

.sidemenu__icon {
    @include reset-icon();

    display: none;
    font-size: 24px;
    flex-shrink: 0;
    margin-top: -4px;
    margin-bottom: -4px;

    @media (min-width: $break-sm) {
        display: block;
        opacity: 0;
    }

    .sidemenu__item--has-children > .sidemenu__link &,
    .sidemenu__item.is-current > .sidemenu__link &,
    html[data-whatintent="mouse"] .sidemenu__link:hover & {
        @media (min-width: $break-sm) {
            opacity: 1;
        }
    }

    .sidemenu__item--has-children > .sidemenu__link & {
        transition: transform 300ms $transition-timing-function;
        transform: rotate(0deg);
        display: block;
    }

    .sidemenu__item--has-children.is-current > .sidemenu__link & {
        transform: rotate(180deg);
    }

    .sidemenu__nav--level-0 > .sidemenu__item > .sidemenu__link & {
        margin-left: 4px;

        @media (min-width: $break-sm) {
            margin-left: 0;
        }
    }

    .sidemenu__nav--level-1 > .sidemenu__item.is-current > .sidemenu__link & {
        display: block;
    }
}

.sidemenu__divider {
    display: inline-block;
    position: relative;
    margin: 0 8px;
    width: 1px;
    height: 20px;
    vertical-align: middle;

    @media (min-width: $break-sm) {
        display: block;
        margin: 15px 0;
        width: auto;
        height: auto;
        border-top: 1px solid $separator-color-default;
    }

    &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        background: $separator-color-default;
        content: "";

        @media (min-width: $break-sm) {
            content: none;
        }
    }
}
