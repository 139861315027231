@import "~@core-front/sass-tokens/index.scss";

.btn {
    @include transition(('animation': short, 'properties': (background-color, border-color, color)));

    position: relative;
    display: inline-block;
    vertical-align: middle;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    min-width: $btn-min-width;
    max-width: 100%;
    font-weight: 700;
    text-transform: $btn-text-transform;
    text-align: center;
    border-style: solid;
    border-width: $btn-border-width;
    padding: $btn-min-vertical-padding $btn-min-horizontal-padding;
    border-radius: $btn-border-radius;
    letter-spacing: $btn-letter-spacing;
    cursor: pointer;

    @media only screen and (min-width: $break-sm) {
        min-width: $btn-min-width-sm;
    }

    @include button-variant($btn-primary-color, $btn-primary-background, $btn-primary-background-hover-color, $btn-primary-border-color, $btn-primary-border-hover-color, $btn-primary-hover-color, null, $btn-primary-pressed-color, $btn-primary-pressed-background);

    &:not(.btn--link) {
        font-size: $btn-font-size;
        line-height: $btn-line-height;
        min-height: $btn-min-height;
    }

    &.is-loading {
        cursor: inherit;
    }

    &.skeleton__item {
        border: none;
        width: auto;
        cursor: inherit;
    }
}

.btn--small {
    min-width: 0;
    padding: $btn-small-padding $btn-small-min-horizontal-padding;
    border-radius: $btn-small-border-radius;

    &:not(.btn--link) {
        font-size: $btn-small-font-size;
        line-height: $btn-small-line-height;
        min-height: $btn-small-min-height;
    }

    &.btn--link {
        font-size: $font-size-small;
    }
}

/**
 * Button variants
 */

.btn--variant-secondary {
    @include button-variant($btn-secondary-color, $btn-secondary-background, $btn-secondary-background-hover, $btn-secondary-border, $btn-secondary-border-hover, $btn-secondary-color-hover, $btn-secondary-box-shadow-hover, $btn-secondary-pressed-color, $btn-secondary-pressed-background);

    &.btn--icon-only {
        @include button-hover-variant($btn-secondary-color, $btn-secondary-background, $btn-secondary-icon-only-background-hover, $btn-secondary-icon-only-border-hover, $btn-secondary-color-hover, $btn-secondary-icon-only-border, $btn-secondary-pressed-color, $btn-secondary-pressed-background);

        &.btn--small {
            @include button-hover-variant($btn-secondary-color, $btn-secondary-background, $btn-secondary-icon-only-background-hover, $btn-secondary-icon-only-border-hover, $btn-secondary-color-hover, $btn-secondary-icon-only-border, $btn-secondary-pressed-color, $btn-secondary-pressed-background);
        }
    }
}

/**
 * Button types.
 */

.btn--text-color {
    @include button-variant($btn-text-color, $btn-text-color-background-color, $btn-text-color-background-hover-color, $btn-text-color-background-color, $btn-text-color-border-hover-color, $btn-text-color, null, $btn-text-color, $btn-text-color-background-pressed-color);
}

.btn--withdrawal {
    @include button-variant($btn-withdrawal-color, $btn-withdrawal-background, $btn-withdrawal-background-hover, $btn-withdrawal-border-color, $btn-withdrawal-border-hover-color, $btn-withdrawal-color, null, $btn-withdrawal-color, $btn-withdrawal-pressed-background);

    &.btn--variant-secondary {
        @include button-variant($btn-withdrawal-secondary-color, $btn-withdrawal-secondary-background, $btn-withdrawal-secondary-background-hover, $btn-withdrawal-secondary-border, $btn-withdrawal-secondary-border-hover, $btn-withdrawal-color, $btn-secondary-box-shadow-hover, $btn-withdrawal-color, $btn-withdrawal-secondary-pressed-background);

        &.btn--icon-only {
            @include button-hover-variant($btn-withdrawal-secondary-color, $btn-withdrawal-secondary-background, $btn-withdrawal-secondary-background-hover, $btn-withdrawal-secondary-border-hover, $btn-withdrawal-color, $btn-withdrawal-secondary-border, $btn-withdrawal-color, $btn-withdrawal-secondary-pressed-background);

            &.btn--small {
                @include button-hover-variant($btn-withdrawal-secondary-color, $btn-withdrawal-secondary-background, $btn-withdrawal-secondary-background-hover, $btn-withdrawal-secondary-border-hover, $btn-withdrawal-color, $btn-withdrawal-secondary-border, $btn-withdrawal-color, $btn-withdrawal-secondary-pressed-background);
            }
        }
    }
}

.btn--employee {
    @include button-variant($btn-employee-color, $btn-employee-background, $btn-employee-background-hover, $btn-employee-border-color, $btn-employee-border-hover-color, $btn-employee-hover-color, null, $btn-employee-pressed-color, $btn-employee-pressed-background);

    &.btn--variant-secondary {
        @include button-variant($btn-employee-secondary-color, $btn-employee-secondary-background, $btn-employee-secondary-background-hover, $btn-employee-secondary-border, $btn-employee-secondary-border-hover, $btn-employee-secondary-color-hover, $btn-secondary-box-shadow-hover, $btn-employee-secondary-pressed-color, $btn-employee-secondary-pressed-background);

        &.btn--icon-only {
            @include button-hover-variant($btn-employee-secondary-color, $btn-employee-secondary-background, $btn-employee-secondary-background-hover, $btn-employee-secondary-border-hover, $btn-employee-secondary-color-hover, $btn-employee-secondary-border, $btn-employee-secondary-pressed-color, $btn-employee-secondary-pressed-background);

            &.btn--small {
                @include button-hover-variant($btn-employee-secondary-color, $btn-employee-secondary-background, $btn-employee-secondary-background-hover, $btn-employee-secondary-border-hover, $btn-employee-secondary-color-hover, $btn-employee-secondary-border, $btn-employee-secondary-pressed-color, $btn-employee-secondary-pressed-background);
            }
        }
    }
}

.btn--expressive {
    @include button-variant($btn-expressive-color, $btn-expressive-background, $btn-expressive-background-hover, $btn-expressive-border-color, $btn-expressive-border-hover-color, null, null, $btn-expressive-pressed-color, $btn-expressive-pressed-background);
}

.btn--link {
    background: transparent;
    border: 0;
    text-transform: none;
    text-align: inherit;
    height: auto;
    padding: 0;
    min-width: 0;
    border-radius: 0;
    letter-spacing: $btn-link-letter-spacing;
    vertical-align: bottom;
    text-decoration: $btn-link-text-decoration;

    @include button-link-variant($btn-primary-link-color, $btn-primary-link-hover-color, $btn-primary-link-pressed-color);

    &.btn--text-color {
        @include button-link-variant($btn-text-color-link-color, $btn-text-color-link-hover-color);
    }

    &.btn--withdrawal {
        @include button-link-variant($btn-withdrawal-link-color, $btn-withdrawal-link-hover-color, $btn-withdrawal-link-pressed-color);
    }

    &.btn--employee {
        @include button-link-variant($btn-employee-link-color, $btn-employee-link-hover-color, $btn-employee-link-pressed-color);
    }

    &.btn--underlined {
        text-decoration: underline;
    }

    &.btn--expressive {
        @include button-link-variant($btn-expressive-link-color, $btn-expressive-link-hover-color, $btn-expressive-link-pressed-color);
    }
}

.btn--large.btn--link {
    font-size: 16px;
}

.btn__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .btn:not(.btn--link) &,
    a.btn:not(.btn--link).btn--icon-only &,
    span.btn:not(.btn--link).btn--icon-only & {
        min-height: $btn-inner-min-height;
    }

    a.btn:not(.btn--link) &,
    span.btn:not(.btn--link) & {
        min-height: $btn-min-height - ($btn-border-width * 2) - ($btn-min-vertical-padding * 2);
    }

    .btn.btn--small:not(.btn--link) &,
    .btn.btn--small:not(.btn--link).btn--icon-only & {
        min-height: $btn-small-inner-min-height;
    }

    .btn.is-loading & {
        opacity: 0;
    }

    .btn--link.is-loading & {
        margin-right: 25px;
        opacity: 1;
    }

    .btn--link.btn--block & {
        justify-content: flex-start;
    }

    .btn--icon-only.btn--link & {
        &::after {
            // inject zero-width space when button has only icon visible,
            // otherwise icon-only link buttons do not follow minimum height
            // set by line-height on the component
            content: "\200B";
        }
    }

    // Loading toggle animation
    .btn__spinner.fade-exit ~ &,
    .btn__spinner.fade-enter ~ & {
        @include transition(('animation': medium, 'properties': (opacity, transform)));
    }

    .btn__spinner.fade-exit-active ~ & {
        opacity: 1;
        transform: translateY(0);
    }

    .btn__spinner.fade-enter-done ~ &,
    .btn__spinner.fade-enter-active ~ & {
        opacity: 0;
        transform: translateY(-10px);
    }

    // Reset animation for link and icon-only variants
    .btn--link .btn__spinner.fade-enter-done ~ &,
    .btn--link .btn__spinner.fade-enter-active ~ &,
    .btn--icon-only .btn__spinner.fade-enter-done ~ &,
    .btn--icon-only .btn__spinner.fade-enter-active ~ & {
        opacity: 1;
        transform: translateY(0);
    }

    .btn__spinner ~ & {
        opacity: 0;
    }
}

.btn__text {
    display: inline-flex;

    .btn--icon-only & {
        @include visually-hidden();
    }
}

.btn--block {
    display: block;
    width: 100%;
}

.btn--icon-only {
    padding: 7px 0;
    min-width: $btn-icon-min-width;
    height: ($btn-padding * 2) + ($btn-border-width * 2) + 16px;

    &.btn--small {
        min-width: $btn-small-min-height;
        height: $btn-small-min-height;
        padding: $btn-icon-only-small-padding 0;
    }

    &.btn--link {
        min-width: 0;
        padding: 0;
        height: auto;
    }
}

.btn--vertical-align-baseline {
    vertical-align: baseline;
}

.btn--vertical-align-top {
    vertical-align: top;
}

.btn--vertical-align-middle {
    vertical-align: middle;
}

.btn--vertical-align-bottom {
    vertical-align: bottom;
}

/**
 * Icons
 */

.btn__icon {
    @include reset-icon();

    position: absolute;
    left: 0;
    top: 50%;
    transform: $btn-icon-translate;

    .btn:not(.btn--link) &:not(.btn__icon--custom-size) {
        font-size: 24px;
    }

    .btn:not(.btn--link).btn--small & {
        font-size: 16px;
    }

    .btn--link.is-loading & {
        opacity: 0;
    }

    .btn--link & {
        &:not(.btn__icon--custom-size) {
            font-size: 24px;
        }
    }

    .btn--large.btn--link & {
        &:not(.btn__icon--custom-size) {
            font-size: $btn-icon-large-size;
        }
    }

    .btn--small.btn--link & {
        &:not(.btn__icon--custom-size) {
            font-size: 16px;
        }
    }
}

.btn__icon--right {
    .btn--with-arrow-right-icon & {
        @include transition(('animation': medium, 'properties': transform));

        transform: translateX(0) $btn-icon-translate;
    }

    html[data-whatintent="mouse"] .card--link:not(.click-area):hover .btn--with-arrow-right-icon &,
    html[data-whatintent="mouse"] .click-area:hover .btn--with-arrow-right-icon.click-area__trigger &,
    html[data-whatintent="mouse"] .btn--with-arrow-right-icon:hover:not([aria-disabled="true"]) & {
        transform: translateX($btn-icon-transition-length) $btn-icon-translate;
    }

    html[data-whatintent="mouse"] .card--link:not(.click-area):hover .btn--small.btn--with-arrow-right-icon &,
    html[data-whatintent="mouse"] .click-area:hover .btn--small.btn--with-arrow-right-icon.click-area__trigger &,
    html[data-whatintent="mouse"] .btn--small.btn--with-arrow-right-icon:hover:not([aria-disabled="true"]) & {
        transform: translateX($btn-small-icon-transition-length) $btn-icon-translate;
    }
}

.btn__icon-wrapper {
    flex-shrink: 0;
    height: 1em;
    width: 24px;
    position: relative;

    .btn--small & {
        width: 16px;
    }

    .is-loading & {
        width: 0;
    }
}

.btn__icon-wrapper--xxs {
    width: $icon-font-size-xxs;
}

.btn__icon-wrapper--xs {
    width: $icon-font-size-xs;
}

.btn__icon-wrapper--sm {
    width: $icon-font-size-sm;
}

.btn__icon-wrapper--md {
    width: $icon-font-size-md;
}

.btn__icon-wrapper--lg {
    width: $icon-font-size-lg;
}

.btn__icon-wrapper--xl {
    width: $icon-font-size-xl;
}

.btn__icon-wrapper--xxl {
    width: $icon-font-size-xxl;
}

.btn__icon-wrapper--xxxl {
    width: $icon-font-size-xxxl;
}

.btn__icon-wrapper--left {
    margin-left: $btn-icon-wrapper-margin-left;
    margin-right: $btn-icon-inner-padding;

    .btn--small & {
        margin-right: $btn-small-icon-spacing;
    }

    .btn--link & {
        margin-left: 0;
        margin-right: 3px;
    }

    .btn--link.is-loading & {
        margin: 0;
    }
}

.btn__icon-wrapper--right {
    margin-right: $btn-icon-wrapper-margin-right;
    margin-left: $btn-icon-inner-padding;

    .btn--small & {
        margin-left: $btn-small-icon-spacing;
        margin-right: $btn-small-icon-padding - $btn-small-min-horizontal-padding;
    }

    .btn--link & {
        margin-left: 3px;
        margin-right: 0;
    }

    .btn--link.btn--block & {
        margin-left: auto;
    }

    .btn--with-arrow-right-icon & {
        margin-left: 0;
        margin-right: $btn-icon-outer-padding;
    }
}

/**
 * Spinner
 */

.btn__spinner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .spinner__circle {
        width: 24px;
        height: 24px;

        .btn--small & {
            width: 13px;
            height: 13px;
        }

        .btn--link & {
            width: 20px;
            height: 20px;
        }
    }

    .btn--link & {
        width: auto;
        margin-left: 10px;
        right: 0;
        left: auto;
    }

    .spinner__path {
        stroke: $btn-spinner-color;
    }

    // Loading toggle animation
    &.fade-exit,
    &.fade-enter {
        @include transition(('animation': medium, 'properties': (opacity, transform)));

        .btn--link &,
        .btn--icon-only & {
            transition: none;
        }
    }

    &.fade-enter,
    &.fade-exit-active {
        opacity: 0;
        transform: translateY(10px);

        .btn--link &,
        .btn--icon-only & {
            opacity: 0;
            transform: none;
        }
    }

    &.fade-enter-done,
    &.fade-enter-active {
        opacity: 1;
        transform: translateY(0);

        .btn--link &,
        .btn--icon-only & {
            opacity: 1;
            transform: none;
        }
    }
}

/*
 * Third party buttons
 */

.btn--outer {
    &-swed {
        @include button-variant($btn-outer-swed-color, $btn-outer-swed-background, $btn-outer-swed-background-hover, $btn-outer-swed-border, $btn-outer-swed-border-hover, $btn-outer-swed-hover-color, null, $btn-outer-swed-pressed-color, $btn-outer-swed-pressed-background);
    }

    &-seb {
        @include button-variant($btn-outer-seb-color, $btn-outer-seb-background, $btn-outer-seb-background-hover, $btn-outer-seb-border, $btn-outer-seb-border-hover, $btn-outer-seb-hover-color, null, $btn-outer-seb-pressed-color, $btn-outer-seb-pressed-background);
    }

    &-luminor {
        @include button-variant($btn-outer-luminor-color, $btn-outer-luminor-background, $btn-outer-luminor-background-hover, $btn-outer-luminor-border, $btn-outer-luminor-border-hover, $btn-outer-luminor-hover-color, null, $btn-outer-luminor-pressed-color, $btn-outer-luminor-pressed-background);
    }

    &-danske {
        @include button-variant($btn-outer-danske-color, $btn-outer-danske-background, $btn-outer-danske-background-hover, $btn-outer-danske-border, $btn-outer-danske-border-hover, $btn-outer-danske-hover-color, null, $btn-outer-danske-pressed-color, $btn-outer-danske-pressed-background);
    }

    &-coop {
        @include button-variant($btn-outer-coop-color, $btn-outer-coop-background, $btn-outer-coop-background-hover, $btn-outer-coop-border, $btn-outer-coop-border-hover, $btn-outer-coop-hover-color, null, $btn-outer-coop-pressed-color, $btn-outer-coop-pressed-background);
    }

    &-lhv {
        @include button-variant($btn-outer-lhv-color, $btn-outer-lhv-background, $btn-outer-lhv-background-hover, $btn-outer-lhv-border, $btn-outer-lhv-border-hover, $btn-outer-lhv-hover-color, null, $btn-outer-lhv-pressed-color, $btn-outer-lhv-pressed-background);
    }

    &-google {
        @include button-variant($btn-outer-google-color, $btn-outer-google-background, $btn-outer-google-background-hover, $btn-outer-google-border, $btn-outer-google-border-hover, $btn-outer-google-hover-color, null, $btn-outer-google-pressed-color, $btn-outer-google-pressed-background);
    }

    &-facebook {
        @include button-variant($btn-outer-facebook-color, $btn-outer-facebook-background, $btn-outer-facebook-background-hover, $btn-outer-facebook-border, $btn-outer-facebook-border-hover, $btn-outer-facebook-hover-color, null, $btn-outer-facebook-pressed-color, $btn-outer-facebook-pressed-background);
    }

    &-microsoft {
        @include button-variant($btn-outer-microsoft-color, $btn-outer-microsoft-background, $btn-outer-microsoft-background-hover, $btn-outer-microsoft-border, $btn-outer-microsoft-border-hover, $btn-outer-microsoft-hover-color, null, $btn-outer-microsoft-pressed-color, $btn-outer-microsoft-pressed-background);
    }
}
