@use "sass:math";

@import "~@telia-front/sass-tokens/index.scss";

// Table
$table-tr-border-color: $grey-d;
$table-tr-border-color-dark: $grey;
$table-tr-hover-color: $grey-m;
$table-tr-highlight-bg: rgba($blue, .1);
$table-tr-employee-bg: rgba($pink, .1);
$table-td-padding: 12px;
$table-td-padding-xsmall: 4px;
$table-td-padding-small: 8px;
$table-td-padding-large: 16px;
$table-sticky-gradient-height: 21px;

.table {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    empty-cells: show;

    table {
        width: 100%;
        border-collapse: collapse;
        border: 0;
        padding: 0;
        position: relative;

        tbody {
            &:not(.table-collapse__body)::after {
                content: "";
                display: block;
                height: 2em;
            }

            &:last-child::after {
                display: none;
            }
        }

        .table__row--employee {
            background: $table-tr-employee-bg;
        }

        tr[data-footer="true"],
        .table__row--footer {
            border: 0;
            background: $table-tr-highlight-bg;

            + tr {
                border-top: 0;
            }
        }

        th,
        td {
            padding: $table-td-padding;
            text-align: left;

            &.table__cell--padding-none {
                padding: 0;
            }

            &.table__cell--padding-xsmall {
                padding: $table-td-padding-xsmall;
            }

            &.table__cell--padding-small {
                padding: $table-td-padding-small;
            }

            &.table__cell--padding-large {
                padding: $table-td-padding-large;
            }

            &.table__cell--valign-top {
                vertical-align: top;
            }

            &.table__cell--valign-bottom {
                vertical-align: bottom;
            }

            &.table__cell--valign-baseline {
                vertical-align: baseline;
            }

            &.table__cell--valign-middle {
                vertical-align: middle;
            }
        }

        th {
            vertical-align: top;
        }

        thead tr {
            border-bottom: 1px solid $table-tr-border-color;
        }

        tbody tr {
            border-top: 1px solid $table-tr-border-color-dark;

            &:first-child {
                border-top: 0;
            }
        }
    }
}

/* hover */

.table--hover {
    tbody > tr:hover {
        background: $table-tr-hover-color;
    }

    .table-collapse__content:hover {
        background: none;
    }
}

/* bordered */

.table--bordered {
    table td,
    table th {
        border-width: 1px;
        border-style: solid dotted;
        border-color: $table-tr-border-color-dark;
    }
}

.table__row--left-bordered-purple {
    box-shadow: inset 2px 0 0 0 $purple;
}

.table__row--left-bordered-grey {
    box-shadow: inset 2px 0 0 0 $grey;
}

.table__row--left-bordered-dark-grey {
    box-shadow: inset 2px 0 0 0 $grey-d;
}

.table__original {
    td,
    th {
        &.text-left {
            text-align: left;
        }

        &.text-right {
            text-align: right;
        }

        &.text-center {
            text-align: center;
        }

        @for $i from 1 through $grid-columns {
            &.table__cell--#{$i} {
                width: math.div(100%, $grid-columns) * $i;
            }
        }

        /* Indent level */
        @for $i from 0 through 6 {
            &.table__cell--indent-level-#{$i} {
                padding-left: $table-td-padding + (15px * $i);

                &.table__cell--padding-none {
                    padding-left: 15px * $i;

                    @media (min-width: $break-md) {
                        padding-left: 20px * $i;
                    }
                }

                &.table__cell--padding-xsmall {
                    padding-left: $table-td-padding-xsmall + (15px * $i);

                    @media (min-width: $break-md) {
                        padding-left: $table-td-padding-xsmall + (20px * $i);
                    }
                }

                &.table__cell--padding-small {
                    padding-left: $table-td-padding-small + (15px * $i);

                    @media (min-width: $break-md) {
                        padding-left: $table-td-padding-small + (20px * $i);
                    }
                }

                &.table__cell--padding-large {
                    padding-left: $table-td-padding-large + (15px * $i);

                    @media (min-width: $break-md) {
                        padding-left: $table-td-padding-large + (20px * $i);
                    }
                }

                @media (min-width: $break-md) {
                    padding-left: $table-td-padding + (20px * $i);
                }
            }
        }
    }
}

/* sticky header */

@supports (position: sticky) {
    html:not(.no-js) .table--sticky-header-primary-3,
    html:not(.no-js) .table--sticky-header-neutral-1,
    html:not(.no-js) .table--sticky-header-primary-4 {
        & > .table__original > table > thead > tr > th {
            background: $color-primary-3;
            position: sticky;
            top: 0;
            z-index: 2;
        }
    }

    html:not(.no-js) .table--sticky-header-primary-4 > .table__original > table > thead > tr > th {
        background: $color-primary-4;
    }

    html:not(.no-js) .table--sticky-header-neutral-1 > .table__original > table > thead > tr > th {
        background: $color-neutral-1;
    }

    .table--sticky-header-primary-3.is-sticky,
    .table--sticky-header-neutral-1.is-sticky,
    .table--sticky-header-primary-4.is-sticky {
        & > .table__original > table > thead > tr > th::after {
            content: "";
            position: absolute;
            width: 100%;
            height: $table-sticky-gradient-height;
            bottom: -$table-sticky-gradient-height;
            left: 0;
            background: linear-gradient($table-tr-border-color 0%, $table-tr-border-color 5%, rgba(0, 0, 0, .1) 6%, rgba(0, 0, 0, 0) 100%);
        }
    }
}
