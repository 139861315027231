@import "~@telia-front/sass-tokens/index.scss";

.service-cards__outer {
    .service-cards__item-col {
        margin-bottom: 32px;
        justify-content: center;
        padding-left: 0;
        padding-right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    & > .grid__col {
        padding-left: 0;
        padding-right: 0;
    }
}

.service-cards {
    position: relative;
    box-shadow: none;

    .service-cards__item-col-filler {
        @extend .service-cards__item-col;

        display: none;
        width: 152px;
        box-sizing: initial;
    }

    .service-cards__item-col-filler--show {
        display: flex;
    }
}

.service-cards__item {
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    padding-right: 12px;
    padding-left: 12px;
}

.service-cards__item,
.service-cards__item-inner {
    display: flex;
    flex-direction: column;
}

.service-cards__item-inner {
    width: 128px;
}

.service-cards__icon {
    margin-top: 0;
    width: 32px;
    height: 32px;
}
