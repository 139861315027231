@use "sass:math";

@import "~@core-front/sass-tokens/index.scss";

$card-border-radiuses: (
    none 0,
    small $card-border-radius-small,
    medium $card-border-radius-medium,
    large $card-border-radius-large,
);

@mixin card-border-radius() {
    @each $size, $border-radius in $card-border-radiuses {
        .card.card--border-radius-#{$size}.card--badge > &:nth-child(2),
        .card.card--border-radius-top-#{$size}.card--badge > &:nth-child(2),
        .card.card--border-radius-#{$size}.card--is-closable > &:nth-child(2),
        .card.card--border-radius-top-#{$size}.card--is-closable > &:nth-child(2),
        .card.card--border-radius-#{$size} > &:first-child,
        .card.card--border-radius-top-#{$size} > &:first-child {
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
        }

        .card.card--border-radius-#{$size} > &:last-child,
        .card.card--border-radius-bottom-#{$size} > &:last-child {
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }

    .card--border > &:first-child,
    .card--attention > &:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .card--border > &:last-child,
    .card--attention > &:last-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.card {
    @include transition(('animation': medium, 'properties': transform));

    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 0;
    transform: translateY(0);

    .card__content-tag {
        @include transition(('animation': medium, 'properties': (background-color, color)));
    }

    &.is-active {
        @include transition(('animation': medium, 'properties': (transform, background-color)));

        transform: translateY(-8px);
    }

    &:not(.is-active) {
        @include transition(('animation': medium, 'properties': (transform, background-color)));
    }
}

.card--disabled {
    pointer-events: none;

    &:not(.card--full-opacity) {
        opacity: $card-disabled-opacity;
    }
}

.card--selectable {
    @include transition(('animation': short, 'properties': border-color));

    cursor: pointer;

    &::before {
        @include transition(('animation': short, 'properties': (box-shadow, top, left, right, bottom)));

        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        pointer-events: none;
        // Using box-shadow instead of border, because border cannot animate smoothly from 1px to 2px width
        box-shadow: 0 0 0 $card-border-width $card-selectable-border-color;
        border-radius: inherit;
    }

    &.card--type-employee {
        color: $card-selectable-employee-color;
    }

    &.card--disabled::before {
        box-shadow: 0 0 0 $card-border-width $card-selectable-disabled-border-color;
    }

    html[data-whatintent="mouse"] &:not(.is-selected):hover {
        @include transition(('animation': short, 'properties': border-color));

        &::before {
            box-shadow: 0 0 0 $card-border-width $card-selectable-hover-border-color;
        }

        &.card--type-employee::before {
            box-shadow: 0 0 0 $card-border-width $card-selectable-employee-hover-border-color;
        }
    }

    &.is-selected {
        &::before {
            @include transition(('animation': short, 'properties': (box-shadow, top, left, right, bottom)));

            box-shadow: 0 0 0 $card-border-selected-width $card-selected-border-color;
        }

        &.card--type-employee::before {
            box-shadow: 0 0 0 $card-border-selected-width $card-selected-border-employee-color;
        }

        &.card--disabled::before {
            box-shadow: 0 0 0 $card-border-selected-width $card-selectable-disabled-border-color;
        }
    }

    &.is-active {
        @include transition(
            ('animation': short, 'properties': border-color),
            ('animation': medium, 'properties': (transform, background-color))
        );
    }

    &:not(.is-active) {
        @include transition(
            ('animation': short, 'properties': border-color),
            ('animation': medium, 'properties': (transform, background-color))
        );
    }
}

.card--type-success {
    border: $card-border-width solid $card-type-success-border-color;
}

.card--type-error {
    border: $card-border-width solid $card-type-error-border-color;
}

.card--type-employee {
    border: $card-border-width solid $card-type-employee-border-color;

    &.card--selectable {
        border: 0;
    }
}

.card--elevation-1 {
    box-shadow: $card-elevation-1;
}

.card--elevation-2 {
    box-shadow: $card-elevation-2;
}

.card--elevation-3 {
    box-shadow: $card-elevation-3;
}

.card--elevation-4 {
    box-shadow: $card-elevation-4;
}

.card--elevation-5 {
    box-shadow: $card-elevation-5;
}

@each $size, $border-radius in $card-border-radiuses {
    .card--border-radius-#{$size} {
        border-radius: $border-radius;
    }

    .card--border-radius-top-#{$size} {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
    }

    .card--border-radius-right-#{$size} {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }

    .card--border-radius-bottom-#{$size} {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }

    .card--border-radius-left-#{$size} {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
    }
}

.card__top {
    flex: 1 0 auto;
}

.card__bottom {
    margin-top: 30px;
}

.card__content {
    @include card-border-radius();

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 100%;

    .card--background-video & {
        position: relative;
    }

    .card__content-tag {
        position: absolute;
        top: $card-content-tag-top-margin;
    }

    &.card__content--with-tag {
        padding-top: 0;
    }

    .card--with-background-image & {
        z-index: 1;
    }
}

.card__content-tag-wrapper {
    display: flex;
    height: $card-content-tag-top-margin + $tag-tiny-height + $card-content-tag-bottom-margin;
    flex-direction: column;

    .option-card & {
        height: $option-card-indicator-top-padding + $option-card-indicator-size + 16px;
    }
}

.card__content-tag-inner {
    @include transition(('animation': medium, 'properties': opacity));

    position: relative;
    opacity: 1;

    &.is-hidden {
        opacity: 0;
    }
}

@each $size, $padding in $card-paddings {
    .card__content--padding-#{$size} {
        padding: $padding;
    }
}

@each $size, $padding in $card-paddings {
    .card__content--padding-top-#{$size} {
        padding-top: $padding;
    }

    .card__content--padding-right-#{$size} {
        padding-right: $padding;
    }

    .card__content--padding-bottom-#{$size} {
        padding-bottom: $padding;
    }

    .card__content--padding-left-#{$size} {
        padding-left: $padding;
    }

    .card__content--padding-vertical-#{$size} {
        padding-top: $padding;
        padding-bottom: $padding;
    }

    .card__content--padding-horizontal-#{$size} {
        padding-left: $padding;
        padding-right: $padding;
    }
}

@each $size, $border-radius in $card-border-radiuses {
    .card__content--border-radius-#{$size} {
        border-radius: $border-radius;
    }
}

@each $size, $border-radius in $card-border-radiuses {
    .card__content--border-radius-top-#{$size} {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
    }

    .card__content--border-radius-right-#{$size} {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }

    .card__content--border-radius-bottom-#{$size} {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }

    .card__content--border-radius-left-#{$size} {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
    }
}

.card__content-tag {
    margin-right: auto;
}

.card__footer,
.card__header {
    @include card-border-radius();

    position: relative;
    border-top: 0;
    min-height: 80px;
    display: flex;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;

    &.text-center {
        justify-content: center;
    }

    &.text-left {
        justify-content: start;
    }

    &.text-right {
        justify-content: end;
    }

    .card--background-video > &,
    .card--with-background-image > & {
        background: transparent;
    }
}

.card__header--grey,
.card__footer--grey {
    background: $grey;
}

@each $size, $padding in $card-paddings {
    .card__footer--padding-#{$size} {
        padding: $card-footer-vertical-padding $padding;
    }
}

@each $size, $padding in $card-paddings {
    .card__footer--padding-right-#{$size} {
        padding-right: $padding;
    }

    .card__footer--padding-left-#{$size} {
        padding-left: $padding;
    }

    .card__footer--padding-vertical-#{$size} {
        padding-top: $padding;
        padding-bottom: $padding;
    }

    .card__footer--padding-horizontal-#{$size} {
        padding-left: $padding;
        padding-right: $padding;
    }
}

@each $size, $padding in $card-paddings {
    .card__header--padding-#{$size} {
        padding: $card-header-vertical-padding $padding;
    }
}

@each $size, $padding in $card-paddings {
    .card__header--padding-right-#{$size} {
        padding-right: $padding;
    }

    .card__header--padding-left-#{$size} {
        padding-left: $padding;
    }

    .card__header--padding-vertical-#{$size} {
        padding-top: $padding;
        padding-bottom: $padding;
    }

    .card__header--padding-horizontal-#{$size} {
        padding-left: $padding;
        padding-right: $padding;
    }
}

.card--link-border {
    color: $text-color-base;
    font-weight: inherit;
    cursor: pointer;

    html[data-whatintent="mouse"] &:hover {
        color: $text-color-base;
    }

    &::before {
        @include transition(('animation': short, 'properties': border-color));

        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        pointer-events: none;
        border: $card-border-width solid transparent;
        border-radius: inherit;
        z-index: 1;
    }

    &[class^='card--type-'],
    &[class*=' card--type-'] {
        &::before {
            top: -$card-border-width;
            right: -$card-border-width;
            bottom: -$card-border-width;
            left: -$card-border-width;
        }
    }

    html[data-whatintent="mouse"] &:hover::before {
        @include transition(('animation': short, 'properties': border-color));

        border: 1px solid $card-hover-border-color;
    }
}

.card--link-background {
    --card-hover-background: #{$card-hover-bg};

    color: $text-color-base;
    font-weight: inherit;
    cursor: pointer;

    html[data-whatintent="mouse"] &:hover {
        color: $text-color-base;
    }

    &::before {
        @include transition(('animation': short, 'properties': background-color));

        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        pointer-events: none;
        background-color: transparent;
        border-radius: inherit;
        z-index: -1;
    }

    &[class^='card--type-'],
    &[class*=' card--type-'] {
        &::before {
            top: -$card-border-width;
            right: -$card-border-width;
            bottom: -$card-border-width;
            left: -$card-border-width;
        }
    }

    html[data-whatintent="mouse"] &:hover::before {
        @include transition(('animation': short, 'properties': background-color));

        background-color: var(--card-hover-background);
    }
}

.card__video {
    .card & {
        overflow: hidden;
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: inherit;
        // stylelint-disable-next-line value-no-vendor-prefix, property-no-vendor-prefix
        -webkit-mask-image: -webkit-radial-gradient($white, $black); // https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b
    }

    .card--background-video-opacity & {
        opacity: .2;
    }
}

.card__video .video__html5 {
    position: absolute;
    height: 100%;
    width: unset;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.card__background-image {
    border-radius: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
}

.card__background-image-picture {
    border-radius: inherit;
    display: block;
    width: 100%;
    height: 100%;

    .card & {
        overflow: hidden;
        position: absolute;
        border-radius: inherit;
    }
}

.card__content--with-background-image {
    position: relative;
    width: 100%;
    height: 100%;
}

.card__content-background-image-picture {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;

    .card & {
        overflow: hidden;
        position: absolute;
        border-radius: inherit;
    }
}

.card__content-object-fit {
    border-radius: inherit;
    display: block;
    z-index: -1;
    top: 0;
    left: 0;
}

.card__tag {
    position: relative;
    z-index: 2;
}

.card__tags {
    position: absolute;
    z-index: 2;
    top: 16px;
    right: -12px;

    @media (max-width: ($break-sm - 1)) {
        right: -8px;
    }
}

.card--badge {
    margin-top: math.div($badge-height, 2);
}

.card__badge-wrapper {
    position: absolute;
    left: 10px;
    right: 10px;
    pointer-events: none;
    display: flex;
    justify-content: center;
    top: 0;
}

.card__badge-wrapper-inner {
    border-radius: 100rem;
    background: $body-bg;
    pointer-events: auto;
    position: relative;
    z-index: 2;

    .card__badge-wrapper & {
        transform: translate(0, -50%);
        top: -1px;
    }
}
