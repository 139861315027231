@import "~@core-front/sass-tokens/index.scss";

/**
 * General helpers
 */

body {
    display: block;
    overflow-y: scroll;
}

#page-container { // stylelint-disable selector-max-id
    z-index: map_get($zindex, page-container);

    body.is-scroll-disabled & {
        overflow-y: hidden;
        position: fixed;
        left: 0;
        width: 100%;
    }
}

// Screen reader

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
}

/**
 * Positioning & alignment
 */

.clearfix {
    @include clear-fixer();
}

.position-relative {
    position: relative;
}

/**
 * Visibility helpers
 */

.hidden {
    display: none !important;
}

.visible {
    display: block !important;
}

.hidden-xs {
    @media only screen and (max-width: $break-sm - 1) {
        display: none !important;
    }
}

.hidden-sm {
    @media only screen and (min-width: $break-sm) and (max-width: $break-md - 1) {
        display: none !important;
    }
}

.hidden-md {
    @media only screen and (min-width: $break-md) and (max-width: $break-lg - 1) {
        display: none !important;
    }
}

.hidden-lg {
    @media only screen and (min-width: $break-lg) and (max-width: $break-xl - 1) {
        display: none !important;
    }
}

.hidden-xl {
    @media only screen and (min-width: $break-xl) {
        display: none !important;
    }
}

/**
 * Printing helpers
 */

@media print {
    .hidden-print {
        display: none !important;
    }
}

.visible-print {
    @media print {
        display: block !important;
    }
}

.visible-only-print {
    display: none;

    @media print {
        display: block !important;
    }
}
