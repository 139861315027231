.header__search {
    position: relative;

    .search__input {
        position: absolute;
        right: 0;
    }
}

.header__search-suggestion-button {
    display: block;
    margin: 0 auto;
}

.textfield__inner {
    .header__search & {
        padding-top: 0;

        &::after {
            content: none;
        }
    }
}

.textfield__label {
    .header__search & {
        &::before,
        &::after {
            content: none;
        }
    }
}

.textfield__input {
    .header__search & {
        border: none;
        border-radius: 23px;
        background-color: $grey-l;
        height: 48px;
        padding: 0 50px 0 45px;
    }
}

.search__action {
    .header__search & {
        padding: 13px 14px;
    }
}

.textfield__clear-btn {
    .header__search & {
        top: 12px;
        right: 14px;
    }
}

.header__search-suggestion.suggestion {
    border: none;
    border-radius: 8px;
    box-shadow: 0 12px 24px rgba($black, .1);
    width: calc(100vw - 30px);
    padding-bottom: 27px;
    max-height: none;
}
