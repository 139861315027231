.header__actions {
    .header__top & {
        max-width: 100%;

        @media (min-width: $break-sm) {
            max-width: none;
        }
    }
}

.header__actions-list {
    @include list-unstyled();

    display: flex;
    align-items: center;

    .header__top & {
        margin-right: -5px;

        @media (min-width: $break-sm) {
            margin-right: -10px;
        }

        @media (min-width: $break-lg) {
            margin-right: 0;
        }
    }

    .header__bottom & {
        align-items: stretch;
    }
}

.header__actions-item {
    position: relative;

    .header__bottom & {
        display: flex;

        &:not(:last-child) {
            margin-right: 25px;

            &.header__actions-item--cart {
                margin-right: 10px;
            }
        }
    }
}

.header__actions-item--search {
    @media (min-width: $break-lg) {
        margin-right: 5px;
    }
}

.header__actions-item--cart {
    .header__top & {
        display: none;

        @media (min-width: $break-lg) {
            display: block;
        }
    }

    .header__bottom & {
        @media (min-width: $break-lg) {
            display: none;
        }
    }
}

.header__actions-item--nav {
    .header__bottom & {
        @media (min-width: $break-lg) {
            display: none;
        }
    }
}

.header__actions-item--sso {
    overflow: hidden;

    @media (min-width: $break-sm) {
        overflow: visible;
    }

    & .header__actions-label {
        font-weight: 700;

        @media (min-width: $break-sm) {
            font-size: 14px;
        }
    }
}

.header__actions-button,
.header__actions-button-inner {
    display: flex;
    align-items: center;
    max-width: 100%;
}

.header__actions-button {
    font-size: 32px;
    color: $black;
    line-height: 1;
    justify-content: center;

    .header__top & {
        outline-offset: -3px;
    }
}

.header__actions-button-inner {
    flex-direction: column;

    .header__actions-button--sso:not(.is-text-color) & {
        color: $purple;
    }

    .header__actions-button.is-current &,
    html[data-whatintent="mouse"] &:hover {
        color: $purple;
    }

    .header__top & {
        flex-direction: row;
        padding: 8px 12px;
        font-size: 24px;

        @media (min-width: $break-lg) {
            font-weight: 400;
        }
    }

    .header__bottom .header__actions-item--search & {
        @media (min-width: $break-lg) {
            font-size: 32px;
        }
    }

    .header__bottom & {
        @media (min-width: $break-lg) {
            font-size: 17px;
            padding: 5px 10px;
        }
    }

    .header__actions-button.is-current & {
        position: relative;
        z-index: 4;
    }

    .header--open-search .header__actions-button--search.is-current & {
        background-color: $white;
    }
}

.header__actions-item--placeholder {
    opacity: 0;
    pointer-events: none;
}

.header__actions-label {
    font-size: 12px;
    font-weight: 400;

    .header__bottom & {
        display: block;
        font-size: 7px;
        margin-top: -5px;
    }

    .header__bottom .header__actions-item--search & {
        @media (min-width: $break-lg) {
            @include visually-hidden();
        }
    }

    .header__top .header__actions-item--sso & {
        margin-left: 6px;
    }

    .header__actions.is-logged-in .header__actions-item--sso & {
        max-width: 140px;
        padding: 0;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        /* For ÜÕÖÄ dots to be visible */
        line-height: 2;
    }

    .header__actions-item--cart &,
    .header__actions-item--notifications &,
    .header__actions-item--search &,
    .header__bottom .header__actions-item--search .header__actions-button.is-current & {
        @include visually-hidden();
    }
}

.header__actions-icon-wrapper {
    display: flex;
    align-items: center;
}

.header__actions-icon {
    @include reset-icon();

    display: block;

    .header__actions-button.is-current & {
        display: none;

        @media (min-width: $break-lg) {
            display: block;
        }
    }

    .header__actions-item--search .header__actions-button.is-current & {
        display: none;
    }

    .header__actions-item--sso .header__actions-button.is-current & {
        display: block;
    }
}

.header__actions-icon--close {
    display: none;

    .header__actions-item--sso .header__actions-button.is-current & {
        display: none;
    }

    .header__actions-button.is-current & {
        display: block;

        @media (min-width: $break-lg) {
            display: none;
        }
    }

    .header__actions-item--search .header__actions-button.is-current & {
        display: block;
    }
}

.header__actions-arrow {
    @include reset-icon();

    display: block;
    font-size: 16px;
    margin-left: 2px;
    flex-shrink: 0;
}

.header__actions-badge {
    vertical-align: bottom;
    position: relative;
    top: -10px;
    margin-left: -10px;

    @media (min-width: $break-lg) {
        top: -8px;
        margin-left: -12px;
    }
}

.header__actions-close-label {
    font-size: 16px;
    font-weight: 300;
    margin-left: 6px;

    @media (max-width: ($break-lg - 1)) {
        @include visually-hidden();
    }

    .header__actions-button.is-current &,
    html[data-whatintent="mouse"] &:hover {
        color: $purple;
    }
}

.header__top {
    .header__actions-item:first-child .header__actions-button-inner {
        padding-left: 16px;
    }

    .header__actions-item:last-child .header__actions-button-inner {
        padding-right: 16px;
    }

    .header__actions-item--sso .header__actions-button-inner {
        @media (max-width: ($break-sm - 1)) {
            padding-left: 10px;
        }
    }
}

.header__actions-item--notifications {
    .header__top & {
        display: none;

        @media (min-width: $break-lg) {
            display: block;
        }
    }

    .header__bottom & {
        @media (min-width: $break-lg) {
            display: none;
        }
    }
}

.header__actions-item--notifications,
.header__actions-item--cart,
.header__actions-item--search {
    .header__actions-button {
        font-size: 24px;

        @media (min-width: $break-lg) {
            font-size: 32px;
        }
    }
}

.header__actions-button.header__actions-button--search.is-current {
    font-size: 32px;
}

.header {
    &:not(.header--open-search) .header__actions-item--search {
        @media (min-width: $break-xl) {
            display: none;
        }
    }
}

.header--minimized-search .header__actions-item--search {
    display: flex;
}

.header--open-search {
    .header__bottom {
        .header__actions-item--notifications,
        .header__actions-item--cart,
        .header__actions-item--nav {
            display: none;
        }

        .header__actions-item--search {
            @include animation(
                (
                    'animation': short,
                    'name': fade-in,
                )
            );

            margin-right: 0;
            margin-left: $header-search-close-button-margin-left;

            @media (min-width: $break-lg) {
                margin-right: 5px;
                margin-left: 0;
            }

            @keyframes fade-in {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }
        }
    }
}

.header__bottom .header__actions-item--nav .header__actions-label {
    @include visually-hidden();
}
