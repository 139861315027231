@import "~@telia-front/sass-tokens/index.scss";

$pagination-link-size-mobile: 42px;
$pagination-link-size-mobile-tooltip: 30px;
$pagination-link-size-desktop: 26px;
$pagination-font-size: 12px;
$pagination-border-width: 1px;

.pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: $pagination-font-size;
    line-height: $pagination-link-size-mobile;

    @media (min-width: $break-sm) {
        padding: 0 4px;
        line-height: $pagination-link-size-desktop;
    }

    &.is-tooltip {
        display: block;
        max-height: 150px;
    }
}

.pagination--left {
    justify-content: flex-start;
}

.pagination--right {
    justify-content: flex-end;
}

.pagination__item {
    @include transition(('animation': short, 'properties': all));

    @media (min-width: $break-sm) {
        margin: 4px;
    }

    .pagination > & {
        display: none;

        @media (min-width: $break-sm) {
            display: block;
        }
    }
}

.pagination__prev,
.pagination__next {
    @media (min-width: $break-sm) {
        margin: 4px;
    }

    &.is-disabled {
        pointer-events: none;

        @media (min-width: $break-sm) {
            opacity: 0;
        }
    }
}

.pagination__link,
.pagination__break {
    @include reset-button();
    @include transition(('animation': short, 'properties': all));

    display: block;
    width: $pagination-link-size-mobile;
    height: $pagination-link-size-mobile;
    line-height: $pagination-link-size-mobile;
    background: $grey-m;
    color: $black;
    font-weight: 700;
    text-align: center;

    @media (min-width: $break-sm) {
        width: $pagination-link-size-desktop;
        height: $pagination-link-size-desktop;
        line-height: $pagination-link-size-desktop;
        border-radius: 50%;
        font-weight: 400;
        border: $pagination-border-width solid transparent;
    }

    .pagination--inverse & {
        background: $white;
    }

    .tooltip__content & {
        background: $white;
    }
}

.pagination__link {
    cursor: pointer;

    html[data-whatintent="mouse"] &:hover {
        @media (min-width: $break-sm) {
            border-color: $purple;
            color: $purple;
        }
    }

    html[data-whatintent="mouse"] .pagination--employee & {
        &:hover {
            color: $pink;

            @media (min-width: $break-sm) {
                border-color: $pink;
                color: $pink;
            }
        }
    }

    .pagination__item.is-active & {
        color: $purple;

        @media (min-width: $break-sm) {
            background: $purple;
            color: $white;
            font-weight: 700;
            // different line-height to fix misalignment issue in firefox
            line-height: $pagination-link-size-desktop - 1;
        }

        .pagination--employee & {
            color: $pink;

            @media (min-width: $break-sm) {
                background: $pink;
                color: $white;
            }
        }

        html[data-whatintent="mouse"] &:hover {
            @media (min-width: $break-sm) {
                background: $purple;
                color: $white;
            }
        }
    }

    html[data-whatintent="mouse"] .pagination--employee .pagination__item.is-active & {
        &:hover {
            @media (min-width: $break-sm) {
                background: $pink;
            }
        }
    }

    .pagination__prev & {
        border-radius: 50% 0 0 50%;

        @media (max-width: ($break-sm - 1)) {
            border-right: 1px solid $grey-l;
        }

        @media (min-width: $break-sm) {
            border-radius: 50%;
            padding: 4px;
        }
    }

    .pagination__next & {
        border-radius: 0 50% 50% 0;

        @media (max-width: ($break-sm - 1)) {
            border-left: 1px solid $grey-l;
        }

        @media (min-width: $break-sm) {
            border-radius: 50%;
            padding: 4px;
        }
    }

    .tooltip--pagination & {
        width: auto;
        height: $pagination-link-size-mobile-tooltip;
        line-height: $pagination-link-size-mobile-tooltip;
    }
}

.pagination__status {
    display: block;
    width: 100px;
    height: $pagination-link-size-mobile;
    margin: 0;
    border-radius: 0;
    background: $grey-m;
    color: $black;
    font-weight: 700;
    text-align: center;

    @media (min-width: $break-sm) {
        display: none;
    }

    .pagination--inverse & {
        background: $white;
    }
}

.pagination__status-button {
    @include reset-button ();

    width: 100%;
    height: 100%;
}

.pagination__icon {
    color: $purple;
    font-size: 24px;
    vertical-align: middle;

    @media (min-width: $break-sm) {
        font-size: 16px;
        vertical-align: top;
    }

    .pagination--employee & {
        color: $pink;
    }

    .pagination__prev.is-disabled &,
    .pagination__next.is-disabled & {
        opacity: .4;
    }
}

.pagination__tooltip {
    margin: 0;
    padding: 0;
    list-style: none;
}

/* tooltip modifications */

.tooltip__content {
    .tooltip--pagination & {
        position: relative;
        padding: 16px 0;
    }

    .tooltip--pagination-white & {
        background-color: $white;
    }

    .tooltip--pagination-light & {
        background-color: $grey-l;
    }
}

.tooltip--pagination-light {
    .pagination__link {
        background-color: $grey-l;
    }
}

.tooltip__close {
    .tooltip--pagination & {
        display: none;
    }
}
