@import "~@core-front/sass-tokens/index.scss";

@mixin text-align($name) {
    .text-center-#{$name} {
        text-align: center;
    }

    .text-left-#{$name} {
        text-align: left;
    }

    .text-right-#{$name} {
        text-align: right;
    }
}

small,
.text-small {
    font-size: $font-size-small;
    line-height: $line-height-small;

    @media (min-width: $break-xl) {
        font-size: $font-size-small-xl;
        line-height: $line-height-small-xl;
    }
}

strong,
.text-bold {
    font-weight: 700;
}

em,
.text-italic {
    font-style: italic;
}

.text-weight-normal {
    font-weight: 400 !important;
}

.text-size-normal {
    font-size: $base-font-size !important;
    line-height: $base-line-height !important;

    @media (min-width: $break-xl) {
        @if $base-font-size-xl {
            font-size: $base-font-size-xl !important;
        }

        @if $base-line-height-xl {
            line-height: $base-line-height-xl !important;
        }
    }
}

.text-color-normal {
    color: $text-color-base !important;
}

.text-white-space-normal {
    white-space: normal !important;
}

.text-normal {
    @extend .text-size-normal;
    @extend .text-weight-normal;
    @extend .text-color-normal;
}

.text-primary {
    color: var(--color-neutral-text, $color-primary-1);
}

.text-success {
    color: var(--color-success-text, $color-success-1);
}

.text-light {
    color: var(--color-neutral-text-subtle, $text-color-light);
}

.text-break {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
}

.text-inactive {
    color: var(--color-disabled-text, $color-inactive-1);
}

/**
 * Text helpers
 */

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

@media only screen and (min-width: $break-sm) {
    @include text-align(sm);
}

@media only screen and (min-width: $break-md) {
    @include text-align(md);
}

@media only screen and (min-width: $break-lg) {
    @include text-align(lg);
}

@media only screen and (min-width: $break-xl) {
    @include text-align(xl);
}
