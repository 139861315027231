@import "~@telia-front/sass-tokens/index.scss";
@import "~@core-front/badge/styles.scss";

.badge--attention {
    background: var(--color-attention-bg-subtle);
    color: var(--color-attention-text);
}

.badge--error {
    background: var(--color-error-bg-strong);
    color: var(--color-error-text-onbg-strong);
}

.badge--information {
    background: var(--color-info-bg-subtle);
    color: var(--color-info-text);
}

.badge--employee {
    background: var(--color-employee-bg-subtle);
    color: var(--color-employee-text);
}

.badge--employee-active {
    background: var(--color-employee-bg-strong);
    color: var(--color-employee-text-onbg-strong);
}

.badge--small {
    &.badge--three-digits {
        font-size: $badge-three-digit-small-font-size;
        line-height: $badge-three-digit-small-line-height;
        font-weight: $badge-three-digit-font-weight;
        padding: $badge-three-digit-small-padding;
    }

    &.badge--two-digits {
        padding: $badge-two-digit-small-padding;
        line-height: $badge-two-digit-small-line-height;
    }
}

.badge--large {
    &.badge--three-digits,
    &.badge--three-digits.badge--large-responsive {
        font-size: $badge-three-digit-large-font-size;
        line-height: $badge-three-digit-large-line-height;
        font-weight: $badge-three-digit-font-weight;
        padding: $badge-three-digit-large-padding;
    }

    &.badge--two-digits,
    &.badge--three-digits.badge--large-responsive {
        padding: $badge-two-digit-large-padding;
        line-height: $badge-two-digit-large-line-height;
    }

    @media (max-width: ($break-sm - 1)) {
        &.badge--large-responsive {
            &.badge--three-digits {
                padding: $badge-three-digit-large-padding-sm;
                font-size: $badge-three-digit-large-font-size-sm;
                line-height: $badge-three-digit-large-line-height-sm;
            }

            &.badge--two-digits {
                padding: $badge-two-digit-large-padding-sm;
                line-height: $badge-two-digit-large-line-height-sm;
            }
        }
    }
}

.badge__icon {
    color: var(--color-brand-primary-text-onbg-strong);

    .badge--selected & {
        color: var(--color-brand-primary-text-onbg-strong);
    }

    .badge--recommended & {
        color: var(--color-brand-expressive-graphic);
    }

    .badge--inactive & {
        color: var(--color-neutral-graphic);
    }

    .badge--success & {
        color: var(--color-success-graphic);
    }

    .badge--attention & {
        color: var(--color-attention-graphic);
    }

    .badge--error & {
        color: var(--color-error-text-onbg-strong);
    }

    .badge--information & {
        color: var(--color-info-graphic);
    }

    .badge--employee & {
        color: var(--color-employee-graphic);
    }

    .badge--employee-active & {
        color: var(--color-employee-text-onbg-strong);
    }
}
