@import "~@telia-front/sass-tokens/index.scss";

.product__text {
    @media (min-width: $break-lg) {
        text-align: center;
    }
}

.product__action {
    @media (min-width: $break-lg) {
        text-align: center;
    }
}

.product__button {
    @media (max-width: $break-sm - 1) {
        @include button-small();
    }
}
