.notification {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    padding: 16px;
    background: var(--color-neutral-bg-level-3);
    border-radius: 4px;
    border: 1px solid var(--color-neutral-border-subtle);
    overflow: hidden;
    font-size: var(--type-small-font-size);
    line-height: var(--type-small-line-height);

    &.click-area {
        cursor: pointer;
    }

    &.notification--not-seen.notification--info {
        background: var(--color-info-bg-subtle);
        border-color: var(--color-info-border);
    }

    &.notification--not-seen.notification--success {
        background: var(--color-success-bg-subtle);
        border-color: var(--color-success-border);
    }

    &.notification--not-seen.notification--error {
        background: var(--color-error-bg-subtle);
        border-color: var(--color-error-border);
    }

    &.notification--not-seen.notification--attention {
        background: var(--color-attention-bg-subtle);
        border-color: var(--color-attention-border);
    }
}

.notification__icon {
    font-size: 24px;

    .notification--info & {
        color: var(--color-info-graphic);
    }

    .notification--error & {
        color: var(--color-error-graphic);
    }

    .notification--success & {
        color: var(--color-success-graphic);
    }

    .notification--attention & {
        color: var(--color-attention-graphic);
    }
}

.notification__head {
    display: flex;
    align-items: center;
    gap: 8px;
}

.notification__time {
    color: var(--color-neutral-text-subtle);
    font-size: var(--type-tiny-font-size);
    line-height: var(--type-tiny-line-height);
}
