@import "~@telia-front/sass-tokens/index.scss";

.datefield__button {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 16px 9px 9px;
    background: transparent;
    border: 0;
    border-radius: 0;
    appearance: none;
    font-size: 24px;
    width: 100%;
    // disable focus outline because focus is shown by pseudo-focused input styles
    outline: none;
}

.datefield__input {
    padding-right: 40px;
}

.datefield__button-icon {
    @include reset-icon();

    display: block;
    margin-left: auto;

    .textfield--small & {
        position: absolute;
        top: 12px;
        right: 5px;
        width: 16px;
        height: 16px;
    }
}
