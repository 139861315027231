@use "sass:map";

@import "~@telia-front/sass-tokens/index.scss";

.large-search {
    position: relative;
    max-width: 720px;
    margin-right: auto;
    margin-left: auto;
    z-index: map.get($zindex, search-large);

    .select {
        border: 2px solid transparent;
        transition: border-color 150ms ease-in-out;

        &.is-focused {
            border-color: $color-focus-1;
        }
    }

    .select__inner {
        display: flex;
        padding: 0;
    }

    .select__label {
        @include visually-hidden();
    }

    .select__wrapper {
        border: 0;
        font-weight: 700;
        height: 60px;
    }

    .select__control {
        top: 0;
        height: 63px;
    }

    .select__icon {
        top: 18px;
    }
}

.large-search--left {
    margin-left: 0;
}

.large-search--right {
    margin-right: 0;
}

.large-search__inner {
    position: relative;
    min-height: 64px;
    border-radius: 32px;
    padding: 0;
    background: $grey-l;
    box-shadow: 0 1px 2px 0 rgba($black, .1);

    .large-search--dark & {
        background: $white;
        text-shadow: inset 0 1px 1px rgba($black, .1);
    }

    .large-search--multiline & {
        border-radius: 16px;
    }
}

.large-search__fields {
    display: flex;
    flex-wrap: wrap;
}

.large-search__block {
    position: relative;
    flex: 1;
    padding: 0;
    min-height: 64px;
    display: flex;

    .large-search--multiline & {
        flex: 1 0 100%;
    }

    &::after {
        content: "";
        position: absolute;
        right: 7px;
        bottom: -1px;
        left: 7px;
        height: 1px;
        background: $grey;
    }

    &:first-child {
        & .textfield {
            border-top-left-radius: 32px;
            border-bottom-left-radius: 32px;
        }

        .large-search--multiline &:not(:only-child) .textfield,
        .large-search--multiline &:not(:only-child) .select {
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
            border-bottom-left-radius: 0;
        }
    }

    &:last-child {
        &::after {
            display: none;
        }

        & .textfield,
        & .select {
            border-top-right-radius: 32px;
            border-bottom-right-radius: 32px;
        }

        .large-search--multiline &:not(:only-child) .textfield,
        .large-search--multiline &:not(:only-child) .select {
            border-top-right-radius: 0;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
        }
    }

    .large-search--multiline-conditional & {
        & + .large-search__block .textfield__inner-input {
            transition: width 150ms ease-in-out;
        }

        & + .large-search__block .large-search__action {
            flex: 1;
        }

        & + .large-search__block .textfield__inner-content {
            display: flex;
        }
    }

    .large-search--multiline-conditional:not(.is-dirty) & {
        & + .large-search__block .textfield__inner-input {
            width: 0;
            flex: 0;
            overflow: hidden;
        }

        & + .large-search__block .textfield__inner-content {
            flex: 1;
        }
    }

    /* Not multiline */

    .large-search:not(.large-search--multiline) & {
        &:first-child::after {
            @media (min-width: $break-sm) {
                top: 14px;
                right: 0;
                bottom: 14px;
                left: auto;
                width: 1px;
                height: auto;
            }
        }

        &:nth-child(2) {
            @media (min-width: $break-sm) {
                max-width: 200px;
            }
        }
    }

    .large-search.large-search--has-button-text:not(.large-search--multiline) & {
        @media (min-width: $break-sm) {
            &:not(:only-child) {
                flex: 3;
            }
        }

        &:nth-child(2) {
            @media (min-width: $break-sm) {
                flex: 2;
                max-width: none;
            }
        }
    }
}

.large-search__action {
    margin: 6px 6px 6px 0;
    transition: outline 150ms ease-in-out;

    .large-search--multiline-conditional & {
        margin: 6px;
    }

    html:not([data-whatinput="keyboard"]) &:focus {
        outline: none;
    }

    html[data-whatinput="keyboard"] &:focus {
        outline: 2px solid $color-focus-1;
    }
}

.large-search__icon {
    @include reset-icon();
}

.large-search__help {
    margin: 5px 32px 0;

    @media (max-width: $break-sm) {
        margin-left: 24px;
        margin-right: 24px;
    }

    .large-search--multiline & {
        margin-left: 16px;
        margin-right: 16px;
    }

    &.form-helper--help {
        color: $grey-t;
    }
}

/* Input Overrides */

.textfield {
    .large-search & {
        border: 2px solid transparent;
        transition: border-color 150ms ease-in-out;

        &.is-invalid {
            border-color: $color-error-1;
            border-width: 1px;
        }

        &.is-focused {
            border-color: $color-focus-1;
            border-width: 2px;
        }
    }
}

.textfield__input {
    .large-search & {
        margin-top: 0;
        margin-bottom: -1px;
        padding-top: 28px;
        padding-right: 16px;
        padding-left: 32px;
        border: 0;
        font-size: 18px;
        font-weight: 700;
        height: 61px;
        min-width: 100px;

        @media (max-width: ($break-sm - 1)) {
            padding-left: 24px;
        }
    }

    .large-search--multiline & {
        padding-left: 16px;
    }

    .large-search .textfield--image & {
        padding-left: 64px;
    }

    .large-search .textfield--no-label-no-break & {
        padding-top: 13px;
    }

    .large-search__block:nth-child(1) &:-webkit-autofill {
        border-radius: 16px 16px 0 0;

        @media (min-width: $break-sm) {
            border-radius: 32px 0 0 32px;
        }
    }

    .large-search__block:nth-child(2) &:-webkit-autofill {
        border-radius: 0 0 0 32px;

        @media (min-width: $break-sm) {
            border-radius: 0;
        }
    }

    .large-search__block:only-child &:-webkit-autofill {
        border-radius: 32px 0 0 32px;
    }

    .large-search--multiline .large-search__block:nth-child(1) &:-webkit-autofill {
        border-radius: 16px 16px 0 0;
    }

    .large-search--multiline .large-search__block:nth-child(2) &:-webkit-autofill {
        border-radius: 0 0 0 16px;
    }

    .large-search__block:only-child & {
        padding-left: 32px;

        .large-search--multiline & {
            padding-left: 24px;
        }

        @media (max-width: ($break-sm - 1)) {
            padding-left: 24px;
        }
    }

    .large-search__block:last-child & {
        padding-left: 32px;

        .large-search--multiline & {
            padding-left: 16px;
        }

        @media (max-width: ($break-sm - 1)) {
            padding-left: 24px;
        }
    }

    .large-search:not(.large-search--multiline) .large-search__block:not(:first-child) & {
        @media (min-width: ($break-sm - 1)) {
            padding-left: 16px;
        }
    }

    .large-search__block .textfield.textfield--with-icon.is-dirty & {
        padding-right: 50px;
    }

    .large-search__block:last-child .textfield.textfield--with-icon.is-dirty & {
        padding-right: 40px;
    }
}

.textfield__image {
    .large-search .textfield--no-label-no-break & {
        top: 25px;
    }

    .large-search .textfield--image & {
        left: 32px;
    }
}

.textfield__inner {
    .large-search & {
        padding: 0;
        position: relative;
        display: flex;

        & .textfield__inner-input {
            position: relative;
            flex: 1;
        }

        &::after {
            border: 0;
        }
    }
}

.textfield__label {
    .large-search .textfield--floating-label & {
        @include animation(
            (
                'animation': short,
                'name': hide-large-search-small-label,
            ),
            (
                'animation': short,
                'name': show-large-search-big-label,
                'delay': short,
            )
        );

        padding-top: 18px;
        padding-right: 14px;
        padding-left: 13px;
        font-size: 18px;
        font-weight: 700;
        color: $grey-t;

        &::before,
        &::after {
            border: 0;
        }
    }

    .large-search--multiline .textfield--floating-label & {
        padding-left: 5px;
    }

    .large-search:not(.large-search--multiline) .large-search__block:not(:first-child) & {
        @media (min-width: $break-sm) {
            padding-left: 0;

            &::before {
                padding-right: 6px;
            }
        }
    }

    .large-search .textfield.is-focused &,
    .large-search .textfield.is-dirty & {
        @include animation(
            (
                'animation': short,
                'name': hide-large-search-big-label,
            ),
            (
                'animation': short,
                'name': show-large-search-small-label,
                'delay': short,
            )
        );

        padding-top: 12px;
        line-height: 16px;
        font-size: 12px;
        font-weight: 400;
    }

    .large-search .textfield.is-invalid & {
        color: $red;
    }

    &::before {
        .large-search--multiline & {
            padding-right: 0;
        }

        .large-search & {
            @media (max-width: ($break-sm - 1)) {
                padding-right: 0;
            }
        }
    }

    .large-search .textfield--with-icon:not(.is-focused, .is-dirty) & .textfield__label-text {
        padding-right: 0;
    }
}

.textfield__clear-btn {
    .large-search & {
        top: 20px;
        right: 16px;
        border-radius: 50%;
        transition: outline 150ms ease-in-out;

        @media (max-width: ($break-sm - 1)) {
            right: 8px;
        }

        html:not([data-whatinput="keyboard"]) &:focus {
            outline: none;
        }

        html[data-whatinput="keyboard"] &:focus {
            outline: 2px solid $color-focus-1;
        }
    }

    .large-search:not(.large-search--multiline) .large-search__block:last-child & {
        right: 8px;
    }
}

@keyframes hide-large-search-small-label {
    0% {
        padding-top: 12px;
        font-size: $textfield-label-size;
        font-weight: $textfield-weight;
    }

    100% {
        padding-top: 18px;
        font-size: $textfield-label-size;
        font-weight: $textfield-weight;
    }
}

@keyframes show-large-search-small-label {
    0% {
        padding-top: 18px;
        font-size: $textfield-label-size;
        font-weight: $textfield-weight;
    }

    100% {
        padding-top: 12px;
        font-size: $textfield-label-size;
        font-weight: $textfield-weight;
    }
}

@keyframes hide-large-search-big-label {
    0% {
        padding-top: 18px;
        font-size: $textfield-floating-label-size;
        font-weight: 700;
    }

    100% {
        padding-top: 12px;
        font-size: $textfield-floating-label-size;
        font-weight: 700;
    }
}

@keyframes show-large-search-big-label {
    0% {
        padding-top: 12px;
        font-size: $textfield-floating-label-size;
        font-weight: 700;
    }

    100% {
        padding-top: 18px;
        font-size: $textfield-floating-label-size;
        font-weight: 700;
    }
}

/* Select Overrides */

.select__value-container {
    .large-search & {
        top: 20px;
    }

    .large-search:not(.large-search--multiline) .large-search__block:not(:first-child) & {
        @media (min-width: $break-sm) {
            padding-left: 16px;
        }
    }
}
