.eu-icon {
    display: inline-block;
    min-width: 50px;
    text-align: center;
    font-weight: 700;
}

.eu-icon__svg {
    display: block;
    margin: 0 auto;
}
