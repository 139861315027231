@import "~@core-front/sass-tokens/index.scss";

.choice-group--center {
    text-align: center;
}

.choice-group__inner {
    margin-bottom: -10px;

    .choice-group--inline & {
        margin-left: -10px;
        margin-right: -10px;
    }

    .choice-group--purpose-agreement & {
        box-shadow: 0 1px 1px rgba($choice-group-purpose-agreement-box-shadow, .1);
        border: 1px solid $choice-group-purpose-agreement-background;
        padding: 6px 19px 19px;
        margin-top: 0;
        margin-bottom: 0;
        border-radius: $choice-group-purpose-agreement-border-radius;

        &.choice-group__inner--default-background {
            background-color: $choice-group-purpose-agreement-background;
        }
    }

    .choice-group--purpose-agreement.is-invalid & {
        @include animation((
            'animation': long,
            'name': choice-group-error,
            'iteration-count': 1,
            'direction': forwards,
        ));
        
        border-color: $choice-group-purpose-agreement-invalid-border;
        transform: scale($error-box-animation-scale);
    }

    .choice-group--inline.choice-group--purpose-agreement & {
        margin-left: 0;
        margin-right: 0;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.choice-group__label {
    font-size: $choice-group-label-font-size;
    margin-bottom: 15px;

    .choice-group--no-label-no-break & {
        @include visually-hidden();
    }

    .choice-group--no-label-sm & {
        @media (min-width: $break-sm) {
            @include visually-hidden();
        }
    }

    .choice-group--no-label-md & {
        @media (min-width: $break-md) {
            @include visually-hidden();
        }
    }
}

.choice-group__item {
    padding-bottom: 10px;

    .choice-group--inline & {
        display: inline-block;
        vertical-align: top;
        padding-left: 10px;
        padding-right: 10px;
    }

    .choice-group--purpose-agreement & {
        margin-top: 13px;
        padding-bottom: 0;
    }
}

.choice-group__form-helper {
    margin-top: $textfield-form-helper-margin-top;

    &.form-helper--help {
        color: $choice-group-form-helper-help;
    }

    .choice-group--center & {
        justify-content: center;
    }
}

@keyframes choice-group-error {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba($choice-group-purpose-agreement-box-shadow, .4);
        animation-timing-function: $error-box-animation-in-easing;
    }

    43% {
        transform: scale($error-box-animation-scale);
        box-shadow: 0 0 20px 0 rgba($choice-group-purpose-agreement-box-shadow, .4);
        animation-timing-function: $error-box-animation-out-easing;
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba($choice-group-purpose-agreement-box-shadow, .4);
    }
}
