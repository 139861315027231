@import "~@telia-front/sass-tokens/index.scss";
@import "~@core-front/breadcrumbs/styles.scss";

.breadcrumbs__link {
    .breadcrumbs--theme-dark & {
        color: $breadcrumbs-link-dark-color;

        html[data-whatintent="mouse"] &:hover {
            color: $breadcrumbs-link-dark-hover-color;
        }
    }
}

.breadcrumbs__icon {
    .breadcrumbs--theme-dark & {
        color: $breadcrumbs-icon-dark-color;
    }
}
