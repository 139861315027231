@import "~@telia-front/sass-tokens/index.scss";

@import "~@core-front/button/styles.scss";

.btn__text {
    &::first-letter {
        text-transform: uppercase;
    }
}

/*
 * Third party buttons
 */

.btn--outer {
    &-spotify {
        @include button-variant($btn-outer-spotify-color, $btn-outer-spotify-background, $btn-outer-spotify-background-hover, $btn-outer-spotify-border, $btn-outer-spotify-border-hover, $btn-outer-spotify-hover-color, null, $btn-outer-spotify-pressed-color, $btn-outer-spotify-pressed-background);
    }

    &-twitter {
        @include button-variant($btn-outer-twitter-color, $btn-outer-twitter-background, $btn-outer-twitter-background-hover, $btn-outer-twitter-border, $btn-outer-twitter-border-hover, $btn-outer-twitter-hover-color, null, $btn-outer-twitter-pressed-color, $btn-outer-twitter-pressed-background);
    }

    &-diil {
        @include button-variant($btn-outer-diil-color, $btn-outer-diil-background, $btn-outer-diil-background-hover, $btn-outer-diil-border, $btn-outer-diil-border-hover, $btn-outer-diil-hover-color, null, $btn-outer-diil-pressed-color, $btn-outer-diil-pressed-background);
    }
}
