@import "~@telia-front/sass-tokens/index.scss";

.comments__item {
    margin-bottom: 10px;

    @media (min-width: $break-sm) {
        margin-bottom: 20px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.comments__item--level-1 {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $grey;

    @media (min-width: $break-sm) {
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
}

.comments__item-inner {
    display: flex;
    flex-direction: column;

    @media (min-width: $break-sm) {
        flex-direction: row;
    }
}

.comments__item-sender {
    margin: 0 0 9px;
    font-size: 16px;
    font-weight: 700;

    @media (min-width: $break-sm) {
        margin: 9px 20px 0 0;
    }
}

.comments__item-sender-name {
    max-width: 150px;
}

.comments__item-comment-wrapper {
    width: 100%;
}

.comments__item-comment {
    display: inline-block;
    padding: 9px 17px 11px 15px;
    background-color: $white;
    border-radius: 3px 12px 12px;
    max-width: 440px;

    .comments--inverse & {
        background-color: $grey-l;
    }

    .comments--full-width & {
        max-width: none;
    }

    .comments__item--primary & {
        background-color: $color-primary-1;
        color: $white;
    }
}

.comments__item-meta {
    margin-top: 10px;
    max-width: 100%;
}

.comments__item-date {
    color: $grey-t;
    font-size: 12px;
}

.comments__item-replies {
    padding-top: 20px;
}
