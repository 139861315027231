@import "~@telia-front/sass-tokens/index.scss";

.indicator {
    display: inline-flex;
    flex-direction: column;
}

.indicator__bar {
    width: 178px;
    height: 101px;
}

.indicator__bar-step-1 {
    fill: $red;

    .indicator--disabled & {
        fill: $grey-t;
    }
}

.indicator__bar-step-2 {
    fill: $orange;

    .indicator--disabled & {
        fill: $grey-d;
    }
}

.indicator__bar-step-3 {
    fill: $green;

    .indicator--disabled & {
        fill: $grey;
    }
}

.indicator__pointer-level-1 {
    fill: $red;
}

.indicator__pointer-level-2 {
    fill: $orange;
}

.indicator__pointer-level-3 {
    fill: $green;
}

.indicator__pointer-icon {
    fill: $white;
}

.indicator__text {
    align-self: center;
    margin-top: 10px;
    font-size: $h4-size;
}
