@import "~@telia-front/sass-tokens/index.scss";

a.arrow-list__link {
    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    .arrow-list__item--hover & {
        &.anchor:hover {
            color: $color-raw-black-strong;
        }
    }

    .arrow-list__item--active & {
        font-weight: unset;

        &.anchor:hover {
            color: $color-raw-black-strong;
        }

        &.anchor.anchor--employee:hover {
            color: $color-employee-1;
        }
    }

    .arrow-list__content > .arrow-list__arrow-icon {
        align-self: center;
    }
}

.arrow-list__icon {
    transition: color $transition-default;

    .list__inner > .list__item > .list__item-inner > & {
        font-size: 24px;
        margin-right: .625em;

        @media (max-width: $break-sm) {
            .list--icon:not(.list--icon-position-middle) & {
                margin-top: 5px;
            }
        }

        @media (min-width: $break-sm) {
            align-self: center;
            font-size: 48px;
            margin-right: .3334em;
        }
    }

    .arrow-list:not(.arrow-list--with-icon) & {
        display: none;
    }

    html[data-whatintent="mouse"] .arrow-list__item:hover:not(.arrow-list__item--hover) > .list__item-inner > & {
        color: $color-primary-2;
    }

    html[data-whatintent="mouse"] .arrow-list--purpose-employee:not(.arrow-list__item--hover) .arrow-list__item:hover:not(.arrow-list__item--hover) > .list__item-inner > & {
        color: $color-employee-2;
    }

    html[data-whatintent="mouse"] .arrow-list__item--active:hover:not(.arrow-list__item--hover) > .list__item-inner > & {
        color: $text-color-base;
    }
}

.arrow-list__content {
    display: inline-flex;
    width: 100%;
    min-height: 19px;

    @media (min-width: $break-xl) {
        min-height: 29px;
    }
}

.arrow-list__inner {
    width: 100%;
    flex: 0 1 auto;
    align-self: center;
}

.list__item-inner {
    .arrow-list--large > .list--bordered > .list__inner & {
        padding: 15px;

        @media (min-width: $break-sm) {
            padding: 24px;
        }
    }

    .arrow-list--large.arrow-list--padding-small > .list--bordered > .list__inner & {
        padding: 15px;

        @media (min-width: $break-sm) {
            padding: 16px;
        }
    }
}

.list__item.arrow-list__item {
    position: relative;

    .list--bordered > .list__inner > & {
        margin-top: 0 !important;
        padding: 13.5px 0;

        .arrow-list--large & {
            padding: 0;
            border: none;
        }

        &:first-child {
            padding: 13px 0;

            .arrow-list--large & {
                padding: 0;
                border: none;
            }
        }
    }

    .arrow-list--large & {
        html[data-whatintent="mouse"] &.bg-primary-4.arrow-list__item--hover:hover {
            background-color: $arrow-list-background-white-hover;
        }

        html[data-whatintent="mouse"] &.bg-background-4.arrow-list__item--hover:hover {
            background-color: $arrow-list-background-beige-hover;
        }

        html[data-whatintent="mouse"] &.bg-neutral-1.arrow-list__item--hover:hover {
            background-color: $arrow-list-background-default-hover;
        }
    }

    .arrow-list--large:not(.arrow-list--with-heading) > .list--bordered > .list__inner > &:first-child {
        border-radius: $card-border-radius-medium $card-border-radius-medium 0 0;
    }

    .arrow-list--large > .list--bordered > .list__inner > &:last-child {
        border-radius: 0 0 $card-border-radius-medium $card-border-radius-medium;
    }

    .arrow-list--large.arrow-list--margin-tiny > .list--bordered > .list__inner > & {
        margin: 0 0 1px;
    }

    .arrow-list--large.arrow-list--margin-xsmall > .list--bordered > .list__inner > & {
        margin: 0 0 2px;
    }

    .arrow-list--large.arrow-list--margin-small > .list--bordered > .list__inner > & {
        margin: 0 0 4px;
    }

    .arrow-list--large.arrow-list--margin-medium > .list--bordered > .list__inner > & {
        margin: 0 0 8px;
    }
}
