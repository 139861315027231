@import "~@core-front/sass-tokens/index.scss";

$toggle-indicator-width: 40px;
$toggle-indicator-height: 24px;
$toggle-font-size: 16px;
$toggle-line-height: 20px;
$toggle-indicator-label-spacing: 8px;
$toggle-indicator-padding: $toggle-indicator-width + $toggle-indicator-label-spacing;
$toggle-circle-size: 16px;
$toggle-circle-spacing: 4px;
$toggle-spinner-size: 14px;

$toggle-small-indicator-width: 24px;
$toggle-small-indicator-height: 16px;
$toggle-small-font-size: 12px;
$toggle-small-line-height: 16px;
$toggle-small-indicator-label-spacing: 4px;
$toggle-small-indicator-padding: $toggle-small-indicator-width + $toggle-small-indicator-label-spacing;
$toggle-small-circle-size: 8px;

$toggle-large-indicator-width: 56px;
$toggle-large-indicator-height: 32px;
$toggle-large-indicator-label-spacing: 12px;
$toggle-large-indicator-padding: $toggle-large-indicator-width + $toggle-large-indicator-label-spacing;
$toggle-large-circle-size: 22px;
$toggle-large-spinner-size: 22px;
$toggle-large-circle-spacing: 5px;

.toggle {
    position: relative;
}

.toggle__label {
    @include visually-hidden();
}

.toggle__inner {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding-left: $toggle-indicator-padding;
    min-height: $toggle-indicator-height;
    cursor: pointer;

    .toggle--small & {
        padding-left: $toggle-small-indicator-padding;
        min-height: $toggle-small-indicator-height;
    }

    .toggle--large & {
        padding-left: $toggle-large-indicator-padding;
        min-height: $toggle-large-indicator-height;
    }

    .toggle--with-label-left & {
        padding-left: 0;
        padding-right: $toggle-indicator-padding;
    }

    .toggle--with-label-left.toggle--small & {
        padding-right: $toggle-small-indicator-padding;
    }

    .toggle--with-label-left.toggle--large & {
        padding-right: $toggle-large-indicator-padding;
    }

    .toggle--with-label-inside & {
        white-space: nowrap;
        min-height: $toggle-large-indicator-height;
        padding: 0;
    }

    .toggle__input:disabled ~ & {
        cursor: default;
        pointer-events: none;
    }

    html[data-whatintent="keyboard"] .toggle__input:focus ~ & {
        @include keyboard-focus();
    }
}

.toggle__indicator {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    width: $toggle-indicator-width;
    height: $toggle-indicator-height;
    border-radius: 99em;
    border: $toggle-border;
    transform: translateY(-50%);
    user-select: none;

    .toggle--with-label-left & {
        right: 0;
        left: auto;
    }

    &::after {
        content: "";
        position: absolute;
        display: block;
        width: $toggle-circle-size;
        height: $toggle-circle-size;
        top: 50%;
        left: $toggle-circle-spacing;
        transform: translate(0, -50%);
        border-radius: 99em;
        background: $toggle-indicator-after-background;
        box-shadow: 0 1px 1px rgba($black, .2);
        transition: all .2s ease;
    }

    .toggle--small & {
        width: $toggle-small-indicator-width;
        height: $toggle-small-indicator-height;

        &::after {
            width: $toggle-small-circle-size;
            height: $toggle-small-circle-size;
        }
    }

    .toggle--large & {
        width: $toggle-large-indicator-width;
        height: $toggle-large-indicator-height;

        &::after {
            width: $toggle-large-circle-size;
            height: $toggle-large-circle-size;
            left: $toggle-large-circle-spacing;
        }
    }

    .toggle--with-label-inside & {
        width: auto;
        height: $toggle-large-indicator-height;
        position: static;
        transform: none;

        &::after {
            width: $toggle-large-circle-size;
            height: $toggle-large-circle-size;
            left: $toggle-large-circle-spacing;
        }
    }

    .toggle__input:disabled ~ .toggle__inner & {
        opacity: .5;
    }

    .toggle__input:not(:disabled) ~ .toggle__inner & {
        .toggle--employee & {
            &::after {
                background: $toggle-indicator-after-background-employee;
            }
        }
    }

    .toggle__input:checked ~ .toggle__inner & {
        border-color: $toggle-indicator-checked-color;
        background: $toggle-indicator-checked-color;

        &::after {
            background: $toggle-checked-indicator-after-background;
            left: calc(100% - $toggle-circle-spacing - $toggle-circle-size);

            .toggle--small & {
                left: calc(100% - $toggle-circle-spacing - $toggle-small-circle-size);
            }

            .toggle--large & {
                left: calc(100% - $toggle-large-circle-spacing - $toggle-large-circle-size);
            }

            .toggle--with-label-inside & {
                left: calc(100% - $toggle-large-circle-spacing - $toggle-large-circle-size);
            }
        }

        .toggle--employee & { 
            border-color: $toggle-indicator-checked-color-employee;
            background: $toggle-indicator-checked-color-employee;
    
            &::after {
                background: $toggle-checked-indicator-after-background-employee;
            }
        }
    }
}

.toggle__label-wrapper {
    display: inline-block;
    position: relative;
}

.toggle__text {
    display: inherit;
    text-align: left;
    font-size: $toggle-font-size;
    line-height: $toggle-line-height;
    font-weight: 400;

    .toggle--small & {
        font-size: $toggle-small-font-size;
        line-height: $toggle-small-line-height;
        font-weight: inherit;
    }

    .toggle--with-label-left & {
        text-align: right;
    }

    .toggle--with-label-inside & {
        display: block;
        font-size: $toggle-small-font-size;
        line-height: $toggle-small-line-height;
        font-weight: 700;
        padding-top: calc(($toggle-large-indicator-height - $toggle-small-line-height) / 2);
        padding-bottom: calc(($toggle-large-indicator-height - $toggle-small-line-height) / 2);
        text-align: center;
    }

    .toggle--with-label-inside &:not(.toggle__text--loading) {
        color: $color-inactive-1;
    }

    .toggle__input:disabled ~ .toggle__inner & {
        color: $color-inactive-1;
    }

    .toggle__input:checked ~ .toggle__inner &:not(.toggle__text--loading) {
        .toggle--with-label-inside & {
            color: $white;
        }
    }

    .toggle--loading &:not(.toggle__text--loading) {
        visibility: hidden;
    }
}

.toggle__text--off {
    .toggle__indicator & {
        padding-left: $toggle-large-circle-size + $toggle-circle-spacing + 10px; // 10px min-space between text and circle
        padding-right: 15px;
    }

    .toggle--with-on-label .toggle__input:checked ~ .toggle__inner &,
    .toggle--with-label-inside .toggle__input:checked ~ .toggle__inner & {
        visibility: hidden;
    }
}

.toggle__text--on {
    visibility: hidden;
    display: inherit;
    margin-top: -$toggle-line-height; // pull on top of off text

    .toggle--small & {
        margin-top: -$toggle-small-line-height;
    }

    .toggle__indicator & {
        margin-top: -$toggle-large-indicator-height; // pull on top of off text
        padding-right: $toggle-large-circle-size + $toggle-circle-spacing + 10px; // 10px min-space between text and circle
        padding-left: 15px;
    }

    .toggle__inner > .toggle__text--off + & {
        display: block;
    }

    .toggle:not(.toggle--loading) .toggle__input:checked ~ .toggle__inner & {
        visibility: visible;
    }
}

.toggle__input {
    @include visually-hidden();
}

.toggle__text--loading {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: $toggle-spinner-size;
    line-height: $toggle-spinner-size;

    .toggle__indicator & {
        padding-left: $toggle-large-circle-size + $toggle-circle-spacing + 10px; // 10px min-space between text and circle
        padding-right: 15px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .toggle__input:checked ~ .toggle__inner .toggle__indicator & {
        padding-right: $toggle-large-circle-size + $toggle-circle-spacing + 10px; // 10px min-space between text and circle
        padding-left: 15px;
    }

    .toggle--large & {
        height: $toggle-large-spinner-size;
        line-height: $toggle-large-spinner-size;
    }
}

.toggle__text--loading .spinner__circle {
    width: $toggle-spinner-size;
    height: $toggle-spinner-size;

    .toggle--large & {
        width: $toggle-large-spinner-size;
        height: $toggle-large-spinner-size;
    }

    .toggle--employee & {
        .spinner__path {
            stroke: $toggle-spinner-color-employee;
        }
    }
}

.toggle__input:checked ~ .toggle__inner .toggle__indicator .spinner__path {
    stroke: $white;
}
