@import "~@core-front/sass-tokens/index.scss";

.collapse-toggle {
    cursor: pointer;
}

.collapse-toggle__icon {
    @include transition(('animation': medium, 'properties': transform));

    .collapse-toggle.is-active.collapse-toggle--rotate-degree-180.collapse-toggle--rotate-direction-clockwise & {
        transform: $btn-icon-translate rotate(180deg);
    }

    .collapse-toggle.is-active.collapse-toggle--rotate-degree-180.collapse-toggle--rotate-direction-counterclockwise & {
        transform: $btn-icon-translate rotate(-180deg);
    }

    .collapse-toggle.is-active.collapse-toggle--rotate-degree-90.collapse-toggle--rotate-direction-clockwise & {
        transform: $btn-icon-translate rotate(90deg);
    }

    .collapse-toggle.is-active.collapse-toggle--rotate-degree-90.collapse-toggle--rotate-direction-counterclockwise & {
        transform: $btn-icon-translate rotate(-90deg);
    }
}
