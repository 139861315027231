@import "~@core-front/sass-tokens/index.scss";

.badge {
    display: inline-block;
    min-width: 30px;
    padding: $badge-padding;
    background: $badge-background;
    border-radius: 100rem;
    color: $badge-color;
    font-size: 12px;
    font-weight: 700;
    line-height: $badge-line-height;
    text-align: center;
}

.badge--small {
    min-width: $badge-size-small;
    padding: $badge-spacing-small;
    font-size: $badge-font-size-small;
    line-height: $badge-font-height-small;
}

.badge--large {
    padding: 5px;
    min-width: $badge-size-large;
    font-size: $badge-font-size-large;
    line-height: $badge-size-large-line-height;
    font-weight: $badge-font-weight-large;

    @media (max-width: ($break-sm - 1)) {
        &.badge--large-responsive {
            padding: 4px;
            min-width: 24px;
            line-height: 16px;
            font-size: $badge-font-size-large-sm;
            font-weight: $badge-font-weight-large-sm;
        }
    }
}

.badge--inactive {
    background: $badge-inactive-background;
    color: $badge-inactive-color;
}

.badge--recommended {
    background: $badge-recommended-background;
    color: $badge-recommended-color;
}

.badge--selected {
    background: $badge-selected-background;
    color: $badge-selected-color;
}

.badge--success {
    background: $badge-success-background;
    color: $badge-success-color;
}

.badge__icon {
    width: 16px;
    height: 16px;
    margin-left: 9px;
    vertical-align: top;

    &:only-child {
        margin: 0;
    }

    .badge--large & {
        width: $badge-size-large-icon-width;
        height: $badge-size-large-icon-height;
    }

    @media (max-width: ($break-sm - 1)) {
        .badge--large.badge--large-responsive & {
            width: 16px;
            height: 16px;
        }
    }

    .badge--small & {
        width: $badge-icon-size-small;
        height: $badge-icon-size-small;
        margin: $badge-icon-margin-small;
    }
}

.badge__text {
    margin-left: 6px;
    margin-right: 9px;
    vertical-align: top;
    text-transform: $badge-text-transform;

    &:only-child {
        margin: 0 9px;
    }

    .badge--small & {
        margin: $badge-text-spacing-small;
    }

    .badge--large & {
        &:only-child {
            margin: 0;
        }
    }
}
