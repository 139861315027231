@use "sass:math";
@use "sass:map";
@use "sass:list";

@mixin reset-button {
    appearance: none;
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    width: auto;
    overflow: visible;
    color: inherit;
    font: inherit;
    line-height: normal;
    text-align: inherit;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
}

@mixin button-variant($color, $background, $background-h, $border, $border-h, $color-h: null, $box-shadow-h: null, $pressed: null, $pressed-background: null) {
    color: $color;
    background-color: $background;
    border-color: $border;

    html[data-whatintent="mouse"] .card--link:not(.click-area):hover &,
    html[data-whatintent="mouse"] .click-area:hover &.click-area__trigger,
    html[data-whatintent="mouse"] &:hover,
    &.is-active {
        background-color: $background-h;
        border-color: $border-h;

        @if $color-h {
            color: $color-h;
        } @else {
            color: $color;
        }

        @if $box-shadow-h {
            box-shadow: $box-shadow-h;
        }
    }

    html[data-whatintent="mouse"] &:active {
        color: $pressed;
        background-color: $pressed-background;
        border-color: $pressed-background;
        transition-duration: 0ms;
    }

    html[data-whatintent="mouse"] &:focus {
        transition-duration: 0ms;
    }

    &.is-loading {
        @if $theme == 'super' {
            background-color: rgba($background, $btn-loading-background-opacity);
        } @else {
            background-color: $background;
        }

        opacity: 1;
    }

    html[data-whatintent="mouse"] &.is-loading:hover,
    html[data-whatintent="mouse"] &.is-loading:active {
        @if $theme == 'super' {
            background-color: rgba($background, $btn-loading-background-opacity);
        } @else {
            background-color: $background;
        }

        color: $color;
        border-color: $border;
        opacity: 1;
    }

    &[aria-disabled="true"] {
        cursor: inherit;
        color: $btn-disabled-color;
        background-color: $btn-disabled-background-color;
        border: $btn-disabled-border-color;

        html[data-whatintent="mouse"] &:hover,
        html[data-whatintent="mouse"] &:active {
            color: $btn-disabled-color;
            background-color: $btn-disabled-background-color;
            border: $btn-disabled-border-color;
        }
    }
}

@mixin button-link-variant($color, $color-h, $pressed: null) {
    background-color: transparent;
    color: $color;

    html[data-whatintent="mouse"] .card--link:not(.click-area):hover &,
    html[data-whatintent="mouse"] .click-area:hover &.click-area__trigger,
    html[data-whatintent="mouse"] &:hover,
    &.is-active {
        background-color: transparent;
        color: $color-h;
    }

    html[data-whatintent="mouse"] &:active {
        color: $pressed;
        background-color: transparent;
    }

    &.is-loading .btn__spinner {
        color: $color;
    }

    &[aria-disabled="true"],
    &.is-loading {
        color: $btn-disabled-link-color;
        background-color: transparent;
        pointer-events: $btn-link-disabled-pointer-events;

        html[data-whatintent="mouse"] &:hover,
        html[data-whatintent="mouse"] &:active {
            color: $btn-disabled-link-color;
            background-color: transparent;
        }
    }
}

@mixin button-hover-variant($color, $background, $background-h, $border-h, $color-h: null, $border: null, $pressed: null, $pressed-background: null) {
    @if $theme != "diil" and $theme != "super" {
        &:not([aria-disabled="true"], .is-loading, .btn--link) {
            color: $color;
            background-color: $background;
            border-color: $border;
            position: relative;
            z-index: 1;

            html[data-whatintent="mouse"] .card--link:not(.click-area):hover &,
            html[data-whatintent="mouse"] .click-area:hover &.click-area__trigger,
            html[data-whatintent="mouse"] &:hover {
                color: $color-h;
                background-color: $background;
                border-color: $border-h;
            }

            &.is-active {
                color: $color;
                background-color: $background;
                border-color: $border-h;
            }

            &::before {
                content: "";
                position: absolute;
                top: $btn-border-width * -1;
                right: $btn-border-width * -1;
                bottom: $btn-border-width * -1;
                left: $btn-border-width * -1;
                z-index: -1;
                background: $background-h;
                border-radius: 100%;
                border-style: solid;
                border-width: $btn-border-width;
                border-color: $border-h;
                transform: scale(0);
                transition: transform 150ms;
                transition-timing-function: cubic-bezier(.45, .95, .33, .81);
            }

            &:hover::before {
                html[data-whatintent="mouse"] & {
                    @include button-hover-before();
                }
            }

            html[data-whatintent="mouse"] &:active::before {
                color: $pressed;
                background-color: $pressed-background;
                border-color: $pressed-background;
            }
        }
    }
}

@mixin visually-hidden {
    /* https://developer.yahoo.com/blogs/ydn/clip-hidden-content-better-accessibility-53456.html */
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
}

@mixin keyboard-focus {
    outline: 2px solid var(--color-focus-outline, #0094ff);
    outline-offset: 2px;
}

@mixin img-responsive($display: block) {
    display: $display;
    max-width: 100%; // Part 1: Set a maximum relative to the parent
    height: auto; // Part 2: Scale the height according to the width, otherwise you get stretching
}

@mixin reset-icon {
    vertical-align: baseline;
    margin-top: 0;
}

@mixin margin-vertical($margin) {
    margin-top: $margin;
    margin-bottom: $margin;
}

@mixin clear-fixer() {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin list-unstyled {
    padding-left: 0;
    list-style: none;
}

@mixin button-hover-before {
    transform: scale(1);
    transition-timing-function: cubic-bezier(.22, .61, .35, 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: math.unit($min-vw);
    $u2: math.unit($max-vw);
    $u3: math.unit($min-font-size);
    $u4: math.unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
    }
}

@function strip-unit($value) {
    @return math.div($value, $value * 0 + 1);
}

@mixin animation($configs...) {
    $animations: ();
    $predefined-delays: 'short', 'medium', 'long', 'extra-long';

    @each $config in $configs {
        $animation: map.get($config, 'animation');
        $name: map.get($config, 'name');
        $delay: map.get($config, 'delay');
        $iteration-count: map.get($config, 'iteration-count');
        $direction: map.get($config, 'direction');

        $duration: var(--animation-duration-#{$animation});
        $easing: var(--animation-easing-#{$animation});

        @if index($predefined-delays, $delay) {
            $delay: var(--animation-duration-#{$delay});
        } @else {
            $delay: $delay;
        }

        $animation-css: #{$name} #{$duration} #{$easing} #{$delay} #{$iteration-count} #{$direction};
        $animations: list.append($animations, $animation-css, comma);
    }

    animation: $animations;
}

@mixin transition($configs...) {
    $transitions: ();
    $predefined-delays: 'short', 'medium', 'long', 'extra-long';

    @each $config in $configs {
        $animation: map.get($config, 'animation');
        $properties: map.get($config, 'properties');
        $delay: map.get($config, 'delay', null);
        $duration: var(--animation-duration-#{$animation});
        $easing: var(--animation-easing-#{$animation});

        @if index($predefined-delays, $delay) {
            $delay: var(--animation-duration-#{$delay});
        } @else {
            $delay: $delay;
        }

        @each $property in $properties {
            $transition-css: #{$property} #{$duration} #{$easing} #{$delay};
            $transitions: list.append($transitions, $transition-css, comma);
        }
    }

    @if length($transitions) > 0 {
        transition: $transitions;
    }
}
