@import "~@core-front/sass-tokens/index.scss";

.chart-pie {
    display: flex;
}

.chart-pie--align-center {
    justify-content: center;
}

.chart-pie--align-right {
    justify-content: flex-end;
}

.chart-pie__inner {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.chart-pie__title {
    margin-bottom: 1rem;
    text-align: center;
}

.chart-pie__title-text {
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
}

.chart-pie__main {
    display: block;
    overflow: hidden;

    .chart-pie--legend-aside & {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
    }
}

.chart-pie__graph-container {
    position: relative;
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;
}

/* Graph */

.chart-pie__graph-svg {
    position: relative;
    display: block;
}

.chart-pie__unused {
    fill: $graph-unused-color;
}

.chart-pie__graph-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    text-align: center;
}

/* Legend */

.chart-pie__legend {
    margin: 1rem auto 0;

    .chart-pie--legend-aside & {
        margin: 1rem auto;
    }
}

/* Tooltip */

.chart-pie__tooltip-title {
    font-size: 16px;
    margin-bottom: .5em;
}

.chart-pie__tooltip-list {
    padding: 0;
    list-style: none;
}

.chart-pie__tooltip-list-item {
    display: flex;
    align-items: baseline;
}

.chart-pie__tooltip-color {
    flex: 0 0 10px;
    width: 10px;
    height: 10px;
    margin-right: 8px;
    border-radius: 50%;
}
