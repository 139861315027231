@import "~@core-front/sass-tokens/index.scss";

.text {
    width: 100%;

    > * + * {
        margin-top: 1em;
    }

    > h1,
    > .h1 {
        margin-bottom: $text-h1-margin-bottom;
    }

    > :last-child {
        margin-bottom: 0;
    }

    > :first-child {
        margin-top: 0;
    }

    > .grid.grid--gutter-default:not(.grid--vertical-gutter-none, .grid--gutter-none) {
        margin-bottom: $grid-gutter * -1;

        @media (max-width: ($break-sm - 1)) {
            margin-bottom: $grid-gutter-xs * -1;
        }
    }

    > .grid.grid--gutter-small:not(.grid--vertical-gutter-none, .grid--gutter-none) {
        margin-bottom: $grid-gutter-small * -1;
    }

    > .text__margin {
        margin-top: 1em;
    }

    > .text__margin-none {
        margin-top: 0;
    }

    > .text__margin-double {
        margin-top: 2em;
    }

    > .text__margin-half {
        margin-top: .5em;
    }
}

.text--double > * + * {
    margin-top: 2em;
}

.text--half > * + * {
    margin-top: .5em;
}

.text--pre-wrap {
    white-space: pre-wrap;
}
