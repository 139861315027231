@import "~@telia-front/sass-tokens/index.scss";

$textarea-rows-padding: 10px;
$textarea-rows-line-nr-width: 50px;
$textarea-rows-line-nr-wide-width: $textarea-rows-line-nr-width + 14px;

.textarea-rows__label {
    color: $black;
    font-size: $textfield-label-size;

    .textarea-rows.is-disabled & {
        opacity: .5;
    }

    .textarea-rows--no-label-no-break & {
        display: none;
    }

    .textarea-rows--no-label-sm & {
        @media (min-width: $break-sm) {
            display: none;
        }
    }

    .textarea-rows--no-label-md & {
        @media (min-width: $break-md) {
            display: none;
        }
    }
}

.textarea-rows__form-helper {
    margin-top: $textfield-form-helper-margin-top;

    &.form-helper--help {
        color: $grey-d;
    }
}

.textarea-rows__main {
    position: relative;
    padding: $textarea-rows-padding;
    border-radius: 3px;
    border: 1px solid $grey-d;
    overflow: hidden;
    background-color: $white;
    transition: border-color 100ms ease-out;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        display: block;
        width: $textarea-rows-line-nr-width;
        background: $grey-l;
    }

    .textarea-rows.is-focused & {
        border-color: $black;
    }

    .textarea-rows.is-invalid & {
        border-color: $color-error-1;
    }

    .textarea-rows.is-disabled & {
        opacity: .5;
    }
}

.textarea-rows__inner {
    position: relative;
}

.textarea-rows__mirror,
.textarea-rows__input {
    position: relative;
    width: 100%;
    padding: 0;
    font-size: $base-font-size-xl;
    font-family: $font-primary;
    font-weight: $font-weight-base;
    line-height: $base-line-height;
}

.textarea-rows__mirror {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}

.textarea-rows__input {
    padding-left: $textarea-rows-line-nr-width;
    border: 0;
    outline: none;
    overflow: hidden;
    background: transparent;
    resize: none;
}

.textarea-rows__item {
    position: relative;
    display: flex;
    word-wrap: break-word;
    overflow-wrap: break-word;
    margin: 0 (-$textarea-rows-padding);
    padding: 0 $textarea-rows-padding 0 0;
    max-width: calc(100% + #{($textarea-rows-padding * 2)});
}

.textarea-rows__item--error {
    background: rgba($red-l, .1);
}

.textarea-rows__item--valid {
    background: rgba($green-l, .1);
}

.textarea-rows__item-nr {
    width: $textarea-rows-line-nr-width;
    padding-right: $textarea-rows-padding;
    color: #8a8a8a;
    text-align: right;
    flex-shrink: 0;
}

.textarea-rows__item-text {
    flex: 1 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: $textarea-rows-padding;
    color: transparent;
    max-width: calc(100% - #{$textarea-rows-line-nr-width});
}

.textarea-rows__item-helper {
    position: relative;
    line-height: calc($base-font-size-xl * $base-line-height);

    .form-helper__icon {
        margin-top: 3px;
    }
}

.textarea-rows__value {
    max-width: 100%;
    flex: 0 0 auto;
}

.textarea-rows.is-wide {
    .textarea-rows__main::before {
        width: $textarea-rows-line-nr-wide-width;
    }

    .textarea-rows__input {
        padding-left: $textarea-rows-line-nr-wide-width;
    }

    .textarea-rows__item-nr {
        width: $textarea-rows-line-nr-wide-width;
    }

    .textarea-rows__item-text {
        max-width: calc(100% - #{$textarea-rows-line-nr-wide-width});
    }
}
