@use "sass:color";
@use "sass:map";

.header__overlay-notices-wrapper {
    pointer-events: auto;
    position: absolute;
    z-index: -1;
    width: 100%;
}

.header__overlay-notice-wrapper {
    position: relative;
    transition: height 500ms cubic-bezier(.86, 0, .07, 1);
    backdrop-filter: blur(2px);

    &.header__overlay-notice-wrapper--with-background {
        background: $white;
        opacity: .95;
    }

    @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
            z-index: -1 * $i;
        }
    }
}

.header__overlay-notice {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    background: $color-information-2;
    opacity: .95;

    .header__overlay-notice-wrapper.is-entering &,
    .header__overlay-notice-wrapper.is-entered &,
    .header__overlay-notice-wrapper.is-exiting &,
    .header__overlay-notice-wrapper.is-exited & {
        position: absolute;
    }

    .header__overlay-notice-wrapper.is-entering & {
        transform: translateY(calc(-100% - 3px));
    }

    .header__overlay-notice-wrapper.is-entered & {
        transform: translateY(0);
        transition: transform 500ms cubic-bezier(.86, 0, .07, 1);
    }

    .header__overlay-notice-wrapper.is-exiting & {
        transform: translateY(0);
    }

    .header__overlay-notice-wrapper.is-exited & {
        transform: translateY(calc(-100% - 3px));
        transition: transform 500ms cubic-bezier(.86, 0, .07, 1);
    }
}

@each $name, $color in $notice-colors {
    .header__overlay-notice--#{$name} {
        background: map.get($color, "background");
        opacity: .95;
    }
}

.header__overlay-notice-notice {
    border: none;
    padding: 0;
    background: transparent;

    .notice__content {
        padding: 16px 0;
        min-height: $notice-height;
    }

    .notice__icon-cell {
        padding-left: 0;
        padding-right: 0;
        align-self: center;
    }

    &.notice .notice__icon {
        font-size: 32px;
    }
}
