@import "~@core-front/sass-tokens/index.scss";

.main {
    display: flex;
    flex-direction: column;
}

.main__inner {
    display: flex;
    flex-direction: column;
    min-height: $main-inner-min-height;
}

.main__header {
    padding-top: $main-header-padding-top;

    @media (min-width: $break-lg) {
        padding-top: $main-header-padding-top-lg;
    }
}

.main__content {
    padding: $main-content-padding;
    flex: 1 0 auto;
}

.main__page {
    flex: 1 0 auto;
}

.main__footer {
    flex-shrink: 0;
}
