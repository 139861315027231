@import "~@core-front/sass-tokens/index.scss";

@mixin select-hide-label() {
    // important is necessary to override all the possible states
    color: transparent !important;
    padding-top: 10px !important;
    animation: none !important;

    &::before {
        opacity: 1 !important;
        top: -10px !important;
        animation: none !important;
        border: none;
    }

    &::after {
        top: 0 !important;
        animation: none !important;
    }
}

.select {
    position: relative;
    font-size: 16px;
    width: 100%;
    max-width: 100%;

    &.is-disabled {
        color: $select-disabled-color;
    }

    .table & {
        min-width: 180px;
    }
}

.select--small {
    .table & {
        min-width: 140px;
    }
}

.select__inner {
    position: relative;
    padding: 17px 0 0;

    .select--employee & {
        &::before {
            content: "";
            display: block;
            background: $select-employee-background-color;
            position: absolute;
            top: -5px;
            left: -5px;
            right: -10px;
            bottom: -5px;
            border-radius: 3px;
            z-index: -1;
        }
    }

    .select--small & {
        padding-top: 10px;

        &::after {
            left: 5px;
            height: 3px;
        }
    }

    &::after {
        @include transition(
            ('animation': short, 'properties': border-color),
            ('animation': short, 'properties': opacity, 'delay': short),
        );

        content: "";
        display: block;
        position: absolute;
        top: 7px;
        left: 10px;
        right: 0;
        height: 10px;
        border: 1px solid $select-secondary-color;
        border-top-right-radius: 3px;
        border-bottom: none;
        border-left: none;
        opacity: 0;
    }

    .select:focus-within &::after {
        border-color: $select-focus-color;
    }

    .select--no-label-no-break & {
        padding-top: 10px;

        &::after {
            // important is necessary to override all the possible states
            opacity: 1 !important;
            top: 0;
        }
    }

    .select--no-label-no-break.select--small & {
        padding-top: 3px;

        &::after {
            // important is necessary to override all the possible states
            opacity: 1 !important;
            top: 0;
        }
    }

    .select--no-label-sm & {
        @media (min-width: $break-sm) {
            padding-top: 10px;

            &::after {
                // important is necessary to override all the possible states
                opacity: 1 !important;
                top: 0;
            }
        }
    }

    .select--no-label-sm.select--small & {
        @media (min-width: $break-sm) {
            padding-top: 3px;

            &::after {
                // important is necessary to override all the possible states
                opacity: 1 !important;
                top: 0;
            }
        }
    }

    .select--no-label-md & {
        @media (min-width: $break-md) {
            padding-top: 10px;

            &::after {
                // important is necessary to override all the possible states
                opacity: 1 !important;
                top: 0;
            }
        }
    }

    .select--no-label-md.select--small & {
        @media (min-width: $break-md) {
            padding-top: 3px;

            &::after {
                // important is necessary to override all the possible states
                opacity: 1 !important;
                top: 0;
            }
        }
    }

    @if $theme == "super" {
        padding: 0;

        .select--small &,
        .select--no-label-no-break &,
        .select--no-label-sm &,
        .select--no-label-md & {
            padding: 0;
        }

        &::after {
            display: none;
        }
    }
}

.select__wrapper {
    @include transition(('animation': short, 'properties': border-color));

    display: block;
    border: 1px solid $select-secondary-color;
    border-top: 0;
    border-radius: 0 0 3px 3px;
    box-shadow: none;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    height: 32px;
    width: 100%;
    background: none;
    text-align: left;
    color: inherit;

    .select.is-open &,
    .select.is-open.is-invalid & {
        outline: none;
        border-color: $select-focus-color;
    }

    .select.is-invalid & {
        border-color: $select-error-border-color;
    }

    &:focus-within {
        border-color: $select-focus-color;

        .select.is-invalid & {
            border-color: $select-focus-color;
        }
    }

    .select.is-disabled & {
        background-color: transparent;
        color: $select-disabled-color;
        -webkit-text-fill-color: $select-disabled-color;
        outline: none;
        opacity: $select-disabled-opacity;
        border-color: $select-disabled-border-color;
    }

    .select--no-border.is-open &,
    .select--no-border & {
        border-color: transparent;
    }

    &::-ms-clear {
        display: none;
    }

    &:focus {
        outline: none;
    }

    .select--small & {
        height: 29px;
        font-size: 14px;
    }

    @if $theme == "super" {
        background-color: $select-primary-color;
        border: 2px solid $select-primary-color;
        border-radius: 3px;
        height: 50px;

        .select.is-disabled & {
            background-color: $select-primary-color;
        }

        .select--employee & {
            background-color: #3a2c39;
            border-color: #3a2c39;
        }

        .select--small & {
            height: 27px;
        }
    }
}

.select__label {
    @include transition(('animation': short, 'properties': color));

    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: $select-secondary-text-color;
    font-size: 12px;
    padding: 0 10px 0 20px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    pointer-events: none;

    &::after {
        @include transition(('animation': short, 'properties': border-color));

        content: "";
        display: block;
        position: absolute;
        top: 7px;
        left: 0;
        width: 10px;
        height: 10px;
        border-radius: 3px 0 0;
        border: 1px solid $select-secondary-color;
        border-bottom: 0;
        border-right: 0;
    }

    &::before {
        @include transition(('animation': short, 'properties': border-color));

        content: "";
        display: block;
        position: relative;
        top: 7px;
        left: 10px;
        right: 0;
        height: 10px;
        border-radius: 0 3px 0 0;
        border: 1px solid $select-secondary-color;
        border-bottom: 0;
        border-left: 0;
        flex: 1 0 auto;
    }

    .select.is-open & {
        color: $select-primary-color;
        padding-top: 0;
    }

    .select:focus-within &,
    .select.is-invalid:focus-within & {
        color: $select-focus-label-color;

        &::before,
        &::after {
            border-color: $select-focus-color;
        }
    }

    .select.is-invalid & {
        &::before,
        &::after {
            border-color: $select-error-border-color;
        }
    }

    .select.is-disabled & {
        color: $select-disabled-color;
        opacity: $select-disabled-opacity;
        
        &::before,
        &::after {
            border-color: $select-disabled-border-color;
        }
    }

    .select--no-border:focus-within &,
    .select.select--no-border.is-open &,
    .select--no-border & {
        &::before,
        &::after {
            border-color: transparent;
        }
    }

    .select--small & {
        padding-left: 10px;
        font-size: 10px;

        &::before {
            top: 7px;
            height: 3px;
        }

        &::after {
            width: 5px;
            height: 3px;
            top: 7px;
        }
    }

    .select--no-label-no-break & {
        @include select-hide-label();
    }

    .select--no-label-sm & {
        @media (min-width: $break-sm) {
            @include select-hide-label();
        }
    }

    .select--no-label-md & {
        @media (min-width: $break-md) {
            @include select-hide-label();
        }
    }

    @if $theme == "super" {
        font-size: 18px;
        background-color: $select-primary-color;
        color: $grey-m;
        width: auto;
        right: auto;
        top: 50%;
        bottom: 2px;
        left: 2px;
        padding-left: 11px;
        transform: translateY(-50%);
        font-weight: 300;

        .select.is-open & {
            color: $grey-m;
        }

        .select.select--employee & {
            background-color: #3a2c39;
        }

        .select.is-disabled & {
            background-color: #1d1d1d;
        }

        .select--small & {
            bottom: auto;
        }

        .select--no-label-no-break & {
            background-color: transparent;
        }

        .select--no-label-sm & {
            @media (min-width: $break-sm) {
                background-color: transparent;
            }
        }

        .select--no-label-md & {
            @media (min-width: $break-md) {
                background-color: transparent;
            }
        }

        &::before,
        &::after {
            display: none;
        }
    }
}

.select__icon {
    @include reset-icon();
    @include transition(('animation': medium, 'properties': transform));

    position: absolute;
    top: $select-icon-top-position;
    color: $select-icon-color;
    right: 9px;
    font-size: $select-icon-size;
    height: $select-icon-size;
    pointer-events: none;

    .select.is-open & {
        transform: rotate(180deg);
    }

    .select--small & {
        font-size: $select-icon-size-small;
        height: $select-icon-size-small;
        right: 5px;
        top: $select-small-icon-top-position;
    }
}

.select__icon-search {
    @include reset-icon();

    position: absolute;
    font-size: $select-icon-size;
    top: 9px;
    left: 16px;

    .select--small & {
        font-size: $select-icon-size-small;
        top: 14px;
        left: 10px;
    }
}

.select__control {
    position: relative;
    width: 100%;
    height: 42px;
    top: -10px;

    .select--small & {
        height: 32px;
    }

    @if $theme == "super" {
        top: 0;
        height: 50px;

        .select--small & {
            height: 19px;
        }
    }
}

.select__placeholder {
    position: absolute;
}

.select__menu {
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: -1px;
    background-color: $select-menu-color;
    box-shadow: $select-box-shaddow;
    border-radius: 3px;
    max-height: 400px;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    font-size: 16px;
}

.select-menu-outer--small {
    font-size: 14px;
}

.select__menu-list {
    max-height: 400px;
    overflow-y: auto;
    border-radius: 3px;

    @if $theme == "super" {
        border-radius: 3px;
        text-align: $select-value-align;
    }
}

.select__option {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 9px 21px;
    cursor: pointer;

    &:not(:first-child)::after {
        content: "";
        position: absolute;
        top: 0;
        left: 10px;
        right: 10px;
        height: 1px;
        background: $select-option-border-color;
    }

    @if $theme == "super" {
        font-weight: 700;
    }

    .select-menu-outer--small & {
        padding: 7px 11px;
    }
}

.select__option--is-selected {
    color: $select-option-selected-color;
    font-weight: $select-option-selected-font-weight;

    .select-menu-outer--employee & {
        color: $select-employee-selected-color;
    }

    .check__content {
        font-weight: $select-option-selected-font-weight;
    }
}

.select__option--is-focused {
    color: $select-option-focused-color;
    background-color: $select-option-focused-background-color;

    .select-menu-outer--employee & {
        color: $select-employee-focused-color;
    }

    &::after {
        display: none;
    }

    + .select__option::after {
        display: none;
    }
    

    @if $theme == "super" {
        color: $select-option-selected-color;

        &::after {
            display: block;
        }

        + .select__option::after {
            display: block;
        }
    }
}

.select__option--employee {
    color: $select-option-employee-color;

    &.select__option--is-focused {
        color: $select-option-employee-focused-color;
    }
}

.select__option--is-disabled {
    color: $select-disabled-color;
    cursor: default;
}

.select__form-helper {
    margin-top: 5px;

    &.form-helper--help {
        color: $select-secondary-text-color;
    }
}

.select__value-container {
    display: block;
    padding: 0 35px 0 20px;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: $select-value-align;
    height: calc(100% - 10px);

    .select.is-valid & {
        padding-right: 40px;
    }

    .select--searchable & {
        display: flex;
        flex: 1;
        padding-left: 0;
        padding-right: 0;
        margin-left: 45px;
        max-width: calc(100% - 2 * ($select-icon-size + 16px));
    }

    .select--small.select--searchable & {
        padding-left: 30px;
    }

    .select--small & {
        padding: 3px 30px 0 10px;
        margin-left: 0;
        max-width: 100%;
    }

    & > input {
        @include visually-hidden();
    }

    @if $theme == "super" {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        top: 0;
        height: 100%;
        font-weight: 700;

        .select--small & {
            padding-top: 0;
            font-size: 10px;
        }
    }
}

.select__multi-value,
.select__single-value {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    max-width: 100%;
    white-space: nowrap;

    .select--searchable & {
        align-items: normal;
    }
}

.select__multi-value {
    display: inline;
    position: relative;
    flex: 0 1 auto;
    min-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;

    &:not(:first-child)::before {
        content: ",";
        margin-right: 4px;
    }
}

.select__menu-notice--no-options {
    cursor: default;
    display: block;
    padding: 8px 10px;
}

.check__inner {
    .select-menu-outer--small & {
        padding-left: 20px;
    }
}

.check__icon {
    .select-menu-outer--small & {
        font-size: 10px;
        top: 2px;
        right: 2.5px;
    }
}

.check__indicator {
    .select-menu-outer--small & {
        width: 16px;
        height: 16px;
    }
}

.check__content {
    .select-menu-outer--small & {
        font-size: 14px;
    }
}

.select__image-wrapper {
    display: inline-block;
    position: absolute;
    left: 21px;
    top: 7px;
    height: 26px;
    width: 26px;

    .select-menu-outer--small & {
        left: 10px;
        height: 16px;
        width: 16px;
        margin-top: 2px;
    }

    .select--small & {
        height: 16px;
        width: 16px;
    }

    .select__value-container & {
        position: static;
        margin-right: 10px;
    }

    .select__menu-list--is-multi & {
        left: 41px;
    }

    .select__value-container--is-multi & {
        display: none;
    }
}

.select__value-container .select__image {
    @if $theme == "super" {
        position: absolute;
        top: 10px;
        height: inherit;
        width: inherit;
    }
}

.select__image-option {
    padding-left: 36px;

    .select-menu-outer--small & {
        padding-left: 26px;
    }
}

.select__option-icon-wrapper {
    display: inline-block;
    flex: 0 0 24px;
    font-size: 24px;
    height: 24px;
    vertical-align: bottom;

    .select--small &,
    .select-menu-outer--small & {
        flex: 0 0 16px;
        font-size: 16px;
        height: 16px;
    }

    .select-menu-outer--small & {
        margin-top: 2px;
    }

    .select__value-container & {
        position: relative;
        margin-right: 10px;
        display: flex;
        align-items: center;
    }

    .select__value-container--is-multi & {
        display: none;
    }
}

.select__option-icon {
    .select__value-container & {
        @include reset-icon();

        display: block;
    }
}

.select__option-icon-label {
    display: inline-block;
    flex: 1 1;
    padding-left: 10px;

    .select__option-icon-wrapper + & {
        margin-top: 2px;
    }
}

.select__sub-label {
    flex: 1 0 100%;
    max-width: 100%;
    margin-top: 5px;
    color: $select-sub-label-color;
    font-size: .8572rem; // 12px
    line-height: 1.5;
}

.select__asterisk-wrapper {
    display: inline-block;
    align-self: flex-start;
    padding-left: 1px;
    margin-top: -9px;
    vertical-align: text-top;

    .select--small &,
    .select-menu-outer--small & {
        margin-top: -6px;
    }
}

.select__asterisk {
    font-size: 8px;
}

.select__text-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
}

.select__native-select {
    appearance: none;
    background-color: inherit;
    border: none;
}

.select__option-children {
    display: inline-block;
}
