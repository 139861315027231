@import "~@telia-front/sass-tokens/index.scss";

.image-zoom {
    display: inline-block;
    position: relative;
    max-width: 100%;
}

.image-zoom__button {
    position: absolute !important;
    left: 15px;
    bottom: 15px;
}
