@use "sass:map";

.header__dropdown {
    position: fixed;
    z-index: map.get($zindex, header-dropdown);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 85px;
    pointer-events: auto;
    white-space: normal;

    @media (min-width: $break-lg) {
        overflow: visible;
        background-color: transparent;
        width: 1224px;
        max-width: calc(100% - #{$container-padding-lg * 2});
        border-radius: 0 0 3px 3px;
        pointer-events: none;
    }

    @media (max-width: ($break-lg - 1)) {
        box-shadow: 0 1px 2px rgba($black, .1);
    }
}

.header__dropdown--small {
    @media (min-width: $break-lg) {
        width: 320px;
    }
}

.header__dropdown--medium {
    @media (min-width: $break-lg) {
        width: 600px;
    }
}

.header__dropdown-inner {
    height: 100%;
    will-change: transform, opacity;
    opacity: 0;
    transition: 100ms ease-out;
    transition-property: transform, opacity;
    transform: translateY(10px);
    background: $white;
    color: $black;

    @media (min-width: $break-lg) {
        height: 100%;
        background-color: transparent;
    }

    .header__dropdown.is-entered & {
        opacity: 1;
        transform: translateY(0);
    }
}

.header__dropdown-content-wrapper {
    position: relative;
    background: $white;
    overflow: auto;
    max-height: 100%;
    pointer-events: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;

    @media (min-width: $break-lg) {
        position: relative;
        background: $white;
        border-radius: 8px;
        box-shadow: 0 12px 24px rgba($black, .1);
        overflow: auto;
        max-height: 100%;
        pointer-events: auto;
        -webkit-overflow-scrolling: touch;
    }
}

.header__dropdown-content {
    .header__dropdown--padding-xsmall & {
        padding: 8px;
    }

    .header__dropdown--padding-small & {
        padding: 20px;
    }

    .header__dropdown--padding-medium & {
        padding: 20px;

        @media (min-width: $break-lg) {
            padding: 40px;
        }
    }
}

.header__dropdown-header {
    background-color: $white;

    .header__dropdown--padding-xsmall & {
        // set top/bottom padding so that header would "contain" close button with minimal content
        padding: 16px;
    }

    .header__dropdown--padding-small & {
        padding: 20px;
    }

    .header__dropdown--padding-medium & {
        padding: 20px;

        @media (min-width: $break-lg) {
            padding: 40px;
        }
    }
}

.header__dropdown-footer {
    background-color: $white;
    position: sticky;
    bottom: 0;

    .header__dropdown--padding-xsmall & {
        padding: 24px 8px;
    }

    .header__dropdown--padding-small & {
        padding: 20px;
    }

    .header__dropdown--padding-medium & {
        padding: 20px;

        @media (min-width: $break-lg) {
            padding: 40px;
        }
    }
}

.header__dropdown-close {
    display: none;
    background: transparent;
    border: 0;
    margin: 0;
    font-size: 24px;
    line-height: 1;
    z-index: 2;
    padding: 3px;
    color: $black;
    height: 30px;
    width: 30px;
    transition: color $transition-default;
    position: absolute;
    top: 10px;
    right: 12px;

    @media (min-width: $break-lg) {
        display: block;
    }

    &:hover {
        color: $purple;
    }

    .header__dropdown--sso & {
        display: block;
    }
}

.header__dropdown-content-top {
    background-color: $grey-m;
}
