@font-face {
    font-family: "Telia Sans";
    src: url("~@telia-front/static-assets/fonts/TeliaSans-Regular.woff2") format("woff2"), url("~@telia-front/static-assets/fonts/TeliaSans-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Telia Sans";
    src: url("~@telia-front/static-assets/fonts/TeliaSans-RegularItalic.woff2") format("woff2"), url("~@telia-front/static-assets/fonts/TeliaSans-RegularItalic.woff") format("woff");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Telia Sans";
    src: url("~@telia-front/static-assets/fonts/TeliaSans-Bold.woff2") format("woff2"), url("~@telia-front/static-assets/fonts/TeliaSans-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Telia Sans";
    src: url("~@telia-front/static-assets/fonts/TeliaSans-BoldItalic.woff2") format("woff2"), url("~@telia-front/static-assets/fonts/TeliaSans-BoldItalic.woff") format("woff");
    font-weight: 700;
    font-style: italic;
}
