@import "~@telia-front/sass-tokens/index.scss";

.process-list {
    position: relative;
    counter-reset: item;
    z-index: 0;
}

.process-list__list {
    padding-left: 0;

    .process-list.is-horizontal & {
        display: flex;
    }
}

.process-list__item {
    position: relative;
    display: block;
    list-style: none;
    padding-left: 45px;
    padding-right: 0;
    flex: 1;
    z-index: 1;

    + .process-list__item {
        margin-top: 25px;
    }

    .process-list.is-horizontal & {
        padding-left: 10px;
        padding-right: 10px;

        + .process-list__item {
            margin-top: 0;
        }
    }
}

.process-list__item-inner {
    .process-list.is-horizontal & {
        padding: 0 10px;
    }
}

.process-list__item-header-inner {
    opacity: 0;
    transform: translateY(8px);
    transition: all 500ms cubic-bezier(.64, .04, .35, 1);
    transition-property: opacity, transform;
    max-width: 100%;

    .process-list.is-vertical & {
        transform: translate(-8px, 0);
    }

    html.no-js &,
    .process-list.on-screen & {
        transform: translate(0, 0);
        opacity: 1;
    }
}

.process-list__item-header,
.process-list__item-header-inner {
    display: flex;
    flex-direction: column;

    .process-list.is-horizontal & {
        align-items: center;
    }
}

.process-list__item-title-wrapper {
    max-width: 100%;
}

.process-list__item-title {
    margin-bottom: 10px;

    .process-list.is-horizontal & {
        text-align: center;
        margin-bottom: 16px;
    }
}

.process-list__badge-wrapper {
    position: absolute;
    margin-top: 0;
    margin-bottom: 14px;
    left: 0;
    top: 0;
    transform: scale(0);
    transition: transform 500ms cubic-bezier(.64, .04, .35, 1);

    .process-list.is-horizontal & {
        position: static;
        display: block;
        top: auto;
        left: auto;
    }

    .process-list--icon & {
        top: 49px;

        @media (max-width: ($break-sm - 1)) {
            top: 54px;
        }
    }

    .process-list--icon.process-list.is-horizontal & {
        top: auto;
    }

    .process-list--image .process-list__item--with-image & {
        top: 210px;
    }

    .process-list--image.process-list.is-horizontal & {
        top: auto;
    }

    html.no-js .process-list &,
    .process-list.on-screen & {
        transform: scale(1);
    }
}

.process-list__icon {
    @include reset-icon();

    width: 40px;
    height: 40px;
    margin-bottom: 9px;

    .process-list.is-horizontal & {
        width: 48px;
        height: 48px;
    }
}

.process-list__image {
    max-height: 200px;
    margin-bottom: 12px;

    .process-list.is-horizontal & {
        margin-bottom: 22px;
    }
}

.process-list__content {
    opacity: 0;
    transition: opacity 500ms cubic-bezier(.64, .04, .35, 1);

    html.no-js &,
    .process-list.on-screen & {
        opacity: 1;
    }

    .process-list.is-horizontal & {
        text-align: center;
    }
}

.process-list__progress-line {
    position: absolute;
    height: 2px;
    width: 0;
    transition: width 2100ms;
    background: $grey;

    .process-list.is-vertical & {
        width: 2px;
        height: 0;
        transition-property: height;
    }
}

.process-list__progress-line--animated {
    transition: none;

    .process-list.is-vertical & {
        transition: none;
    }
}
