@use "sass:color";
@use "sass:map";

@import "~@core-front/sass-tokens/index.scss";

.notice {
    position: relative;
    display: flex;
    border: $notice-border-size solid;
    border-radius: $notice-border-radius;
    padding: 15px;
    gap: $notice-gap-sm;

    @media (min-width: $break-sm) {
        padding: 15px 23px;
        gap: $notice-gap;

        &.notice--small {
            gap: $notice-gap-sm;
        }
    }

    &.notice--small,
    &.notice--vertical {
        padding: 0;
    }
}

.notice--with-close {
    @media (min-width: $break-sm) {
        padding-right: 15px;
    }
}

@each $name, $color in $notice-colors {
    .notice--#{$name} {
        border-color: map.get($color, "border");
        background: map.get($color, "background");

        @if $theme == "tv" {
            background: transparent;
        }

        & .notice__icon {
            color: map.get($color, "icon");
        }

        &.notice--small,
        &.notice--vertical {
            background: transparent;
        }
    }
}

@if $theme != "tv" and $theme != "super" {
    .notice--success {
        border: none;
        padding: 15px;

        @media (min-width: $break-sm) {
            padding: 20px;
        }

        &.notice--small,
        &.notice--vertical {
            padding: 0;
        }

    }
}

.notice--small {
    background: transparent;
    border: none;
}

.notice--vertical {
    display: block;
    background: transparent;
    border: none;
}

.notice--center.notice--small {
    justify-content: center;
}

.notice__icon {
    @include reset-icon();

    @if $theme != "tv" and $theme != "super" {
        .notice--success:not(.notice--vertical, .notice--small) & {
            font-size: $notice-success-icon-size;

            @media (min-width: $break-sm) {
                font-size: $notice-success-icon-size-sm;
            }
        }
    }

    .notice--small & {
        margin-top: 0;
    }
}

.notice__inner-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.notice__inner {
    width: 100%;
}

.notice__icon-cell {
    padding: 0;
    display: flex;
    align-self: flex-start;
    line-height: 1;

    .notice--vertical & {
        justify-content: center;
    }

    .notice--center & {
        align-self: center;
    }
}

.notice__content {
    display: flex;
    width: 100%;
    align-self: center;
    text-align: left;

    .notice--vertical & {
        text-align: center;
        padding: 16px 0 0;

        @media (min-width: $break-sm) {
            padding-top: 24px;
        }
    }

    .notice--vertical.notice--small & {
        padding-top: 8px;
    }

    .notice--center.notice--small & {
        width: auto;
    }
}

.notice__close {
    padding: 0 0 0 $notice-close-padding-left-sm;
    align-self: flex-start;

    @media (min-width: $break-sm) {
        padding-left: $notice-close-padding-left;
    }

    .notice--center & {
        align-self: center;
    }
}
