@import "~@core-front/sass-tokens/index.scss";

.text-edit {
    display: flex;
    align-items: center;
}

.text-edit__content {
    max-width: calc(100% - #{$icon-font-size-xs + 10px});
}

.text-edit__button {
    margin-left: 10px;
}
