@import "~@telia-front/sass-tokens/index.scss";

.internet-speed-indicator {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.internet-speed-indicator__content {
    margin-top: 10%;
}

.internet-speed-indicator__graph-line,
.internet-speed-indicator__graph {
    position: absolute;
    width: auto;
    height: auto;
}

.internet-speed-indicator--size-small {
    width: 160px;
    height: 117px;
}

.internet-speed-indicator--size-medium {
    width: 192px;
    height: 139px;
}

.internet-speed-indicator--size-responsive {
    width: 100%;
    height: 0;
    padding-bottom: 72%; /* Creates a svg aspect ratio */

    .internet-speed-indicator__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        margin-top: 5%;
        width: 80%;
    }

    .internet-speed-indicator__graph-line,
    .internet-speed-indicator__graph {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

$percentage: 2.5;
$iterations: 40;

@function custom-round($number) {
    @return round($number);
}

@for $i from 1 through $iterations {
    $indicator-value: custom-round(calc(($percentage / 100) * 370 * $i));

    @keyframes internet-speed-indicator__path-#{$indicator-value} {
        from {
            stroke-dashoffset: $indicator-value;
        }

        to {
            stroke-dashoffset: 0;
        }
    }
}
