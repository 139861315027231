@import "~@telia-front/sass-tokens/index.scss";

@import "~@core-front/grid/styles.scss";

@include grid-gutter(medium, $grid-gutter-medium, $grid-gutter-medium-xs);
@include grid-gutter(smedium, $grid-gutter-smedium);

@media only screen and (min-width: $break-xxl) {
    @include make-grid(xxl);
}

