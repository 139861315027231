@use "sass:map";

@import "~@telia-front/sass-tokens/index.scss";

.filter {
    z-index: map.get($zindex, filter-sidemenu);
    background-color: $body-bg;
    display: none;
    overflow: auto;

    @media (min-width: $break-md) {
        display: block;
        position: static;
        overflow: visible;
        background-color: transparent;
    }

    &.is-open {
        display: initial;
        justify-content: space-between;
        flex-direction: column;
        z-index: map.get($zindex, filter-sidemenu-open);
    }
}

.filter__main {
    padding: 5px;

    @media (min-width: $break-md) {
        padding: 0 0 5px;
    }
}

.filter__heading {
    margin-bottom: 20px;
}

.filter__footer {
    text-align: center;
    padding: 15px 15px 0;
    background: none;
}

.filter__save {
    margin-bottom: 16px;

    @media (min-width: $break-md) {
        display: none;
    }
}

.filter__close-icon {
    @include reset-icon();

    font-size: 24px;
    vertical-align: top;
}

.filter__section:first-child:not(.filter__section--header) {
    border-top: 1px solid $grey;
}

.filter__section {
    border-bottom: 1px solid $grey;
}

.filter__header {
    display: block;
    position: relative;
    z-index: 0;
    padding: 15px 32px 15px 0;
    color: $black;
    overflow-wrap: break-word;
    hyphens: auto;

    .filter__section.is-open &,
    html[data-whatintent="mouse"] &:hover {
        color: $color-primary-1;
    }

    .filter__section--employee.is-open &,
    html[data-whatintent="mouse"] .filter__section--employee &:hover {
        color: $color-employee-1;
    }
}

.filter__header-anchor {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.filter__header-anchor-content {
    @include visually-hidden();
}

.filter__header-arrow {
    font-size: 24px;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -12px;

    .filter__section.is-open & {
        transform: rotate(180deg);
    }
}

.filter__tooltip-toggle {
    position: relative;
    z-index: 2;
    margin-left: 5px;
}

.filter__title {
    font-weight: 700;
}

.filter__body {
    transition: height 300ms $transition-timing-function;
}

.filter__content {
    padding-bottom: 20px;
}
