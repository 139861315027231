@use "sass:math";

@import "~@core-front/sass-tokens/index.scss";

@mixin card-old-border-radius() {
    .card-old > &:first-child {
        border-top-left-radius: $card-old-border-radius;
        border-top-right-radius: $card-old-border-radius;
    }

    .card-old > &:last-child {
        border-bottom-left-radius: $card-old-border-radius;
        border-bottom-right-radius: $card-old-border-radius;
    }

    .card-old--border > &:first-child,
    .card-old--selected > &:first-child,
    .card-old--attention > &:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .card-old--border > &:last-child,
    .card-old--selected > &:last-child,
    .card-old--attention > &:last-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .card-old--shape-option > &:first-child {
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
    }

    .card-old--shape-option > &:last-child {
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
    }

    .card-old--border.card-old--shape-option > &:first-child,
    .card-old--selected.card-old--shape-option > &:first-child,
    .card-old--attention.card-old--shape-option > &:first-child,
    .card-old--badge-top.card-old--shape-option > .card-old__badge-wrapper ~ &:nth-child(2),
    .card-old--badge-bottom.card-old--shape-option > .card-old__badge-wrapper ~ &:nth-child(2),
    .card-old--badge-top.card-old--badge-bottom.card-old--shape-option > .card-old__badge-wrapper ~ &:nth-child(3) {
        border-top-left-radius: 12px - $card-old-border-width;
        border-top-right-radius: 12px - $card-old-border-width;
    }

    .card-old--border.card-old--shape-option > &:last-child,
    .card-old--selected.card-old--shape-option > &:last-child,
    .card-old--attention.card-old--shape-option > &:last-child {
        border-bottom-left-radius: 12px - $card-old-border-width;
        border-bottom-right-radius: 12px - $card-old-border-width;
    }
}

.card-old {
    position: relative;
    width: 100%;
    box-shadow: 0 1px 1px rgba($black, .15);
    border-radius: $card-old-border-radius;
    display: flex;
    flex-direction: column;
    color: $text-color-base;
    z-index: 0;

    &.card-old--shape-option {
        border-radius: $card-old-shape-option-border-radius;
    }
}

.card-old--disabled {
    opacity: $card-old-disabled-opacity;
    pointer-events: none;
}

.card-old__top {
    flex: 1 0 auto;
}

.card-old__bottom {
    margin-top: 30px;
}

.card-old__content {
    padding: $base-horizontal-spacing-xs;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 100%;

    @media (min-width: $break-sm) {
        padding: $base-horizontal-spacing;
    }

    @include card-old-border-radius();
}

.card-old__footer {
    display: block;
    position: relative;
    border-top: 0;
    padding: $base-horizontal-spacing-xs;

    @media (min-width: $break-sm) {
        padding: $base-horizontal-spacing;
    }

    @include card-old-border-radius();
}

.card-old__header {
    display: block;
    position: relative;
    border-bottom: 0;
    padding: $base-horizontal-spacing-xs;

    @media (min-width: $break-sm) {
        padding: $base-horizontal-spacing;
    }

    @include card-old-border-radius();
}

.card-old--link {
    color: $text-color-base;
    font-weight: inherit;
    transform: translateY(0);
    will-change: transform, box-shadow;
    transition: $transition-duration ease-in;
    transition-property: transform, box-shadow;

    html[data-whatintent="mouse"] &:hover {
        color: $text-color-base;
    }

    html[data-whatintent="mouse"] &:not(.card-old--invisible):hover {
        transition-timing-function: ease-out;
        transform: translateY(-8px);
        box-shadow: 0 3px 6px rgba($black, .2);
    }
}
