@import "~@telia-front/sass-tokens/index.scss";
@import "~@core-front/carousel/styles.scss";

.carousel {
    &.carousel--without-slick,
    &.carousel--without-slick.carousel--with-arrows,
    &.carousel--without-slick.carousel--with-arrows.carousel--with-center-arrows {
        &.carousel--sales-argument {
            padding-top: 14px;
            padding-bottom: 14px;
        }
    }
}

.carousel__slider {
    .carousel--with-arrows & {
        padding-bottom: $btn-min-height;
    }

    .carousel--with-small-arrows & {
        padding-bottom: $btn-small-min-height;
    }

    .carousel--with-link-arrows.carousel--with-small-arrows & {
        padding-bottom: 16px;
    }

    .carousel--with-link-arrows & {
        padding-bottom: 24px;
    }

    .slick-slide {
        .carousel--sales-argument & {
            padding-top: 14px;
            padding-bottom: 14px;
        }
            
        .carousel--without-slick.carousel--sales-argument & {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    @media (min-width: $break-sm) {
        .carousel--with-arrows & {
            padding-bottom: $btn-min-height;
        }

        .carousel--with-small-arrows & {
            padding-bottom: $btn-small-min-height;
        }

        .carousel--with-link-arrows.carousel--with-small-arrows & {
            padding-bottom: 16px;
        }

        .carousel--with-link-arrows & {
            padding-bottom: 24px;
        }
    }
}

.carousel--with-center-arrows {
    .carousel__slider {
        padding-bottom: unset;
    }
}
