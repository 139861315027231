@import "~@core-front/sass-tokens/index.scss";

$scroll-fade-color: rgba(0, 0, 0, 1) !default;
$scroll-fade-transparent: transparent !default;
$scroll-fade: #{$scroll-fade-color} 40px !default;
$scroll-fade-arrow: #{$scroll-fade-transparent} 24px, #{$scroll-fade-color} 48px !default;

.scroll-fade {
    --scroll-fade-top: #{$scroll-fade-color};
    --scroll-fade-bottom: #{$scroll-fade-color};
    --scroll-fade-left: #{$scroll-fade-color};
    --scroll-fade-right: #{$scroll-fade-color};
    --scroll-fade-arrow-left: #{$scroll-fade};
    --scroll-fade-arrow-right: #{$scroll-fade};

    display: block;
    position: relative;
    max-height: inherit;

    &.has-scrolled-to-top {
        --scroll-fade-bottom: transparent;
    }

    &.has-scrolled-to-right {
        --scroll-fade-left: transparent;

        &.scroll-fade--has-arrows:not(.has-scrolled-to-top, .has-scrolled-to-bottom) {
            --scroll-fade-arrow-left: #{$scroll-fade-arrow};
        }
    }

    &.has-scrolled-to-bottom {
        --scroll-fade-top: transparent;
    }

    &.has-scrolled-to-left {
        --scroll-fade-right: transparent;

        &.scroll-fade--has-arrows:not(.has-scrolled-to-top, .has-scrolled-to-bottom) {
            --scroll-fade-arrow-right: #{$scroll-fade-arrow};
        }
    }
}

.scroll-fade__inner {
    display: block;
    max-height: inherit;
    overflow: auto;
    background-color: inherit;
    mask-image:
        linear-gradient(to top, var(--scroll-fade-bottom), #{$scroll-fade}),
        linear-gradient(to bottom, var(--scroll-fade-top), #{$scroll-fade}),
        linear-gradient(to right, var(--scroll-fade-left), var(--scroll-fade-arrow-left)),
        linear-gradient(to left, var(--scroll-fade-right), var(--scroll-fade-arrow-right));
    mask-composite: intersect;
}

.scroll-fade__button {
    position: absolute;
    z-index: 2;

    &.scroll-fade__button-left,
    &.scroll-fade__button-right {
        top: 50%;
        transform: translateY(-50%);
    }

    &.scroll-fade__button-left {
        left: 0;
    }

    &.scroll-fade__button-right {
        right: 0;
    }

    &.scroll-fade__button-down {
        right: 0;
        bottom: 0;
    }

    &.scroll-fade__button-up {
        right: 0;
        top: 0;
    }
}

.scroll-fade__content {
    .scroll-fade.has-scrolled-to-bottom &,
    .scroll-fade.has-scrolled-to-top & {
        padding-right: 10px;
        display: block;
    }

    .scroll-fade.scroll-fade--has-arrows.has-scrolled-to-bottom &,
    .scroll-fade.scroll-fade--has-arrows.has-scrolled-to-top & {
        padding-right: 14px;
        display: block;
    }
}
