@use "sass:map";

@import "~@telia-front/sass-tokens/index.scss";

$lightbox-max-width: 1230px;

.lightbox {
    position: relative;
    height: 100vh;
    z-index: map.get($zindex, lightbox);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background: rgba($grey-b, .8);
    opacity: 0;
    transition: opacity 100ms ease;

    &.is-entered {
        opacity: 1;
    }
}

.lightbox__inner {
    display: flex;
    align-items: center;
    min-height: 100vh;
    padding: 15px;

    @media (min-width: $break-sm) {
        padding: $grid-gutter;
    }
}

.lightbox__box {
    display: flex;
    visibility: hidden;
    width: 100%;
    min-width: 100px;
    max-width: $lightbox-max-width;
    margin: auto;
    background-color: $white;
    border-radius: 3px;
    position: relative;
    overflow: hidden;

    &.has-media-loaded {
        visibility: visible;
    }
}

.lightbox__box-inner {
    width: 100%;
}

.lightbox__header {
    padding: 25px 20px;
    background-color: $purple-deep;

    @media (min-width: $break-sm) {
        padding-left: 40px;
        padding-right: 40px;
    }
}

.lightbox__title {
    color: $white;
}

.lightbox__close {
    @include reset-button();

    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 8px;
    z-index: 2;
    background: rgba($white, .15);
    border-radius: 50%;
    color: $white;
    transition: $transition-default;
    transition-property: color;

    &:hover {
        color: $purple;
    }
}

.lightbox__close-icon {
    @include reset-icon();

    font-size: 24px;
    vertical-align: top;
}

.lightbox__video,
.lightbox__image {
    width: 100%;
    background-color: $purple-black;
}

.lightbox__video,
.lightbox__video .video__iframe,
.lightbox__video .video__image {
    border-radius: 0;
}

.lightbox__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
