@use "sass:math";

@import "~@core-front/sass-tokens/index.scss";

.option-card {
    @include transition(('animation': short, 'properties': transform));

    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    transform: translateY(0);
    margin-top: 4px;

    &.is-checked {
        @include transition(('animation': short, 'properties': transform));

        transform: translateY(-4px);
    }
}

.option-card--badge-top {
    margin-top: math.div(30px, 2);
}

.option-card__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
}

.option-card__label {
    @include visually-hidden();
}

.option-card__body {
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;
    flex: 1 0 auto;

    .option-card__input:disabled ~ & {
        cursor: default;
    }

    .option-card--grey & {
        background: $option-card-grey-background;
    }

    .option-card__input:checked:not(:disabled) ~ & {
        @include transition(('animation': short, 'properties': box-shadow));

        box-shadow: 0 12px 24px rgba($black, .1);
    }

    .option-card__input:not(:checked) ~ & {
        @include transition(('animation': short, 'properties': box-shadow));
    }
}

html[data-whatintent="mouse"]:not([data-whatintent="touch"]) .option-card__input:not(:disabled, :checked) ~ .option-card__body:hover::before {
    box-shadow: inset 0 0 0 $card-border-width $color-primary-1;
    border-color: transparent;
}

html[data-whatintent="mouse"]:not([data-whatintent="touch"]) .option-card__input:not(:disabled, :checked) ~ .option-card__body:not(:hover)::before {
    @include transition(('animation': medium, 'properties': box-shadow));
}

.option-card__body::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    // Using box-shadow instead of border, because border cannot animate smoothly from 1px to 2px width
    box-shadow: 0 0 0 $card-border-width transparent;
    border-radius: inherit;

    .option-card--employee & {
        box-shadow: inset 0 0 0 $card-border-width $color-employee-1;
    }

    .option-card--success & {
        box-shadow: inset 0 0 0 $card-border-width $card-type-success-border-color;
    }

    .option-card__input:disabled ~ & {
        box-shadow: inset 0 0 0 $card-border-width $option-card-disabled-border-color;
    }

    .option-card__input:checked ~ & {
        @include transition(('animation': short, 'properties': (box-shadow, top, left, right, bottom)));

        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        box-shadow: inset 0 0 0 $option-card-checked-border-width $color-selected-1;
    }

    .option-card__input:not(:checked) ~ & {
        @include transition(('animation': short, 'properties': (box-shadow, top, left, right, bottom)));
    }

    .option-card__input:disabled:checked ~ & {
        box-shadow: inset 0 0 0 $option-card-checked-border-width $option-card-disabled-selected-border-color;
    }

    html[data-whatintent="keyboard"] .option-card__input:focus ~ & {
        box-shadow: inset 0 0 0 $card-border-width $color-selected-1;
    }

    .option-card--error.is-animating & {
        border: $card-border-width solid $option-card-error-color;
    }

    .option-card--error.is-animating.option-card--animate-in & {
        @include animation((
            'animation': medium,
            'name': option-card-error-in,
            'iteration-count': 1,
            'direction': forwards,
        ));

        transform: scale($error-box-animation-scale);
    }

    .option-card--error.is-animating.option-card--animate-out & {
        @include animation((
            'animation': medium,
            'name': option-card-error-out,
            'iteration-count': 1,
            'direction': forwards,
        ));

        transform: scale(1);
    }

    .option-card--error.option-card--animate-idle & {
        box-shadow: inset 0 0 0 $card-border-width $option-card-error-color;
    }
}

.option-card__elements {
    width: 100%;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
}

.option-card__content {
    &:first-child {
        border-radius: $option-card-border-radius $option-card-border-radius 0 0;
    }

    .option-card__indicator + &:nth-child(2) {
        border-radius: $option-card-border-radius $option-card-border-radius 0 0;
    }
}

.option-card__indicator {
    @include transition(('animation': short, 'properties': (background-color, border-color)));

    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    position: absolute;
    top: $option-card-indicator-top-padding;
    right: $option-card-indicator-right-padding;

    .option-card--input-radio & {
        border: 1px solid $option-card-indicator-border-color;
        width: $option-card-indicator-size;
        height: $option-card-indicator-size;
        border-radius: 50%;
        background: $white;
        color: $white;

        &::before {
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            background: currentcolor;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
        }
    }

    .option-card--input-radio .option-card__input:checked ~ .option-card__body &::before {
        opacity: 1;
    }

    .option-card__input:checked ~ .option-card__body & {
        @include transition(('animation': short, 'properties': (background-color, border-color)));

        background: $color-selected-1;
        border-color: $color-selected-1;

        .option-card--employee & { // todo: fix this selector
            background: $color-employee-1;
            border-color: $color-employee-1;
        }
    }

    .option-card--input-radio .option-card__input:disabled:checked ~ .option-card__body & {
        background: $option-card-indicator-disabled-background-color;
        border: 1px solid $option-card-indicator-disabled-border-color;
        color: $option-card-indicator-disabled-color;
    }

    .option-card--input-checkbox & {
        width: $option-card-indicator-size;
        height: $option-card-indicator-size;
        background: $white;
        border: 1px solid $option-card-indicator-border-color;
        border-radius: $check-indicator-radius;
        color: $white;
    }

    .option-card--input-checkbox .option-card__input:disabled:checked ~ .option-card__body & {
        background: $option-card-indicator-disabled-background-color;
        border-color: $option-card-indicator-disabled-border-color;
        color: $option-card-indicator-disabled-color;
    }
}

.option-card__indicator-icon {
    @include reset-icon();

    font-size: $option-card-indicator-icon-size;
    opacity: 0;
    pointer-events: none;

    .option-card__input:checked ~ .option-card__body & {
        opacity: 1;
    }
}

.option-card__checked-badge {
    position: relative;
    z-index: 1;
}

.option-card__badge--with-label {
    padding-left: 15px;
}

.option-card__badge-label {
    padding-left: 7px;
    padding-right: 7px;
}

.option-card__badge-icon {
    @include reset-icon();

    font-size: 1.15em;
}

.option-card__body.card__badge-wrapper {
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    transform: translateY(-50%);

    .option-card--error.is-animating.option-card--animate-in & {
        @include animation((
            'animation': medium,
            'name': option-card-error-top-wrapperIn,
            'iteration-count': 1,
            'direction': forwards,
        ));

        transform: translateY(-60%);
    }

    .option-card--error.is-animating.option-card--animate-out & {
        @include animation((
            'animation': medium,
            'name': option-card-error-top-wrapper-out,
            'iteration-count': 1,
            'direction': forwards,
        ));

        transform: translateY(-50%);
    }
}

// option card has different structure and card-border-radius mixin does not match
.option-card__footer {
    .card.card--border-radius-small & {
        border-bottom-left-radius: $card-border-radius-small;
        border-bottom-right-radius: $card-border-radius-small;
    }

    .card.card--border-radius-medium & {
        border-bottom-left-radius: $card-border-radius-medium;
        border-bottom-right-radius: $card-border-radius-medium;
    }

    .card.card--border-radius-large & {
        border-bottom-left-radius: $card-border-radius-large;
        border-bottom-right-radius: $card-border-radius-large;
    }
}

@keyframes option-card-error-in {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba($black, .1);
    }

    100% {
        transform: scale($error-box-animation-scale);
        box-shadow: 0 12px 24px rgba($black, .1);
    }
}

@keyframes option-card-error-out {
    0% {
        transform: scale($error-box-animation-scale);
        box-shadow: 0 12px 24px rgba($black, .1);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba($black, .1);
    }
}

@keyframes option-card-error-wrapper-in {
    0% {
        transform: translateY(50%);
    }

    100% {
        transform: translateY(60%);
    }
}

@keyframes option-card-error-wrapper-out {
    0% {
        transform: translateY(60%);
    }

    100% {
        transform: translateY(50%);
    }
}

@keyframes option-card-error-top-wrapper {
    0% {
        transform: translateY(-50%);
    }

    100% {
        transform: translateY(-60%);
    }
}

@keyframes option-card-error-top-wrapper-out {
    0% {
        transform: translateY(-60%);
    }

    100% {
        transform: translateY(-50%);
    }
}
