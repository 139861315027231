@use "sass:map";
@use "sass:math";

@import "~@core-front/sass-tokens/index.scss";

.modal {
    position: relative;
    z-index: map.get($zindex, modal);
    display: flex;
    flex-direction: column;
    opacity: 0;
    will-change: opacity;
    transition: opacity 300ms ease;

    &.is-entering,
    &.is-entered {
        opacity: 1;
    }

    &.is-entered {
        overflow: visible;
    }

    &.is-entering,
    &.is-exiting {
        overflow: hidden;
    }
}

.modal__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $modal-backdrop-color;
}

.modal__inner {
    display: flex;
    align-items: center;
    min-height: 100vh;
    padding: $grid-gutter $base-horizontal-spacing-xs;

    @media (min-width: $break-sm) {
        padding: $base-horizontal-spacing;
    }

    .modal--full-screen &,
    .modal--with-sidebar-right &,
    .modal--with-sidebar-left & {
        padding: 0;
    }
}

.modal__box {
    display: flex;
    width: 100%;
    margin: auto;
    position: relative;
    max-width: $modal-max-width;
    background: $modal-background-color;
    box-shadow: $modal-box-shadow;
    border-radius: $modal-border-radius;
    border: $modal-border;
    will-change: transform;
    transform: translateY(30px);
    transition: transform 300ms ease;

    .modal--full-screen & {
        min-height: 100vh;
        border-radius: 0;
    }

    .modal--full-height & {
        min-height: calc(100vh - (#{$grid-gutter} * 2));
    }

    .modal--vertical-align-top & {
        margin-top: 0;
    }

    .modal.is-entered &,
    .modal.is-entering & {
        transform: translate(0, 0);
    }

    .modal--with-sidebar-right & {
        transform: translateX(30px);
        margin: auto 0 auto auto;
    }

    .modal--with-sidebar-left & {
        transform: translateX(-30px);
        margin: auto auto auto 0;
    }

    .modal--with-sidebar-right &,
    .modal--with-sidebar-left & {
        border-radius: 0;
        min-height: 100vh;

        @media (min-width: $break-xs) {
            width: 100%;
            max-width: unset;
        }
    }

    .modal--10-col & {
        @media (min-width: $break-lg) {
            width: math.div(10, 12) * 100%;
            max-width: $modal-max-width * math.div(10, 12);
        }
    }

    .modal--10-col.modal--with-sidebar-right &,
    .modal--10-col.modal--10-col.modal--with-sidebar-left & {
        @media (min-width: $break-sm) {
            width: math.div(10, 12) * 100%;
            max-width: $modal-max-width * math.div(10, 12);
        }
    }

    .modal--8-col & {
        @media (min-width: $break-lg) {
            width: math.div(8, 12) * 100%;
            max-width: $modal-max-width * math.div(8, 12);
        }
    }

    .modal--8-col.modal--with-sidebar-right &,
    .modal--8-col.modal--with-sidebar-left & {
        @media (min-width: $break-sm) {
            width: math.div(8, 12) * 100%;
            max-width: $modal-max-width * math.div(8, 12);
        }
    }

    .modal--6-col & {
        @media (min-width: $break-lg) {
            width: math.div(6, 12) * 100%;
            max-width: $modal-max-width * math.div(6, 12);
        }
    }

    .modal--6-col.modal--with-sidebar-right &,
    .modal--6-col.modal--with-sidebar-left & {
        @media (min-width: $break-sm) {
            width: math.div(6, 12) * 100%;
            max-width: $modal-max-width * math.div(6, 12);
        }
    }

    .modal--4-col & {
        @media (min-width: $break-lg) {
            width: math.div(4, 12) * 100%;
            max-width: $modal-max-width * math.div(4, 12);
        }
    }

    .modal--4-col.modal--with-sidebar-right &,
    .modal--4-col.modal--with-sidebar-left & {
        @media (min-width: $break-sm) {
            width: math.div(4, 12) * 100%;
            max-width: $modal-max-width * math.div(4, 12);
        }
    }

    .modal--2-col & {
        @media (min-width: $break-lg) {
            width: math.div(2, 12) * 100%;
            max-width: $modal-max-width * math.div(2, 12);
        }
    }

    .modal--2-col.modal--with-sidebar-right &,
    .modal--2-col.modal--with-sidebar-left & {
        @media (min-width: $break-sm) {
            width: math.div(2, 12) * 100%;
            max-width: $modal-max-width * math.div(2, 12);
        }
    }
}

.modal__body {
    padding: 40px $base-horizontal-spacing-xs;

    .modal--full-screen &,
    .modal--full-height & {
        flex-grow: 1;
    }

    @media only screen and (min-width: $break-sm) {
        padding: 40px;
    }
}

.modal__close-wrapper {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
}

.modal__close-icon {
    @include reset-icon();

    font-size: 24px;
    vertical-align: top;
}

.modal__box-inner {
    width: 100%;

    .modal--full-screen &,
    .modal--full-height & {
        display: flex;
        flex-direction: column;
    }

    .modal--cover & {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (min-width: $break-md) {
            width: 66.6666%;
        }
    }
}

.modal__cover {
    display: none;
    position: relative;
    width: 33.3333%;
    padding: 40px;
    overflow: hidden;
    border-radius: $modal-border-radius 0 0 $modal-border-radius;
    background-size: cover;
    background-position: 50% 50%;

    .modal--cover & {
        @media (min-width: $break-md) {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.modal__cover--fill {
    padding: 0;
}

.modal__cover-icon {
    width: 100%;
    height: 100%;
}

.modal__footer {
    padding: 20px $base-horizontal-spacing-xs;
    background-color: $modal-footer-background-color;
    border-radius: 0 0 $modal-border-radius $modal-border-radius;

    @media (min-width: $break-sm) {
        padding-left: 40px;
        padding-right: 40px;
    }

    .modal--full-screen & {
        border-radius: 0;
    }
}

.modal__footer--sticky {
    position: sticky;
    bottom: 0;
    z-index: map.get($zindex, modal-footer);
}

.modal__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

