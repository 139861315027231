@use "sass:math";

@import "~@telia-front/sass-tokens/index.scss";

.cart__header {
    display: none;
    font-weight: 700;

    @media (min-width: $break-xl) {
        display: block;
    }
}

.cart__row {
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.cart__cell {
    padding: 15px 5px;

    &:last-child {
        padding-right: 0;
    }
}

.cart__cell--padding-none {
    padding: 0;
    border-top: none;
}

.cart__cell--padding-small,
.cart__cell--vertical-padding-small {
    padding-top: 7px;
    padding-bottom: 7px;
}

.cart__cel--vertical-padding-none {
    padding-top: 0;
    padding-bottom: 0;
}

.cart__cell--border-top {
    border-top: 1px solid $grey;
}

.cart__cell--border-bottom {
    border-bottom: 1px solid $grey;
}

.cart__cell--center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.cart__cell--image {
    align-items: center;
    border-top: none;

    @media (min-width: $break-xl) {
        display: flex;
        padding: 15px 20px;
        width: 80px;
    }
}

.cart__cell--name {
    width: calc(100% - 50px);

    @media (min-width: $break-xl) {
        width: calc(35% - 80px);
    }
}

.cart__item {
    + .cart__item {
        border-top: 2px solid $grey;
    }

    @media (min-width: $break-xl) {
        border-top: 2px solid $grey;
    }
}

.cart__item-label {
    display: block;

    @media (min-width: $break-xl) {
        display: none;
    }
}

.cart__item-number {
    margin-top: .4em;

    @media (min-width: $break-xl) {
        margin-top: 0;
    }
}

.cart__item-remove-btn {
    position: absolute;
    top: 5px;
    right: -10px;

    & .close-button__inner {
        background: none;
    }

    @media (min-width: $break-xl) {
        top: 50%;
        left: -15px;
        right: auto;
        transform: translateY(-50%);
    }
}

.cart__item-image {
    height: 40px;
    width: 40px;
}

.cart__check-label {
    font-size: 14px;

    @media (min-width: $break-xl) {
        font-size: 16px;
    }
}

.cart__cell.cart__cell-additional-offer {
    @media (min-width: $break-xl) {
        width: calc(100% - 80px);
    }
}

.cart__footer {
    border-top: 2px solid $grey;
}

.cart__footer-sum {
    font-size: 14px;

    @media (min-width: $break-sm) {
        font-size: 16px;
    }
}

@mixin make-cell($name) {
    @for $i from 1 through 10 {
        .cart__cell--#{$name}-#{$i}0 {
            width: math.div(100%, 10) * $i;
        }
    }

    @for $i from 1 through 9 {
        .cart__cell--#{$name}-#{$i}5 {
            width: 5% + 10 * $i;
        }
    }

    .cart__row--start-#{$name} {
        justify-content: flex-start;
    }

    .cart__row--center-#{$name} {
        justify-content: center;
    }

    .cart__row--end-#{$name} {
        justify-content: flex-end;
    }

    .cart__row--top-#{$name} {
        align-items: flex-start;
    }

    .cart__row--middle-#{$name} {
        align-items: center;
    }

    .cart__row--bottom-#{$name} {
        align-items: flex-end;
    }

    .cart__row--around-#{$name} {
        justify-content: space-around;
    }

    .cart__row--between-#{$name} {
        justify-content: space-between;
    }

    .cart__row--no-wrap-#{$name} {
        flex-wrap: nowrap;
    }

    .cart__cell--border-none-#{$name} {
        border: none;
    }

    .cart__cell--border-bottom-none-#{$name} {
        border-bottom: none;
    }

    .cart__cell--border-bottom-#{$name} {
        border-bottom: 1px solid $grey;
    }

    .cart__cell--border-top-none-#{$name} {
        border-top: none;
    }

    .cart__cell--border-top-#{$name} {
        border-top: 1px solid $grey;
    }
}

@include make-cell(xs);

@media only screen and (min-width: $break-sm) {
    @include make-cell(sm);
}

@media only screen and (min-width: $break-md) {
    @include make-cell(md);
}

@media only screen and (min-width: $break-lg) {
    @include make-cell(lg);
}

@media only screen and (min-width: $break-xl) {
    @include make-cell(xl);
}
