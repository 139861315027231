@import "~@telia-front/sass-tokens/index.scss";

.tv-comparison__table {
    table tr:first-child th {
        border-top: 0;
    }

    table tr td:first-child,
    table tr th:first-child {
        border-left: 0;
    }

    table tr td:last-child,
    table tr th:last-child {
        border-right: 0;
    }

    table thead tr th,
    table tbody tr.tv-comparison__bordered-row td {
        border-bottom-width: 1px;
    }

    table thead tr th {
        border-bottom-color: inherit;
    }

    .tv-comparison__price-cell,
    .tv-comparison__name-cell,
    .tv-comparison__info-cell {
        @media (max-width: $break-md - 1) {
            border-style: dotted;
        }
    }

    .tv-comparison__price-cell {
        @media (max-width: $break-md - 1) {
            height: 53px;
        }
    }

    .tv-comparison__row {
        @media (max-width: $break-md - 1) {
            border-top-style: dotted;
        }
    }

    .tv-comparison__name-cell {
        @media (max-width: $break-md - 1) {
            border-top-style: solid;
        }
    }

    .tv-comparison__price-row {
        @media (max-width: $break-md - 1) {
            border-bottom: 1px solid $grey;
        }
    }

    .tv-comparison__channel-info-cell {
        @media (max-width: $break-md - 1) {
            padding-top: 0;
        }
    }

    .tv-comparison__sound-and-subtitles {
        @media (max-width: $break-md - 1) {
            margin-top: 0;
        }
    }
}

tr.tv-comparison__theme {
    .tv-comparison__table table & {
        border-top: 0;

        > td {
            border-top: 0;
        }
    }
}

.tv-comparison__body {
    overflow-x: auto;
    padding-bottom: 2px;
    margin-bottom: -1px;

    .table {
        overflow-x: visible;
    }
}

.tv-comparison__accordion {
    .accordion__content {
        padding: 0;
    }

    .accordion__header-inner {
        padding-left: 10px;
    }
}

.tv-comparison__header {
    padding: 30px 10px 10px;

    @media (min-width: $break-md) {
        padding: 10px 0;
    }
}

.tv-comparison__filter {
    padding: 18px 0 0;

    @media (min-width: $break-md) {
        padding: 32px 0 0;
    }
}
