@use "sass:map";

@import "~@telia-front/sass-tokens/index.scss";

.horizontal-filter {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  
    &.is-scrollable {
        display: block;
        margin: -4px;
    
        .scroll-fade::before,
        .scroll-fade::after {
            z-index: 2;
        }
        
        .scroll-fade__button {
            z-index: 3;
        }
    }
  
    .horizontal-scroll__item {
        .horizontal-filter-item__wrapper {
            padding: 4px 2px;
        }
    }
  
    .horizontal-scroll__item:first-child {
        .horizontal-filter-item__wrapper {
            padding: 4px 2px 4px 4px;
        }
    }
  
    .horizontal-scroll__item:last-child {
        .horizontal-filter-item__wrapper {
            padding: 4px 4px 4px 2px;
        }

        .horizontal-filter-item__show-all-btn {
            margin: 4px 4px 4px 2px;
        }
    }
}

.horizontal-filter-item {
    @include reset-button();

    padding: 12px 16px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: 400;
    position: relative;
    min-height: 48px;

    &.is-active {
        font-weight: 700;
    }
    
    .horizontal-filter.is-scrollable & {
        white-space: nowrap;
    }

    &.is-clearable {
        padding: 12px 16px 12px 44px;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        pointer-events: none;
        border: 1px solid $color-raw-grey-bright;
        border-radius: inherit;
        z-index: 1;
    }

    html[data-whatintent="mouse"] &:hover::before {
        border: 1px solid $color-primary-1;
    }

    html[data-whatintent="mouse"] &:not(:hover)::before {
        transition: border 300ms ease-out;
    }

    html[data-whatintent="mouse"] &.is-active:hover::before,
    &:active:hover::before {
        border: 2px solid $color-primary-1;
    }

    &.is-active::before,
    &:active::before {
        border: 2px solid $color-selected-1;
    }
}

.horizontal-filter-item__dropdown-footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    position: sticky;
    bottom: 0;

    .horizontal-filter-item__dropdown.is-scrollable .horizontal-filter-item__dropdown-inner & {
        border-bottom-right-radius: 0;
    }
}

.horizontal-filter-item__dropdown {
    position: absolute;
    max-width: calc(100vw - 20px);
    z-index: map.get($zindex, horizontal-filter-dropdown);
    width: 288px;

    &.is-hidden {
        @include visually-hidden();
    }
}

.horizontal-filter-item__dropdown-inner {
    transition: 100ms ease-out;
    transition-property: transform, opacity;
    transform: translateY(-10px);
    opacity: 0;
    max-height: 400px;
    overflow: auto;

    .horizontal-filter-item__dropdown.is-entered & {
        transform: translateY(0);
        opacity: 1;
    }
}

.horizontal-filter-item__show-all-btn {
    @extend .horizontal-filter-item;

    font-weight: 700;
}

.horizontal-filter-item__icon {
    transform: rotate(0deg);
    display: block;

    .horizontal-filter-item.is-active.is-open & {
        transform: rotate(180deg);
    }
}

.horizontal-filter__skeleton .skeleton__content {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
}

.horizontal-filter-item__clear-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
}

.horizontal-filter-item__wrapper {
    display: flex;
    position: relative;
}
