.animate {
    position: relative;
}

.animate__item {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    margin: 0;
    width: 100%;
    transition: all 300ms cubic-bezier(.2, .65, .35, 1);
    opacity: 0;

    .animate--slide-up & {
        transform: translate(-50%, calc(-50% + 8px));
    }

    .animate--slide-left & {
        transform: translate(calc(-50% + 20px), -50%);
    }

    html.no-js &:first-child,
    &:only-child {
        // important is necessary to override all states
        position: static !important;
        transform: none !important;
    }
}

.animate__item.enter {
    transition: all 300ms cubic-bezier(.2, .65, .35, 1);
    opacity: 1;

    .animate--slide-left &,
    .animate--slide-up & {
        transform: translate(-50%, -50%);
    }
}

.animate__item.entered {
    opacity: 1;

    .animate--slide-left &,
    .animate--slide-up & {
        transform: translate(-50%, -50%);
    }
}

.animate__item.exit {
    transition: all 300ms 50ms cubic-bezier(.5, 0, .65, .2);
    opacity: 0;

    .animate--slide-up & {
        transform: translate(-50%, calc(-50% - 8px));
    }

    .animate--slide-left & {
        transform: translate(calc(-50% - 20px), -50%);
    }
}
