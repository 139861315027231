@use "sass:color";
@use "sass:math";
@import "~@telia-front/design-tokens/lib/scss/variables.scss";
@import "~@telia-front/design-tokens/lib/scss/telia-light.scss";

// Variables

// Page minimum width
$page-min-width: 320px;

// Page maximum width
$page-max-width: 1288px !default;
$page-max-width-lg: 1768px !default;

// Breakpoints
// Mobile first
// Mobile 320 - 599
$mobile: 320px;

// Extra small (320px - 599px)
// Use the modifier "-xs" when defining classes.
$break-xs: 320px;

// Small (600px - 799px)
// Use the modifier "-sm" when defining classes.
$break-sm: 600px;

// Medium (800px - 999px)
// Use the modifier "-md" when defining classes.
$break-md: 800px;

// Large (1000px - 1299px)
// Use the modifier "-lg" when defining classes.
$break-lg: 1000px;

// Extra large (1288px - 1449px)
// Use the modifier "-xl" when defining classes.
$break-xl: 1288px !default;

// 2xExtra large (1450px - ...)
// Use the modifier "-xxl" when defining classes.
$break-xxl: 1450px !default;

// Color definitions

// Main brand colors
$purple: $color-raw-purple-base;
$purple-l: $color-raw-purple-light;
$purple-d: $color-raw-purple-dark;
$purple-deep: $color-raw-purple-deep;
$purple-black: $color-raw-purple-black;

// Commerce flow colors
$green: $color-raw-green-base;
$green-l: $color-raw-green-light;

// Info colors
$blue: $color-raw-blue-base;
$blue-l: $color-raw-blue-light;

// Additional colors
$red: $color-raw-red-base;
$red-l: $color-raw-red-light;

$orange: $color-raw-orange-base;
$orange-l: $color-raw-orange-light;

$pink: $color-raw-pink-base;
$pink-l: $color-raw-pink-light;
$pink-m: $color-raw-pink-medium;

$teal: $color-raw-green-base;
$teal-l: $color-raw-green-light;

// Neutral colors
// Background colors
$white: $color-raw-white-base; // white background
$grey: $color-raw-grey-base; // tables and lines
$grey-l: $color-raw-grey-light; // default background
$grey-m: $color-raw-grey-light; // tables and lines
$grey-b: $color-raw-grey-black; // dark background

// Text colors
$grey-d: $color-raw-grey-dark; // grey text and input field line
$grey-t: $color-raw-black-strong; // text, headings
$black: $color-raw-black-base; // for graphics

// Third party colors
$swedbank: $color-third-party-swedbank-base;
$swedbank-l: $color-third-party-swedbank-light;
$seb: $color-third-party-seb-base;
$seb-l: $color-third-party-seb-light;
$danske: $color-third-party-danske-base;
$danske-l: $color-third-party-danske-light;
$lhv: $color-third-party-lhv-base;
$lhv-l: $color-third-party-lhv-light;
$coop: $color-third-party-coop-base;
$luminor: $color-third-party-luminor-base;

$google: $color-third-party-google-base;
$facebook: $color-third-party-facebook-base;
$microsoft: $color-third-party-microsoft-base;
$spotify: $color-third-party-spotify-base;
$twitter: $color-third-party-twitter-base;
$diil: $color-third-party-diil-base;

// Graph colors
$graph-color-1: $color-graph-1;
$graph-color-2: $color-graph-2;
$graph-color-3: $color-graph-3;
$graph-color-4: $color-graph-4;
$graph-color-5: $color-graph-5;
$graph-color-6: $color-graph-6;
$graph-color-7: $color-graph-7;

$graph-unused-color: var(--color-neutral-bg-subtle, $color-raw-black-fare) !default;

// Grid
$grid-columns: 12;
$grid-gutter: 24px;
$grid-gutter-small: 8px;
$grid-gutter-smedium: 16px;
$grid-gutter-medium: 48px;
$grid-gutter-medium-xs: 32px;
$grid-gutter-xs: 16px;
$grid-gutter-xsmall: 4px;

$grid-border-color: var(--color-neutral-border-subtle, #{$grey}) !default;
$grid-border-light-color: var(--color-neutral-border-subtlest, #{$grey-l}) !default;
$grid-border-dark-color: var(--color-neutral-border, #{$grey-d}) !default;
$grid-border-on-dark-color: var(--color-neutral-border-subtle, rgba($white, .2)) !default;

// container
$container-padding: 16px !default;
$container-padding-sm: 24px !default;
$container-padding-lg: calc(24px * 4 / 3) !default;

// Header
$break-smd: 800px;
$header-height: 112px;
$header-height-lg: 120px;
$header-employee-height: 40px;
$header-search-close-button-margin-left: 8px;
$header-search-close-button-width: 32px;
$header-search-offset-right: $header-search-close-button-margin-left + $header-search-close-button-width;
$header-search-offset-left-xs: $header-search-offset-right + $container-padding * 2;
$header-search-offset-left-md: $header-search-offset-right + $container-padding-sm * 2;

// Main
$main-padding-top: 30px;

// Slick
$slick-arrow-color: $white !default;
$slick-prev-character: "←" !default;
$slick-next-character: "→" !default;
$slick-dot-character: "•" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: .75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: .25 !default;

// Custom inputs
$address-search-height: 60px;

// Spinner list pebble rotations
$pebble-rotation-1: 135deg;
$pebble-rotation-2: 110deg;
$pebble-rotation-3: -45deg;
$pebble-rotation-4: 45deg;

$transition-duration: 150ms;
$transition-timing-function: ease;
$transition-default: $transition-duration $transition-timing-function;

// z-index
$zindex: (
    datepicker: 100,
    carousel-arrow: 100,
    tooltip-on-header: 32,
    tooltip: 32,
    datepickers-container-scroll-disabled: 31,
    select-menu: 31,
    notice-on-top: 30,
    modal: 30,
    modal-footer: 30,
    lightbox: 30,
    page-container: 22,
    filter-sidemenu-open: 22,
    header-dropdown: 22,
    progress-card-sidebar-open: 21,
    header: 20,
    progress-card-sidebar: 19,
    suggestion: 19,
    dropdown-menu: 19,
    filter-sidemenu: 19,
    horizontal-filter-dropdown: 19,
    filter-backdrop: 18,
    dropdown-backdrop: 18,
    overlay: 15,
    fixed-buttons: 15,
    collapse-modal: 10,
    header-backdrop: 10,
    header-backdrop-desktop: 9,
    header-backdrop-nav: 9,
    product-comparison-bar: 4,
    scroll-to-top-button: 3,
    form-address-search: 2,
    sidemenu: 2,
    badge: 2,
    affix: 2,
    breadcrumbs: 1,
    carousel: 1,
    search-large: 1,
    carousel-hero: 0,
);

// THEME
$theme: "telia" !default;

// Base spacing
$base-horizontal-spacing-xs: 15px !default;
$base-horizontal-spacing: 20px !default;

// Typography
// TODO: remove "freesans" after https://github.com/Zenika/alpine-chrome/issues/171 is solved
$font-primary: var(--type-font-family, "Telia Sans", "freesans", sans-serif) !default;
$font-primary-ru: $font-primary !default;
$font-secondary: var(--type-font-family-display, "Telia Sans Heading", "Telia Sans", "freesans", sans-serif) !default;

$body-bg: var(--color-neutral-bg-level-1, #{$color-neutral-bg-level-1}) !default;

$base-font-size: var(--type-font-size, 1rem) !default;
$base-font-size-xl: var(--type-font-size, 1rem) !default;
$base-line-height: var(--type-line-height, 1.375) !default; // 16/22;
$base-line-height-xl: var(--type-line-height, 1.375) !default; // 16/22;
$font-weight-base: 400 !default;

$font-size-small: var(--type-small-font-size, .875rem) !default; // 14px
$font-size-small-xl: var(--type-small-font-size, .875rem) !default; // 14px
$line-height-small: var(--type-small-line-height, 1.43) !default; // 14/20
$line-height-small-xl: var(--type-small-line-height, 1.43) !default; // 14/20

$font-size-tiny: var(--type-tiny-font-size, .75rem) !default; // 12px
$font-size-tiny-xl: var(--type-tiny-font-size, .75rem) !default; // 12px
$line-height-tiny: var(--type-tiny-line-height, 1.334) !default; // 12/16
$line-height-tiny-xl: var(--type-tiny-line-height, 1.334) !default; // 12/16

$text-color-base: var(--color-neutral-text, #{$color-neutral-text}) !default;
$text-color-primary: $color-primary-1 !default;
$text-color-white: $white !default;
$text-color-attention: $color-attention-3 !default;
$text-color-light: $color-raw-black-medium !default;
$text-color-dark-primary: $color-raw-white-strong !default;
$text-color-dark-inactive: $color-raw-white-dull !default;
$text-color-dark-light: $color-raw-white-medium !default;

// Heading
$heading-font: $font-secondary !default;
$heading-transform: none !default;
$heading-weight: 700 !default;

// these need to be defined in px
// because CarouselHero uses them with fluid-type mixin
$h1-size: var(--type-h1-font-size, 32px) !default; // 32px
$h1-size-sm: var(--type-h1-sm-font-size, 44px) !default; // 44px
$h1-size-xl: var(--type-h1-xl-font-size, 56px) !default; // 56px
$h1-line-height: var(--type-h1-line-height, 1.0625) !default; // 32/34
$h1-line-height-sm: var(--type-h1-sm-line-height, 1.0455) !default; // 44/46
$h1-line-height-xl: var(--type-h1-xl-line-height, 1) !default; // 56/56
$h1-transform: var(--type-h1-text-transform, #{$heading-transform}) !default;

$h2-size: var(--type-h2-font-size, 1.625rem) !default; // 26px
$h2-size-sm: var(--type-h2-sm-font-size, 2rem) !default; // 32px
$h2-size-xl: var(--type-h2-xl-font-size, 2.5rem) !default; // 40px
$h2-line-height: var(--type-h2-line-height, 1.154) !default; // 26/30
$h2-line-height-sm: var(--type-h2-sm-line-height, 1.0625) !default; // 32/34
$h2-line-height-xl: var(--type-h2-xl-line-height, 1.1) !default; // 40/44

$h3-size: var(--type-h3-font-size, 1.5rem) !default; // 24px
$h3-size-sm: var(--type-h3-sm-font-size, 1.5rem) !default; // 24px
$h3-size-xl: var(--type-h3-xl-font-size, 1.875rem) !default; // 30px
$h3-line-height: var(--type-h3-line-height, 1.1667) !default; // 24/28
$h3-line-height-sm: var(--type-h3-sm-line-height, 1.1667) !default; // 24/28
$h3-line-height-xl: var(--type-h3-xl-line-height, 1.1333) !default; // 30/34
$h3-font-family: var(--type-h3-font-family, $font-primary) !default;

$h4-size: var(--type-h4-font-size, 1.25rem) !default; // 20px
$h4-size-xl: var(--type-h4-xl-font-size, 1.375rem) !default; // 22px
$h4-line-height: var(--type-h4-line-height, 1.3) !default; // 20/26
$h4-line-height-xl: var(--type-h4-xl-line-height, 1.27) !default; // 22/28

$h5-size: var(--type-h5-font-size, 1.125rem) !default; // 18px
$h5-size-xl: var(--type-h5-font-size, 1.125rem) !default; // 18px
$h5-line-height: var(--type-h5-line-height, 1.33) !default; // 18/24
$h5-line-height-xl: var(--type-h5-line-height, 1.33) !default; // 18/24

// Anchor
$anchor-weight: 700 !default;
$anchor-color: var(--color-brand-primary-text, $color-raw-purple-dark) !default;
$anchor-hover-color: var(--color-brand-primary-text-hover, $color-raw-purple-base) !default;
$anchor-text-color-hover-color: var(--color-brand-primary-text-hover, $color-raw-purple-base) !default;

// Badge
$badge-background: var(--color-brand-primary-bg-strong, $color-background-1) !default;
$badge-color: var(--color-brand-primary-text-onbg-strong, $white) !default;
$badge-inactive-background: var(--color-neutral-bg-subtle, $color-inactive-1) !default;
$badge-inactive-color: var(--color-neutral-text, $white) !default;
$badge-recommended-background: var(--color-brand-expressive-bg-subtle, $color-recommended-2) !default;
$badge-recommended-color: var(--color-brand-expressive-text, $color-recommended-1) !default;
$badge-selected-background: var(--color-brand-primary-bg-strong, $color-selected-1) !default;
$badge-selected-color: var(--color-brand-primary-text-onbg-strong, $white) !default;
$badge-success-background: var(--color-success-bg-subtle, $color-success-2) !default;
$badge-success-color: var(--color-success-text, $color-success-3) !default;

$badge-font-weight-large: 400 !default;
$badge-font-weight-large-sm: 700 !default;
$badge-font-size-large: 20px !default;
$badge-font-size-large-sm: 16px !default;
$badge-size-large: 32px !default;
$badge-size-large-line-height: 22px !default;
$badge-size-large-icon-height: 22px !default;
$badge-size-large-icon-width: 20px !default;

$badge-three-digit-font-weight: 700 !default;
$badge-three-digit-large-font-size: 16px !default;
$badge-three-digit-large-line-height: 24px !default;
$badge-three-digit-large-line-height-sm: 22px !default;
$badge-three-digit-large-font-size-sm: 12px !default;
$badge-three-digit-large-padding: 4px !default;
$badge-three-digit-large-padding-sm: 1px !default;
$badge-three-digit-small-font-size: 8px !default;
$badge-three-digit-small-line-height: 12px !default;
$badge-three-digit-small-padding: 2px !default;

$badge-two-digit-small-padding: 2px !default;
$badge-two-digit-small-line-height: 12px !default;
$badge-two-digit-large-padding: 4px !default;
$badge-two-digit-large-line-height: 24px !default;
$badge-two-digit-large-padding-sm: 2px !default;
$badge-two-digit-large-line-height-sm: 20px !default;

// Banner
$banner-border-radius: 16px !default;
$banner-border-radius-small: 8px !default;
$banner-border-radius-xsmall: 8px !default;
$banner-padding-none: 0 !default;
$banner-padding-xxsmall: 8px !default;
$banner-padding-xsmall: 12px !default;
$banner-padding-small: 16px !default;
$banner-padding-medium: 24px !default;
$banner-padding-large: 32px !default;
$banner-padding-xlarge: 48px !default;
$banner-padding-xxlarge: 64px !default;
$banner-margin-none: 0 !default;
$banner-margin-xxsmall: 8px !default;
$banner-margin-xsmall: 12px !default;
$banner-margin-small: 16px !default;
$banner-margin-medium: 24px !default;
$banner-margin-large: 32px !default;
$banner-margin-xlarge: 48px !default;
$banner-margin-xxlarge: 64px !default;

$banner-paddings: (
    none $banner-padding-none,
    xxsmall $banner-padding-xxsmall,
    xsmall $banner-padding-xsmall,
    small $banner-padding-small,
    medium $banner-padding-medium,
    large $banner-padding-large,
    xlarge $banner-padding-xlarge,
    xxlarge $banner-padding-xxlarge,
);

$banner-margins: (
    none $banner-margin-none,
    xxsmall $banner-margin-xxsmall,
    xsmall $banner-margin-xsmall,
    small $banner-margin-small,
    medium $banner-margin-medium,
    large $banner-margin-large,
    xlarge $banner-margin-xlarge,
    xxlarge $banner-margin-xxlarge,
);

// Breadcrumbs
$breadcrumbs-link-color: $color-raw-black-medium !default;
$breadcrumbs-icon-color: $color-raw-black-medium !default;
$breadcrumbs-link-dark-color: $color-raw-white-medium !default;
$breadcrumbs-icon-dark-color: $color-raw-white-medium !default;
$breadcrumbs-link-dark-hover-color: $color-raw-purple-bright !default;

// Button variants
// necessary for mixin atm
$btn-min-width: 150px !default;
$btn-min-height: 48px !default;
$btn-font-size: 16px !default;
$btn-line-height: 1.125 !default; // 18px
$btn-text-transform: none !default;
$btn-border-width: 1px !default;
$btn-padding: 6px !default;
$btn-border-radius: 24px !default;
$btn-min-vertical-padding: 6 - $btn-border-width !default;
$btn-min-horizontal-padding: 24 - $btn-border-width !default;
$btn-letter-spacing: normal !default;

$btn-small-min-height: 32px !default;
$btn-small-font-size: 14px !default;
$btn-small-line-height: 1.2 !default;
$btn-small-padding: 2px !default;
$btn-small-min-horizontal-padding: 16 - $btn-border-width !default;
$btn-small-border-radius: 16px !default;
$btn-small-icon-padding: 16px - $btn-border-width !default;
$btn-small-icon-spacing: 6px !default;
$btn-small-inner-min-height: 26px !default;

$btn-icon-min-width: $btn-min-height;
$btn-icon-wrapper-margin-left: -4px !default;
$btn-icon-wrapper-margin-right: -4px !default;
$btn-icon-outer-padding: 19px - $btn-min-horizontal-padding !default;

$btn-link-disabled-pointer-events: auto !default;

$btn-outer-swed-color: $white !default;
$btn-outer-swed-background: #D34300 !default;
$btn-outer-swed-background-hover: #AE3700 !default;
$btn-outer-swed-border: #D34300 !default;
$btn-outer-swed-border-hover: #AE3700 !default;
$btn-outer-swed-hover-color: $white !default;
$btn-outer-swed-pressed-color: $white !default;
$btn-outer-swed-pressed-background: #882B00 !default;

$btn-outer-seb-color: $white !default;
$btn-outer-seb-background: #3F8510 !default;
$btn-outer-seb-background-hover: #336D0D !default;
$btn-outer-seb-border: #3F8510 !default;
$btn-outer-seb-border-hover: #336D0D !default;
$btn-outer-seb-hover-color: $white !default;
$btn-outer-seb-pressed-color: $white !default;
$btn-outer-seb-pressed-background: #28540A !default;

$btn-outer-luminor-color: $white !default;
$btn-outer-luminor-background: #481335 !default;
$btn-outer-luminor-background-hover: #370E28 !default;
$btn-outer-luminor-border: #481335 !default;
$btn-outer-luminor-border-hover: #370E28 !default;
$btn-outer-luminor-hover-color: $white !default;
$btn-outer-luminor-pressed-color: $white !default;
$btn-outer-luminor-pressed-background: #200818 !default;

// Deprecared, as Danske Bank does not exist anymore.
$btn-outer-danske-color: $white !default;
$btn-outer-danske-background: $danske !default;
$btn-outer-danske-background-hover: $danske !default;
$btn-outer-danske-border: $danske !default;
$btn-outer-danske-border-hover: $danske !default;
$btn-outer-danske-hover-color: $white !default;
$btn-outer-danske-pressed-color: $white !default;
$btn-outer-danske-pressed-background: $danske !default;

$btn-outer-coop-color: $white !default;
$btn-outer-coop-background: #004BC5 !default;
$btn-outer-coop-background-hover: #002F7D !default;
$btn-outer-coop-border: #004BC5 !default;
$btn-outer-coop-border-hover: #002F7D !default;
$btn-outer-coop-hover-color: $white !default;
$btn-outer-coop-pressed-color: $white !default;
$btn-outer-coop-pressed-background: #002665 !default;

$btn-outer-lhv-color: $white !default;
$btn-outer-lhv-background: #222221 !default;
$btn-outer-lhv-background-hover: #1D1D1D !default;
$btn-outer-lhv-border: #222221 !default;
$btn-outer-lhv-border-hover: #1D1D1D !default;
$btn-outer-lhv-hover-color: $white !default;
$btn-outer-lhv-pressed-color: $white !default;
$btn-outer-lhv-pressed-background: #111110 !default;

$btn-outer-google-color: $white !default;
$btn-outer-google-background: #1A73E8 !default;
$btn-outer-google-background-hover: #155EBD !default;
$btn-outer-google-border: #1A73E8 !default;
$btn-outer-google-border-hover: #155EBD !default;
$btn-outer-google-hover-color: $white !default;
$btn-outer-google-pressed-color: $white !default;
$btn-outer-google-pressed-background: #104992 !default;

$btn-outer-facebook-color: $white !default;
$btn-outer-facebook-background: #0866FF !default;
$btn-outer-facebook-background-hover: #0756D8 !default;
$btn-outer-facebook-border: #0866FF !default;
$btn-outer-facebook-border-hover: #0756D8 !default;
$btn-outer-facebook-hover-color: $white !default;
$btn-outer-facebook-pressed-color: $white !default;
$btn-outer-facebook-pressed-background: #0543A9 !default;

$btn-outer-microsoft-color: $white !default;
$btn-outer-microsoft-background: #007CB6 !default;
$btn-outer-microsoft-background-hover: #006696 !default;
$btn-outer-microsoft-border: #007CB6 !default;
$btn-outer-microsoft-border-hover: #006696 !default;
$btn-outer-microsoft-hover-color: $white !default;
$btn-outer-microsoft-pressed-color: $white !default;
$btn-outer-microsoft-pressed-background: #004F74 !default;

$btn-outer-spotify-color: $white !default;
$btn-outer-spotify-background: #15873E !default;
$btn-outer-spotify-background-hover: #116F32 !default;
$btn-outer-spotify-border: #15873E !default;
$btn-outer-spotify-border-hover: #116F32 !default;
$btn-outer-spotify-hover-color: $white !default;
$btn-outer-spotify-pressed-color: $white !default;
$btn-outer-spotify-pressed-background: #0E5727 !default;

$btn-outer-twitter-color: $white !default;
$btn-outer-twitter-background: #1a93dd !default;
$btn-outer-twitter-background-hover: #167ab8 !default;
$btn-outer-twitter-border: #1a93dd !default;
$btn-outer-twitter-border-hover: #167ab8 !default;
$btn-outer-twitter-hover-color: $white !default;
$btn-outer-twitter-pressed-color: $white !default;
$btn-outer-twitter-pressed-background: #126497 !default;

$btn-outer-diil-color: $white !default;
$btn-outer-diil-background: #00847E !default;
$btn-outer-diil-background-hover: #006C67 !default;
$btn-outer-diil-border: #00847E !default;
$btn-outer-diil-border-hover: #006C67 !default;
$btn-outer-diil-hover-color: $white !default;
$btn-outer-diil-pressed-color: $white !default;
$btn-outer-diil-pressed-background: #02534F !default;

$btn-primary-background: var(--color-brand-primary-bg-strong, $color-raw-purple-dim) !default;
$btn-primary-background-hover-color: var(--color-brand-primary-bg-strong-hover, $color-raw-purple-dark) !default;
$btn-primary-border-color: var(--color-brand-primary-bg-strong, $color-raw-purple-dim) !default;
$btn-primary-border-hover-color: var(--color-brand-primary-bg-strong-hover, $color-raw-purple-dark) !default;
$btn-primary-color: var(--color-brand-primary-text-onbg-strong, $white) !default;
$btn-primary-hover-color: var(--color-brand-primary-text-onbg-strong, $white) !default;
$btn-primary-pressed-color: var(--color-brand-primary-text-onbg-strong, $white) !default;
$btn-primary-pressed-background: var(--color-brand-primary-bg-strong-pressed, $color-raw-purple-light) !default;

$btn-primary-link-color: var(--color-brand-primary-text, $color-raw-purple-dark) !default;
$btn-primary-link-hover-color: var(--color-brand-primary-text-hover, $color-raw-purple-base) !default;
$btn-primary-link-pressed-color: var(--color-brand-primary-text-hover, $color-raw-purple-medium) !default;

$btn-employee-color: var(--color-employee-text-onbg-strong, $white) !default;
$btn-employee-hover-color: var(--color-employee-text-onbg-strong, $white) !default;
$btn-employee-background: var(--color-employee-bg-strong, $color-employee-1) !default;
$btn-employee-background-hover: var(--color-employee-bg-strong-hover, $color-employee-2) !default;
$btn-employee-border-color: var(--color-employee-bg-strong, $color-employee-1) !default;
$btn-employee-border-hover-color: var(--color-employee-bg-strong-hover, $color-employee-2) !default;
$btn-employee-link-color: var(--color-employee-text, $color-employee-1) !default;
$btn-employee-link-hover-color: var(--color-employee-text-hover, $color-employee-2) !default;
$btn-employee-link-pressed-color: var(--color-employee-text-hover, $color-employee-2) !default;
$btn-employee-pressed-color: var(--color-employee-text-onbg-strong, $white) !default;
$btn-employee-pressed-background: var(--color-employee-bg-strong-pressed, $color-employee-2) !default;

$btn-employee-secondary-color: var(--color-employee-text, $color-employee-1) !default;
$btn-employee-secondary-color-hover: var(--color-employee-text-onbg-strong, $white) !default;
$btn-employee-secondary-background: transparent !default;
$btn-employee-secondary-background-hover: var(--color-employee-bg-strong, $color-employee-1) !default;
$btn-employee-secondary-border: var(--color-employee-border, $color-employee-1) !default;
$btn-employee-secondary-border-hover: var(--color-employee-bg-strong, $color-employee-1) !default;
$btn-employee-secondary-box-shadow-hover: null !default;
$btn-employee-secondary-pressed-color: var(--color-employee-text-onbg-strong, $white) !default;
$btn-employee-secondary-pressed-background: var(--color-employee-bg-strong-hover, $color-employee-2) !default;

$btn-withdrawal-color: var(--color-error-text-onbg-strong, $white) !default;
$btn-withdrawal-background: var(--color-error-bg-strong, $color-error-1) !default;
$btn-withdrawal-background-hover: var(--color-error-bg-strong-hover, $color-error-2) !default;
$btn-withdrawal-border-color: var(--color-error-bg-strong, $color-error-1) !default;
$btn-withdrawal-border-hover-color: var(--color-error-bg-strong-hover, $color-error-2) !default;
$btn-withdrawal-link-color: var(--color-error-text, $color-error-1) !default;
$btn-withdrawal-link-hover-color: var(--color-error-text-hover, $color-error-2) !default;
$btn-withdrawal-link-pressed-color: var(--color-error-text-hover, $color-error-3) !default;
$btn-withdrawal-pressed-background: var(--color-error-bg-strong-pressed, $color-error-3) !default;

$btn-withdrawal-secondary-color: var(--color-error-text, $color-error-1) !default;
$btn-withdrawal-secondary-background: transparent !default;
$btn-withdrawal-secondary-background-hover: var(--color-error-bg-strong, $color-error-1) !default;
$btn-withdrawal-secondary-border: var(--color-error-border, $color-error-1) !default;
$btn-withdrawal-secondary-border-hover: var(--color-error-bg-strong, $color-error-1) !default;
$btn-withdrawal-secondary-box-shadow-hover: null !default;
$btn-withdrawal-secondary-pressed-background: var(--color-error-bg-strong-hover, $color-error-2) !default;

$btn-expressive-color: var(--color-brand-expressive-text-onbg, $white) !default;
$btn-expressive-background: var(--color-brand-expressive-bg-strong, $purple) !default;
$btn-expressive-background-hover: var(--color-brand-expressive-bg-strong-hover, $purple-l) !default;
$btn-expressive-border-color: var(--color-brand-expressive-bg-strong, $purple) !default;
$btn-expressive-border-hover-color: var(--color-brand-expressive-bg-strong-hover, $purple-l) !default;
$btn-expressive-link-color: var(--color-brand-expressive-text, $purple) !default;
$btn-expressive-link-hover-color: var(--color-brand-expressive-text-hover, $purple-l) !default;
$btn-expressive-link-pressed-color: var(--color-brand-expressive-text-hover, $color-raw-purple-dark) !default;
$btn-expressive-pressed-color: var(--color-brand-expressive-text-onbg, $white) !default;
$btn-expressive-pressed-background: var(--color-brand-expressive-bg-strong-pressed, $color-raw-purple-dark) !default;

$btn-secondary-color: var(--color-brand-primary-text, $color-raw-purple-dim) !default;
$btn-secondary-color-hover: var(--color-brand-primary-text-onbg-strong, $white) !default;
$btn-secondary-background: transparent !default;
$btn-secondary-background-hover: var(--color-brand-primary-bg-strong, $color-raw-purple-dim) !default;
$btn-secondary-icon-only-background-hover: var(--color-brand-primary-bg-strong, $color-raw-purple-dim) !default;
$btn-secondary-border: var(--color-brand-primary-border, $color-raw-purple-dim) !default;
$btn-secondary-border-hover: var(--color-brand-primary-bg-strong, $color-raw-purple-dim) !default;
$btn-secondary-icon-only-border: $btn-secondary-border !default;
$btn-secondary-icon-only-border-hover: var(--color-brand-primary-bg-strong, $color-raw-purple-dim) !default;
$btn-secondary-box-shadow-hover: null !default;
$btn-secondary-pressed-color: var(--color-brand-primary-text-onbg-strong, $white) !default;
$btn-secondary-pressed-background: var(--color-brand-primary-bg-strong-hover, $color-raw-purple-dark) !default;

$btn-text-color: var(--color-neutral-text-onbg-strong, $white) !default;
$btn-text-color-background-color: var(--color-neutral-bg-strong, $black)  !default;
$btn-text-color-background-hover-color: var(--color-neutral-bg-strong-hover, #{color.adjust($black, $lightness: 20%)}) !default;
$btn-text-color-background-pressed-color: var(--color-neutral-bg-strong-pressed, #{color.adjust($black, $lightness: 20%)}) !default;
$btn-text-color-border-hover-color: var(--color-neutral-bg-strong-hover, #{color.adjust($black, $lightness: 20%)}) !default;
$btn-text-color-link-color: var(--color-neutral-text, $black) !default;
$btn-text-color-link-hover-color: var(--color-brand-primary-text-hover, $color-primary-1) !default;

$btn-disabled-color: var(--color-disabled-text, $color-raw-black-dull) !default;
$btn-disabled-background-color: var(--color-disabled-bg, $color-raw-grey-base) !default;
$btn-disabled-border-color: transparent !default;
$btn-disabled-link-color: $btn-disabled-color !default;

// Campaign
$campaign-collapse-icon-color: $purple !default;
$campaign-selected-color: $green !default;
$campaign-background-color: $white !default;
$campaign-after-line-color: $grey-l !default;
$campaign-input-background-color: $grey-l !default;

// Check
$check-border-color: var(--color-neutral-border, $grey-d)  !default;
$check-background: transparent !default;
$check-disabled-text-color: var(--color-disabled-text, $grey-d) !default;
$check-disabled-border-color: var(--color-disabled-border, $grey-d) !default;
$check-disabled-background-color: var(--color-disabled-bg, $white) !default;
$check-disabled-indicator-color: var(--color-disabled-text, $white) !default;
$check-disabled-checked-background-color: var(--color-disabled-bg, $grey-d) !default;
$check-disabled-checked-border-color: var(--color-disabled-border, $grey-d) !default;
$check-indicator-color: var(--color-brand-primary-text-onbg-strong, $white) !default;
$check-employee-indicator-color: var(--color-employee-text-onbg-strong, $white) !default;
$check-employee-indicator-background-color: var(--color-employee-bg-strong, $color-employee-1) !default;
$check-employee-indicator-border-color: var(--color-employee-border, $color-employee-1) !default;
$check-employee-subtle-background-color: var(--color-employee-bg-subtlest, rgba($color-employee-1, .1)) !default;
$check-checked-background: var(--color-brand-primary-bg-strong, $color-selected-1) !default;
$check-checked-border: var(--color-brand-primary-border, $color-selected-1) !default;
$check-indicator-disabled-opacity: 1 !default;

// Radio
$radio-border-color: var(--color-neutral-border, $grey-d) !default;
$radio-disabled-color: var(--color-disabled-text, $grey-d) !default;
$radio-employee-background: var(--color-employee-bg-subtlest, rgba($color-employee-1, .1)) !default;
$radio-indicator-bg: transparent !default;
$radio-indicator-checked-color: var(--color-brand-primary-text-onbg-strong, $white) !default;
$radio-indicator-checked-bg: var(--color-brand-primary-bg-strong, $color-selected-1) !default;
$radio-indicator-checked-border: var(--color-brand-primary-border, $color-selected-1) !default;
$radio-indicator-employee-color: var(--color-employee-text-onbg-strong, $white) !default;
$radio-indicator-employee-bg: var(--color-employee-bg-strong, $color-employee-1) !default;
$radio-indicator-employee-border: var(--color-employee-border, $color-employee-1) !default;
$radio-indicator-disabled-color: var(--color-disabled-text, $white) !default;
$radio-indicator-disabled-checked-bg: var(--color-disabled-bg, $grey-d) !default;
$radio-indicator-disabled-checked-border: var(--color-disabled-border, $grey-d) !default;
$radio-indicator-disabled-bg: var(--color-disabled-bg, $white) !default;
$radio-indicator-disabled-border: var(--color-disabled-border, $grey-d) !default;
$radio-indicator-disabled-opacity: 1 !default;

// ChoiceGroup
$choice-group-purpose-agreement-background: $grey-m !default;
$choice-group-purpose-agreement-box-shadow: $black !default;
$choice-group-purpose-agreement-border-radius: 3px !default;
$choice-group-form-helper-help: $grey-t !default;
$choice-group-label-font-size: 14px !default;

// ClearButton
$clear-button-color: var(--color-neutral-graphic, $text-color-base) !default;
$clear-button-hover: var(--color-brand-primary-text-hover, $color-primary-1) !default;

// CloseButton
$close-button-color: var(--color-neutral-graphic, $text-color-base) !default;
$close-button-hover-color: var(--color-brand-primary-text-hover, $color-primary-1) !default;
$close-button-background: transparent !default;

$close-button-modal-size: 40px !default;
$close-button-modal-icon-size: 24px !default;
$close-button-modal-background: var(--color-neutral-bg-level-3, $white) !default;

// Horizontal progress
$horizontal-progress-title-color: var(--color-neutral-text-subtle, $text-color-light) !default;
$horizontal-progress-title-color-current-done: var(--color-neutral-text, $text-color-base) !default;
$horizontal-progress-current-color: var(--color-brand-primary-bg-strong, $color-primary-1) !default;
$horizontal-progress-indicator-line-inactive-color: var(--color-neutral-border-subtlest, $color-raw-grey-bright) !default;
$horizontal-progress-indicator-line-success-color: var(--color-success-border, $color-success-2) !default;
$horizontal-progress-content-border-color: var(--color-neutral-border-subtlest, $grey) !default;

// Sorting-menu
$sorting-menu-background-color: $white !default;
$sorting-menu-link-hover: $purple !default;
$sorting-menu-link-current-color: $purple !default;
$sorting-menu-link-add-color: $purple !default;
$sorting-menu-link-add-hover-color: $purple-l !default;

// Large-search
$large-search-submit-color: $green !default;
$large-search-submit-hover-color: $green-l !default;

// Carousel
$carousel-progress-color: $purple !default;
$carousel-indicator-dot-hover-color: $purple !default;
$carousel-arrow-color: $purple !default;
$carousel-dots-with-arrows-bottom: 20px !default;
$carousel-dots-with-small-arrows-bottom: 12px !default;
$carousel-dots-active-color: $purple-d !default;
$carousel-dots-color: $color-raw-black-fare !default;

// Carousel-hero
$carousel-hero-arrow-color: $grey-d !default;
$carousel-hero-arrow-hover-color: $grey-d !default;

// Accordion
$accordion-arrow-hover-color: var(--color-brand-primary-text-hover, $color-primary-1) !default;
$accordion-arrow-is-open-color: var(--color-brand-primary-text, $color-primary-6) !default;
$accordion-arrow-left-sm: auto !default;
$accordion-body-box-shadow: none !default;
$accordion-border-color: var(--color-neutral-border-subtlest, $color-raw-black-fare) !default;
$accordion-content-padding: 0 16px 16px 16px !default;
$accordion-content-padding-sm: 0 16px 16px 16px !default;
$accordion-header-inner-box-shadow: none !default;
$accordion-header-inner-min-height: 56px !default;
$accordion-header-inner-min-height-sm: 56px !default;
$accordion-header-inner-padding-top: 16px !default;
$accordion-header-inner-padding-bottom: 16px !default;
$accordion-header-inner-padding-right: 48px !default;
$accordion-header-inner-padding-left: 16px !default;
$accordion-header-inner-padding-right-sm: 48px !default;
$accordion-header-inner-padding-left-sm: 16px !default;
$accordion-header-inner-with-icon-padding-top: 16px !default;
$accordion-header-inner-with-icon-padding-bottom: 16px !default;
$accordion-header-inner-no-content-padding-left: 16px !default;
$accordion-header-inner-nth-last-of-type-2-padding-right: 16px !default;
$accordion-meta-padding: 8px 8px 0 0 !default;
$accordion-title-font-weight: 700 !default;
$accordion-title-padding: 0 16px 0 0 !default;
$accordion-title-padding-sm: 0 16px 0 0 !default;
$accordion-title-hover-color: var(--color-brand-primary-text-hover, $color-primary-1) !default;
$accordion-title-open-color: var(--color-brand-primary-text, $color-primary-6) !default;
$accordion-transition-timing-function: cubic-bezier(.165, .84, .44, 1);
$accordion-label-inactive: var(--color-disabled-text, $color-inactive-1);
$accordion-icon-inactive: var(--color-disabled-text, $color-inactive-1);
$accordion-employee-bg: var(--color-employee-bg-subtlest, $color-employee-3) !default;
$accordion-employee-open-color: var(--color-employee-text, $color-employee-1) !default;
$accordion-employee-hover-color: var(--color-employee-text-hover, $color-employee-2) !default;
$accordion-type-card-header-background: var(--color-neutral-bg-level-2, $grey-l) !default;
$accordion-type-card-content-border: var(--color-neutral-border-subtlest, $color-raw-grey-bright) !default;
$accordion-type-card-content-padding: 24px !default;

// Elevations
$elevation-1: 0 1px 2px rgba(0, 0, 0, .1) !default;
$elevation-2: 0 2px 4px rgba(0, 0, 0, .1) !default;
$elevation-3: 0 4px 8px rgba(0, 0, 0, .1) !default;
$elevation-4: 0 8px 16px rgba(0, 0, 0, .1) !default;
$elevation-5: 0 12px 24px rgba(0, 0, 0, .1) !default;

// Search
$search-textfield-label-color: var(--color-neutral-text-subtle, $grey-t) !default;
$search-action-hover-color: var(--color-brand-primary-text-hover, $purple) !default;
$search-action-disabled-color: var(--color-disabled-text, $grey-d) !default;

// Select
$select-primary-color: var(--color-neutral-text, $black) !default;
$select-secondary-color: var(--color-neutral-border, $grey-d) !default;
$select-secondary-text-color: var(--color-neutral-text, $grey-t) !default;
$select-menu-color: var(--color-neutral-bg-level-3, $white) !default;
$select-option-color: $grey !default; // removed
$select-selected-color: $color-selected-1 !default; // removed
$select-disabled-color: var(--color-disabled-text, $grey-d) !default;
$select-disabled-border-color: var(--color-disabled-border, $grey-d) !default;
$select-error-border-color: var(--color-error-border, $color-error-1) !default;
$select-employee-selected-color: var(--color-employee-text, $color-employee-1) !default;
$select-employee-focused-color: var(--color-employee-text-hover, $color-employee-1) !default;
$select-employee-background-color: var(--color-employee-bg-subtlest, rgba($color-employee-1, .1)) !default;
$select-focus-color: var(--color-focus-outline, $black) !default;
$select-focus-label-color: var(--color-neutral-text, $black) !default;
$select-sub-label-color: var(--color-neutral-text-subtle, $color-inactive-1) !default;
$select-option-selected-font-weight: 700 !default;
$select-box-shaddow: $elevation-5 !default;
$select-disabled-opacity: 1 !default;

$select-option-border-color: var(--color-neutral-border-subtle, $grey) !default;
$select-option-selected-color: var(--color-brand-primary-text, $color-selected-1) !default;
$select-option-focused-color: var(--color-brand-primary-text-hover, $color-selected-1) !default;
$select-option-focused-background-color: var(--color-neutral-bg-hover, $grey-m) !default;

$select-option-employee-color: var(--color-employee-text, $color-employee-1) !default;
$select-option-employee-focused-color: var(--color-employee-text-hover, $color-employee-1) !default;

// Selector
$selector-background: transparent !default;
$selector-border-radius: 4px !default;
$selector-border-color: var(--color-neutral-border, $grey-d) !default;

$selector-border-hover-color: var(--color-brand-primary-border, $color-selected-2) !default;
$selector-border-hover-color-custom: var(--color-brand-primary-border, $grey-d) !default;

$selector-disabled-color: var(--color-disabled-text, $color-inactive-1) !default;
$selector-disabled-border-color: var(--color-disabled-border, $color-raw-grey-bright) !default;
$selector-disabled-background: transparent !default;
$selector-disabled-selected-color: var(--color-disabled-text, $color-inactive-1) !default;
$selector-disabled-selected-background: var(--color-disabled-bg, $grey) !default;

$selector-inactive-text-color: var(--color-neutral-text, $text-color-base) !default;
$selector-inactive-color: var(--color-neutral-text-subtle, $grey-d) !default;

$selector-form-helper-help: var(--color-neutral-text, $grey-t) !default;

$selector-selected-color: var(--color-brand-primary-text-onbg-strong, $white) !default;
$selector-selected-background-color: var(--color-brand-primary-bg-strong, $color-selected-1) !default;
$selector-selected-border-color: var(--color-brand-primary-border, $color-selected-1) !default;
$selector-selected-background-hover-color: var(--color-brand-primary-bg-strong-hover, $color-selected-2) !default;
$selector-selected-border-hover-color: var(--color-brand-primary-bg-strong-hover, $color-selected-2) !default;
$selector-selected-color-border-color: $selector-selected-border-color !default;

$selector-color-employee: var(--color-employee-text, $color-employee-1) !default;
$selector-border-hover-color-employee: var(--color-employee-bg-strong-hover, $color-employee-2) !default;
$selector-selected-color-employee: var(--color-employee-text-onbg-strong, $white) !default;
$selector-selected-background-color-employee: var(--color-employee-bg-strong, $color-employee-1) !default;
$selector-selected-border-color-employee: var(--color-employee-bg-strong, $color-employee-1) !default;
$selector-selected-hover-color-employee: var(--color-employee-text-onbg-strong, $white) !default;
$selector-selected-background-hover-color-employee: var(--color-employee-bg-strong-hover, $color-employee-2) !default;
$selector-selected-border-hover-color-employee: var(--color-employee-bg-strong-hover, $color-employee-2) !default;

$selector-font-size: 16px !default;
$selector-line-height: 22px !default;
$selector-padding-vertical: 10px !default;
$selector-padding-horizontal: 16px !default;
$selector-min-height: 48px !default;

$selector-small-font-size: 14px !default;
$selector-small-line-height: 20px !default;
$selector-small-padding-vertical: 4px !default;
$selector-small-padding-horizontal: 8px !default;
$selector-small-min-height: 32px !default;

$selector-large-font-size: 18px !default;
$selector-large-line-height: 24px !default;
$selector-large-padding-vertical: 10px !default;
$selector-large-padding-horizontal: 16px !default;
$selector-large-min-height: 60px !default;

$selector-xlarge-font-size: 20px !default;
$selector-xlarge-line-height: 26px !default;
$selector-xlarge-padding-vertical: 26px !default;
$selector-xlarge-padding-horizontal: 20px !default;
$selector-xlarge-size: 80px !default;
$selector-xlarge-min-height: 80px !default;

// FormHelper
$form-helper-check-length: 36px !default;
$form-helper-size: 12px !default;
$form-helper-help-color: var(--color-info-graphic, $blue) !default;
$form-helper-valid-color: var(--color-success-text, $green) !default;
$form-helper-valid-icon-color: var(--color-success-graphic, $green) !default;
$form-helper-error-color: var(--color-error-text, $red) !default;
$form-helper-error-icon-color: var(--color-error-graphic, $red) !default;
$form-helper-error-font-weight: 400 !default;

// Icon
$icon-font-size-xxs: 16px;
$icon-font-size-xs: 24px;
$icon-font-size-sm: 32px;
$icon-font-size-md: 40px;
$icon-font-size-lg: 48px;
$icon-font-size-xl: 56px;
$icon-font-size-xxl: 64px;
$icon-font-size-xxxl: 96px;

// Iframe
$iframe-consent-background: var(--color-backdrop, $color-background-2) !default;
$iframe-consent-inner-background: var(--color-neutral-bg-level-3, $white) !default;

// Main
$main-header-padding-top: 100px !default;
$main-header-padding-top-lg: 110px !default;
$main-content-padding: 30px 0 100px !default;

// Mini Form
$mini-form-button-top: 12px !default;
$mini-form-hide-label-button-top: 5px !default;

// Modal
$modal-backdrop-color: var(--color-backdrop, rgba($color-neutral-5, .8)) !default;
$modal-border-radius: 8px !default;
$modal-box-shadow: $elevation-5 !default;
$modal-horizontal-spacing-xs: 16px !default;
$modal-background-color: var(--color-neutral-bg-level-3, $white) !default;
$modal-footer-background-color: var(--color-neutral-bg-level-2, $grey-m) !default;

// Text
$text-h1-margin-bottom: .5em !default;

// Textfield
$textfield-label-size: 12px !default;
$textfield-label-padding: 0 !default;
$textfield-floating-label-size: 16px !default;
$textfield-label-start-position: 17px !default;
$textfield-height: 32px !default;
$textfield-weight: 400 !default;
$textfield-label-visible-height: 17px !default;
$textfield-border-radius: 0 0 3px 3px !default;
$textfield-border-top-position: 7px !default;
$textfield-box-shadow: none !default;
$textfield-background: none !default;
$textfield-dirty-border-color: var(--color-neutral-border, $grey-d) !default;
$textfield-form-helper-margin-top: 5px !default;
$textfield-large-font-family: $font-primary;

$textfield-employee-subtle-background-color: var(--color-employee-bg-subtlest, rgba($color-employee-1, .1)) !default;
$textfield-focused-border-color: var(--color-focus-outline, $black) !default;
$textfield-border-color: var(--color-neutral-border, $grey-d) !default;
$textfield-disabled-color: var(--color-disabled-text, $grey-d) !default;
$textfield-disabled-border-color: var(--color-disabled-border, rgba($grey-d, .5)) !default;
$textfield-error-border-color: var(--color-error-border, $color-error-1) !default;
$textfield-border: 1px solid $textfield-border-color !default;
$textfield-color: var(--color-neutral-text, $black) !default;
$textfield-floating-label-color: var(--color-neutral-text-subtle, $black) !default;
$textfield-dirty-text-color: var(--color-neutral-text, $grey-t) !default;
$textfield-focused-color: var(--color-neutral-text, $black) !default;
$textfield-focused-border-color: var(--color-focus-outline, $black) !default;
$textfield-helper-color: var(--color-neutral-text, $grey-t) !default;

// Passfield
$passfield-button-hover-color: var(--color-brand-primary-text-hover, $color-primary-1) !default;

// Textarea
$textarea-small-height: $textfield-height !default;

// Tabs
$tabs-text-color: var(--color-neutral-text, $text-color-base) !default;
$tabs-hover-text-color: var(--color-brand-primary-text-hover, $color-primary-1) !default;
$tabs-selected-text-color: var(--color-brand-primary-text, $color-primary-6) !default;
$tabs-border-color: var(--color-neutral-border-subtlest, rgba($color-primary-5, .12)) !default;
$tabs-selected-border-color: var(--color-brand-primary-border, $color-primary-6) !default;
$tabs-nav-link-icon-color: var(--color-neutral-graphic, $color-primary-5) !default;
$tabs-employee-text-color: var(--color-employee-text, $color-employee-1) !default;
$tabs-employee-hover-text-color: var(--color-employee-text-hover, $color-employee-2) !default;
$tabs-employee-border-color: var(--color-employee-text, $color-employee-1) !default;
$tabs-employee-selected-text-color: var(--color-employee-text, $color-employee-1) !default;
$tabs-huge-background-color: var(--color-neutral-bg-level-2, $color-raw-grey-light) !default;
$tabs-huge-background-color-current: var(--color-neutral-bg-level-1, $color-raw-white-base) !default;
$tabs-huge-border-color: var(--color-neutral-border-subtle, $color-raw-grey-bright) !default;
$tabs-huge-employee-border-color: var(--color-employee-border, $color-employee-1) !default;
$tabs-light-color: var(--color-neutral-bg-level-1, $white) !default;
$tabs-light-selected-color: var(--color-neutral-bg-level-2, $color-raw-grey-light) !default;
$tabs-tag-selected-text-color: var(--color-brand-primary-text-onbg-strong, $color-raw-purple-fare) !default;
$tabs-tag-selected-background-color: var(--color-brand-primary-bg-strong, $color-raw-purple-dim) !default;
$tabs-employee-tag-text-color: var(--color-employee-text, $color-employee-2) !default;
$tabs-employee-tag-background-color: var(--color-employee-bg-subtle, $white) !default;

// Toggle
$toggle-border: 1px solid $color-neutral-3 !default;
$toggle-indicator-after-background: $color-neutral-3 !default;

$toggle-indicator-checked-color-employee: var(--color-employee-bg-strong, $color-employee-1) !default;
$toggle-indicator-after-background-employee: var(--color-employee-graphic, $color-employee-1) !default;
$toggle-checked-indicator-after-background-employee: var(--color-employee-text-onbg-strong, $white) !default;
$toggle-spinner-color-employee: var(--color-employee-graphic, $color-employee-1) !default;

// Header
$header-link-hover: $purple !default;
$header-box-shadow: none !default;
$header-scrolled-box-shadow: 0 1px 1px rgba($black, .1) !default;
$header-backdrop-background: $grey !default;
$header-bottom-height: 60px !default;
$header-bottom-md-height: 80px !default;
$header-scrolled-height: 40px !default;
$header-top-font-size: 10px !default;
$header-top-md-font-size: 12px !default;
$header-top-height: 36px !default;
$header-top-md-height: 40px !default;
$header-scrolled-margin-top: -$header-top-height !default;
$header-scrolled-md-margin-top: -$header-top-md-height !default;
$header-dropdown-section-box-shadow: none !default;
$header-height: 112px !default;
$header-height-md: 120px !default;
$header-logo-padding: 12px 8px 15px !default;
$header-logo-width: 21px !default;
$header-logo-height: 22px !default;
$header-employee-height: 40px !default;
$header-action-tag-color: #00a552;

// Header search
$header-search-padding: 7px 0 !default;
$header-search-button-top: 7px !default;

// Menu
$menu-link-font-weight: $font-weight-base !default;
$menu-link-padding: 13px 7px 10px !default;
$menu-large-link-padding: 12px 12px 16px !default;
$menu-large-link-md-padding: $menu-large-link-padding !default;
$menu-text-font-size: 7px !default;
$menu-text-bottom: 6px !default;
$menu-large-icon-font-size: 22px !default;
$menu-item-current-indicator-bottom: 0 !default;
$menu-icon-wrapper-height: 20px !default;

// Nav
$nav-container-box-shadow: none !default;
$nav-link-font-weight: $font-weight-base !default;
$nav-container-margin-left: 1px !default;
$nav-link-hover: $purple !default;
$nav-link-hover-background: $grey-l !default;
$nav-icon-display: inline-block !default;
$nav-icon-md-display: block !default;
$nav-item-md-height: $header-bottom-md-height !default;
$nav-link-md-padding: 17px 10px !default;
$nav-item-after-display: block !default;
$nav-item-md-min-width: 95px !default;
$nav-item-font-size: 12px !default;
$nav-compact-item-height: $header-scrolled-height !default;
$nav-compact-link-md-padding: 12px 8px !default;
$nav-compact-link-md2-padding: 10px 10px !default;
$nav-compact-item-multiline-md-padding: 8px !default;
$nav-compact-item-multiline-md2-padding: 6px !default;
$nav-compact-padding-top: 10px !default;
$nav-item-scroll-visible-padding: 0 10px !default;
$nav-sub-container-right-position: 2px !default;

// Dropdown
$dropdown-selected-color: $purple !default;

// List
$list-large-font-size-xs: $base-font-size !default;
$list-large-font-size-sm: $h4-size !default;
$list-large-font-size-md: $h4-size !default;
$list-large-font-size-xl: $h4-size-xl !default;
$list-large-font-weight: 400 !default;
$list-icon-color: var(--color-neutral-graphic, inherit) !default;
$list-text-color: var(--color-neutral-text, $text-color-base) !default;
$list-border-color: var(--color-neutral-border-subtlest, $color-raw-black-fare) !default;
$list-marker-top-position-xl: 9px !default;
$list-icon-spacing: .4em !default;
$list-ordered-counter-weight: 700 !default;
$list-gutter-medium: $grid-gutter-medium;
$list-gutter-medium-xs: $grid-gutter-medium-xs;

// Arrow-list
$arrow-list-background-default: $color-raw-grey-light !default;
$arrow-list-background-default-hover: $color-raw-grey-base !default;
$arrow-list-background-white: $color-raw-white-base !default;
$arrow-list-background-white-hover: $color-raw-grey-base !default;
$arrow-list-background-beige: $color-raw-beige-fare !default;
$arrow-list-background-beige-hover: $color-raw-beige-light !default;

// Logo
$logo-padding-top: math.div(97, 250) * 100% !default; // (height / width * 100%)
$logo-background-url: "~@telia-front/static-assets/images/telia-logo.svg" !default;
$logo-background-url-inverse: "~@telia-front/static-assets/images/telia-logo-inverse.svg" !default;
$logo-background-url-black: "~@telia-front/static-assets/images/telia-logo-black.svg" !default;
$logo-small-width-sm: 80px;

// Search-large
$search-large-background: $white !default;
$search-large-light-background: $grey-l !default;

// Clear-field
$clear-field-hover: $purple !default;

// Skeleton
$skeleton-background-color: var(--color-neutral-border-subtlest, $color-raw-grey-base) !default;
$skeleton-background-color-secondary: var(--color-neutral-border, $color-raw-grey-bright)  !default;

// SkipLinks
$skip-links-background-color: var(--color-neutral-bg-level-2, $grey-l) !default;
$skip-links-border-color: var(--color-neutral-border-subtlest, $grey) !default;

// Suggestion
$suggestion-background: var(--color-neutral-bg-level-3, $white) !default;
$suggestion-shadow: 0 3px 6px rgba($black, .2) !default;
$suggestion-border-radius: 3px !default;
$suggestion-link-padding: 9px 21px !default;
$suggestion-link-hover-color: var(--color-neutral-text, $text-color-base) !default;
$suggestion-link-background: var(--color-neutral-bg-level-4, $grey-m) !default;
$suggestion-link-active-color: var(--color-brand-primary-text, $color-selected-1) !default;
$suggestion-link-active-color-employee: var(--color-employee-text, $pink-l) !default;
$suggestion-group-label-color: var(--color-neutral-text-subtle, $grey-d) !default;
$suggestion-item-background: var(--color-neutral-border-subtle, $grey) !default;
$suggestion-item-margin-top: -1px !default;
$suggestion-sub-label-color: var(--color-neutral-text-subtle, $grey-t) !default;
$suggestion-image-width: 22px !default;

// Affix
$affix-top: $header-bottom-height !default;
$affix-top-lg: $header-scrolled-height !default;

// Notice
$notice-colors: (
    info: (
        icon: var(--color-info-graphic, $color-information-1),
        background: var(--color-info-bg-subtle, $color-information-2),
        border: var(--color-info-border, $color-information-1),
    ),
    success: (
        icon: var(--color-success-graphic, $color-success-1),
        background: var(--color-success-bg-subtle, $color-success-2),
        border: var(--color-success-border, $color-success-1),
    ),
    employee: (
        icon: var(--color-employee-graphic, $color-employee-1),
        background: var(--color-employee-bg-subtlest, $color-employee-3),
        border: var(--color-employee-border, $color-employee-1),
    ),
    error: (
        icon: var(--color-error-graphic, $color-error-1),
        background: var(--color-error-bg-subtle, $color-error-4),
        border: var(--color-error-border, $color-error-1),
    ),
    attention: (
        icon: var(--color-attention-graphic, $color-attention-1),
        background: var(--color-attention-bg-subtle, $color-attention-2),
        border: var(--color-attention-border, $color-attention-1),
    ),
) !default;
$notice-height: 64px !default;

// Card
$card-box-shadow: none !default;
$card-border-radius-medium: 8px !default;
$card-left-border-width: 4px !default;
$card-hover-bg: var(--color-neutral-bg-hover, rgba(0,0,0, 0.05)) !default;

$card-old-inactive-color: $color-raw-grey-dark !default;

$card-selectable-border-color: var(--color-neutral-border-subtle, $color-raw-grey-bright) !default;
$card-selectable-employee-color: var(--color-employee-text, $color-employee-1) !default;
$card-selectable-hover-border-color: var(--color-brand-primary-border, $color-primary-2) !default;
$card-selectable-employee-hover-border-color: var(--color-employee-border, $color-employee-2) !default;
$card-selected-border-color: var(--color-brand-primary-border, $color-raw-purple-dark) !default;
$card-selected-border-employee-color: var(--color-employee-border, $color-employee-1) !default;
$card-selectable-disabled-border-color: var(--color-disabled-border, $color-raw-grey-bright) !default;

$card-type-success-border-color: var(--color-success-border, $color-raw-green-medium) !default;
$card-type-error-border-color: var(--color-error-border, $color-error-1) !default;
$card-type-employee-border-color: var(--color-employee-border, $color-employee-1) !default;
$card-type-inactive-border-color: var(--color-disabled-border, $color-raw-grey-dark) !default;
$card-type-attention-border-color: var(--color-attention-border, $color-attention-1) !default;
$card-type-light-border-color: var(--color-neutral-border-subtle, $color-raw-grey-bright) !default;

$card-hover-border-color: var(--color-brand-primary-border, $color-primary-1) !default;

// Option-card
$option-card-recommended-color: $color-recommended-1 !default;
$option-card-error-color: $color-error-1 !default;

$option-card-small-vertical-padding: 20px !default;
$option-card-small-horizontal-padding: 15px !default;
$option-card-medium-vertical-padding: 30px !default;
$option-card-medium-horizontal-padding: 20px !default;

// Option-card-old
$option-card-old-recommended-color: $color-recommended-1 !default;
$option-card-old-error-color: $color-error-1 !default;

$option-card-old-content-padding: 14px 30px !default;
$option-card-old-content-padding-xs: 14px 30px !default;
$option-card-old-small-vertical-padding: 20px !default;
$option-card-old-small-horizontal-padding: 15px !default;
$option-card-old-medium-vertical-padding: 30px !default;
$option-card-old-medium-horizontal-padding: 20px !default;
$option-card-old-content-vertical-padding: 40px !default;
$option-card-old-content-vertical-padding-xs: 40px !default;

$option-card-old-footer-padding: 16px 30px !default;
$option-card-old-footer-padding-xs: 16px 30px !default;
$option-card-old-background-color: $color-primary-3 !default;

// Overlay
$overlay-background: var(--color-neutral-bg-level-1, $grey) !default;

// Collapse
$collapse-background-color: var(--color-neutral-bg-level-1, $white) !default;
$collapse-background-color-grey: var(--color-neutral-bg-level-2, $grey-m) !default;
$collapse-border-color: var(--color-brand-primary-border, $color-primary-1) !default;
$collapse-close-button-color-hover: var(--color-brand-primary-text-hover, $color-primary-1) !default;

// CookieNotice
$cookie-notice-background-color: #d9f8ff !default;
$cookie-notice-color: $black !default;
$cookie-notice-font-size: 12px !default;
$cookie-notice-font-weight: 700 !default;
$cookie-notice-icon-color: $blue !default;
$cookie-notice-icon-size: 16px !default;
$cookie-notice-align-items: flex-start !default;
$cookie-notice-padding: 5px 10px 10px 10px !default;
$cookie-notice-padding-sm: 5px 20px 4px 20px !default;

// Filter
$filter-trigger-hover: $purple !default;

// Progress
$progress-step-color: $color-raw-grey-bright !default;
$progress-current-color: $purple !default;
$progress-done-color: $green !default;
$progress-icon-size: 32px !default;
$progress-icon-size-sm: 24px !default;
$progress-counter-size: 8px !default;
$progress-current-counter-size: 32px !default;
$progress-current-counter-mobile-size: 24px !default;
$progress-indicator-line-position: 11px !default;
$progress-indicator-line-current-color: $color-success-2 !default;
$progress-indicator-line-done-color: $color-success-2 !default;
$progress-invalid-color: $red !default;
$progress-indicator-line-width: 2px !default;
$progress-counter-font-size: 22px !default;
$progress-counter-mobile-font-size: 14px !default;

// ProductCard
$product-card-icon-color: #00a552 !default;
$product-card-hover-border-color: $color-primary-1 !default;

// Separator
$separator-color-default: var(--color-neutral-border-subtlest, $color-raw-black-fare) !default;
$separator-color-medium: var(--color-neutral-border-subtle, $color-raw-black-light) !default;
$separator-color-strong: var(--color-neutral-border, $color-raw-black-bright) !default;
$separator-weight-default: 2px !default;
$separator-weight-thin: 1px !default;

// Spinner
$spinner-color: var(--color-brand-primary-graphic, #{$color-primary-1});

// Styleguide variables
$sg-body-background: $white !default;
$sg-body-background-grey: $grey-l !default;

// Tag
$tag-size: 96px !default;
$tag-size-small: 64px !default;
$tag-size-medium: 80px !default;
$tag-padding: 15% !default;
$tag-padding-small: 15% !default;
$tag-font-size-size-tiny: 9px !default;
$tag-font-size-size-small: 11px !default;
$tag-font-size-size-medium: 14px !default;
$tag-font-size-size-large: 16px !default;
$tag-font-size-size-extra-large: 20px !default;
$tag-svg-size: 24px !default;
$tag-svg-size-icon: 40px !default;
$tag-svg-size-icon-small: 32px !default;
$tag-tiny-padding-vertical: 4px !default;
$tag-tiny-padding-horizontal: 8px !default;
$tag-tiny-border-radius: 4px !default;
$tag-tiny-font-size: 12px !default;
$tag-tiny-font-height: 16px !default;
$tag-purpose-service-border-color: var(--color-neutral-border-subtle, $color-raw-grey-bright) !default;
$tag-purpose-service-text-color: var(--color-neutral-text, $black) !default;
$tag-default-background-color: var(--color-brand-primary-bg-strong, $color-background-7) !default;
$tag-default-text-color: var(--color-brand-primary-text-onbg-strong, $color-background-6) !default;

// Tooltip
$tooltip-color: var(--color-neutral-bg-level-3, $white) !default;
$tooltip-border-color: var(--color-neutral-border-subtlest, $color-raw-grey-bright) !default;

// Tooltip toggle
$tooltip-toggle-border-color: var(--color-brand-primary-text, $color-primary-1) !default;
$tooltip-toggle-border-color-hover: var(--color-brand-primary-text-hover, $color-primary-1) !default;
$tooltip-toggle-color-hover: var(--color-brand-primary-text-hover, $color-primary-1) !default;
$tooltip-toggle-icon-color: var(--color-brand-primary-text, $color-raw-purple-dark) !default;
$tooltip-toggle-icon-color-hover: var(--color-brand-primary-text-hover, $color-primary-1) !default;
$tooltip-toggle-employee-border-color: var(--color-employee-text, $color-employee-1) !default;
$tooltip-toggle-employee-border-color-hover: var(--color-employee-text-hover, $color-employee-2) !default;
$tooltip-toggle-employee-color-hover: var(--color-employee-text-hover, $color-employee-2) !default;
$tooltip-toggle-employee-icon-color: var(--color-employee-text, $color-employee-1) !default;
$tooltip-toggle-employee-icon-color-hover: var(--color-employee-text-hover, $color-employee-2) !default;

// Video
$video-background-color: var(--color-neutral-bg-level-2, $black) !default;
$video-box-shadow: none !default;
$video-icon-background-color: var(--color-brand-primary-bg-subtle, rgba($color-raw-grey-light, .9)) !default;
$video-icon-background-color-hover: var(--color-brand-primary-bg-strong, $color-primary-1) !default;
$video-icon-fill: var(--color-brand-primary-text-subtle, $color-raw-purple-dark) !default;
$video-icon-fill-hover: var(--color-brand-primary-text-onbg-strong, $white) !default;
$video-border-radius: 8px !default;
$video-title-background: var(--color-brand-primary-bg-strong, rgba($color-raw-purple-dim, .9)) !default;
$video-title-color: var(--color-brand-primary-text-onbg-strong, $white) !default;

