@import "~@core-front/sass-tokens/index.scss";

.iframe {
    position: relative;
}

.iframe__content {
    display: block;
    width: 100px !important;
    min-width: 100%;
    border: 0;
}

.iframe__consent {
    background: $iframe-consent-background;
    padding: 40px $base-horizontal-spacing-xs;

    @media (min-width: $break-sm) {
        padding-left: $base-horizontal-spacing;
        padding-right: $base-horizontal-spacing;
    }
}

.iframe__consent-inner {
    background: $iframe-consent-inner-background;
    border-radius: 3px;
    padding: 40px $base-horizontal-spacing-xs;
    max-width: 580px;
    margin: 0 auto;

    @media (min-width: $break-sm) {
        padding-left: $base-horizontal-spacing;
        padding-right: $base-horizontal-spacing;
    }
}
