@import "~@core-front/sass-tokens/mixins.scss";

@mixin button-small {
    min-width: 0;
    padding: $btn-small-padding $btn-small-min-horizontal-padding;
    border-radius: $btn-small-border-radius;

    &:not(.btn--link) {
        font-size: $btn-small-font-size;
        line-height: $btn-small-line-height;
    }

    .btn__icon {
        font-size: 10px;
    }

    .btn__icon--left {
        margin-right: $btn-small-icon-spacing;
    }

    .btn__icon--right {
        margin-left: $btn-small-icon-spacing;
        margin-right: $btn-small-icon-padding - $btn-small-min-horizontal-padding;
    }

    &.btn--link {
        font-size: 12px;

        @media (min-width: $break-xl) {
            font-size: 14px;
        }
    }
}
