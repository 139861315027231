@use "sass:math";

@import "~@core-front/sass-tokens/index.scss";

$check-indicator-size-medium: 20px !default;
$check-indicator-size-small: 16px !default;
$check-indicator-position-top: 1px !default;
$check-indicator-disabled-opacity: .5 !default;
$check-icon-size-medium: $check-icon-size !default;
$check-icon-size-small: 10px !default;

.check {
    position: relative;
}

.check--vertical {
    display: flex;
}

.check__label {
    @include visually-hidden();
}

.check__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.check__inner {
    position: relative;
    display: inline-block;
    padding-left: ($check-indicator-size + math.div($check-indicator-size, 2));
    min-height: $check-indicator-size + $check-indicator-position-top;
    cursor: pointer;
    max-width: 100%;

    html[data-whatintent="keyboard"] .check__input:focus ~ & {
        @include keyboard-focus();
    }

    .check__input:disabled ~ & {
        cursor: default;
    }

    .check--small & {
        padding-left: ($check-indicator-size-small + math.div($check-indicator-size-small, 2));
        min-height: $check-indicator-size-small;
    }

    .check--medium & {
        min-height: $check-indicator-size-medium;
    }

    .check--employee-background & {
        &::before {
            content: "";
            display: block;
            background: $check-employee-subtle-background-color;
            position: absolute;
            top: -5px;
            left: -5px;
            right: -10px;
            bottom: -5px;
            border-radius: 3px;
        }
    }

    .check--vertical & {
        display: flex;
        flex-direction: column-reverse;
        text-align: center;
        justify-content: space-between;
        padding: 0;
    }

    .skeleton__content > & {
        display: block;
        cursor: default;
    }

    &--full-width {
        width: 100%;
    }
}

.check__content {
    display: block;
    font-weight: $check-weight;
    position: relative;

    .check__input:disabled ~ .check__inner & {
        color: $check-disabled-text-color;
    }

    .check--large & {
        font-size: 16px;
    }

    .check--medium & {
        font-size: 14px;
    }

    .check--small & {
        font-size: 12px;
    }

    .check--vertical & {
        margin-bottom: 15px;
    }

    .check--hide-label & {
        display: none;
    }
}

.check__indicator {
    display: block;
    width: $check-indicator-size;
    height: $check-indicator-size;
    background: $check-background;
    border: 1px solid $check-border-color;
    border-radius: $check-indicator-radius;
    box-shadow: none;
    position: absolute;
    left: 0;
    color: $check-indicator-color;

    .check__input:checked ~ .check__inner & {
        background: $check-checked-background;
        border-color: $check-checked-border;

        .check--employee & {
            background: $check-employee-indicator-background-color;
            border-color: $check-employee-indicator-border-color;
            color: $check-employee-indicator-color;
        }
    }

    .check--large & {
        top: $check-indicator-position-top;
    }

    .check--medium & {
        width: $check-indicator-size-medium;
        height: $check-indicator-size-medium;
    }

    .check--small & {
        width: $check-indicator-size-small;
        height: $check-indicator-size-small;
    }

    .check--indicator-middle & {
        top: 50%;
        transform: translateY(-50%);
    }

    .check__input:disabled ~ .check__inner & {
        opacity: $check-indicator-disabled-opacity;
        background: $check-disabled-background-color;
        border-color: $check-disabled-border-color;
    }

    .check__input:disabled:checked ~ .check__inner & {
        color: $check-disabled-indicator-color;
        background: $check-disabled-checked-background-color;
        border-color: $check-disabled-checked-border-color;
    }

    .check--vertical & {
        top: 0;
        position: relative;
        margin: 0 auto;
    }

    &.skeleton__item {
        border: none;
    }
}

.check__icon {
    @include reset-icon();

    font-size: $check-icon-size;
    position: absolute;
    top: 1px;
    right: 1px;
    opacity: 0;
    pointer-events: none;

    .check--medium & {
        font-size: $check-icon-size-medium;
    }

    .check--small & {
        font-size: $check-icon-size-small;
        top: 2px;
        right: 2px;
    }

    .check__input:checked ~ .check__inner & {
        opacity: 1;
    }
}

.check__extra {
    padding-top: 10px;
    padding-left: ($check-indicator-size + math.div($check-indicator-size, 2));

    .check--small & {
        padding-left: ($check-indicator-size-small + math.div($check-indicator-size-small, 2));
    }
}
