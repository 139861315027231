@import "~@telia-front/sass-tokens/index.scss";
@import "~@core-front/option-card/styles.scss";

.option-card__content--grey {
    background-color: $grey;
}

.option-card__checked-badge-inner {
    &::before {
        content: none;
    }

    &::after {
        content: none;
    }
}

.option-card__tag--top {
    position: absolute;
    top: 10px;
    right: -10px;
}
